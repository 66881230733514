import { ENVIO_CLIENTES_FINALES } from '../constants/filters';

const initialState = {
    filtersEnvioClientesFinales: {
        tab: 0,
    },
};

const filters = (state = initialState, action) => {
    switch (action.type) {
        case ENVIO_CLIENTES_FINALES:
            return {
                ...state,
                filtersEnvioClientesFinales: action.filtersEnvioClientesFinales,
            };

        default:
            return state;
    }
};

export default filters;
