export const formatterCOP = new Intl.NumberFormat('es-CO', {
    style: 'currency',
    currency: 'COP',
});

export const thousandFormatter = new Intl.NumberFormat('es-CO');

export const CANALES = [
    {
        id: 1,
        canal: 'TMK',
    },
    {
        id: 2,
        canal: 'F2F',
    },
    {
        id: 3,
        canal: 'TMK y F2F',
    },
];

export const AÑOS = [
    {
        id: 1,
        ano: '2015',
    },
    {
        id: 2,
        ano: '2016',
    },
    {
        id: 3,
        ano: '2017',
    },
    {
        id: 4,
        ano: '2018',
    },
    {
        id: 5,
        ano: '2019',
    },
    {
        id: 6,
        ano: '2020',
    },
    {
        id: 7,
        ano: '2021',
    },
    {
        id: 8,
        ano: '2022',
    },
    {
        id: 9,
        ano: '2023',
    },
    {
        id: 10,
        ano: '2024',
    },
];
