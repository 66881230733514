import { useEffect, useState } from 'react';
import { sendRequest } from '../../../../../services/request';
import { useLocation } from 'react-router-dom';
import { DataGrid, GridToolbar, esES } from '@mui/x-data-grid';
import { Box, Typography } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
import dayjs from 'dayjs';
import weekOfYear from 'dayjs/plugin/weekOfYear';
import ListHeader from './listHeader';
import { DataGridStyle } from '../../../../../styles/DataGridSytle';

dayjs.extend(weekOfYear);

const CampanaClientesVerDetalle = ({ pais }) => {
    const [rows, setRows] = useState();
    const [limit, setLimit] = useState(50);
    const [page, setPage] = useState(0);
    const [rowCount, setRowCount] = useState(null);

    const URL = `${process.env.REACT_APP_API_URL}`;
    const location = useLocation();

    // Filtros
    const [filtros, setFiltros] = useState({
        searchId: '',
        searchNombres: '',
        searchApellidos: '',
        tipoDocumentoSelected: 'tipo',
        searchNumeroDoc: '',
        generoSelected: 'genero',
        searchCelular: '',
        searchCorreo: '',
        tratamientoDatosSelected: 'tratamiento',
    });

    // Listas
    const [tipoDocumentos, setTipoDocumentos] = useState([]);
    const [generos, setGeneros] = useState([]);

    // Condicionales
    const [loading, setLoading] = useState(false);

    const campana = location.state?.campana;

    useEffect(() => {
        setLoading(true);
        sendRequest(
            'get',
            `${URL}/${pais}/cliente/campana/${
                location.state?.campana.id
            }?limit=${limit}&page=${page}&searchID=${
                filtros.searchId
            }&searchNombres=${filtros.searchNombres}&searchApellidos=${
                filtros.searchApellidos
            }&searchTipoDoc=${
                filtros.tipoDocumentoSelected !== 'tipo'
                    ? filtros.tipoDocumentoSelected
                    : ''
            }&searchNumeroDoc=${filtros.searchNumeroDoc}&searchGenero=${
                filtros.generoSelected !== 'genero'
                    ? filtros.generoSelected
                    : ''
            }&searchCelular=${filtros.searchCelular}&searchCorreo=${
                filtros.searchCorreo
            }&searchTratamientoDatos=${
                filtros.tratamientoDatosSelected !== 'tratamiento'
                    ? filtros.tratamientoDatosSelected
                    : ''
            }`,
        ).then((res) => {
            setLoading(false);
            setRows(res.data.items);
            setRowCount(res.data.meta.totalItems);
        });
    }, [
        URL,
        pais,
        limit,
        page,
        location.state?.campana.id,
        filtros.searchId,
        filtros.searchNombres,
        filtros.searchApellidos,
        filtros.tipoDocumentoSelected,
        filtros.searchNumeroDoc,
        filtros.generoSelected,
        filtros.searchCelular,
        filtros.searchCorreo,
        filtros.tratamientoDatosSelected,
    ]);

    useEffect(() => {
        sendRequest('get', `${URL}/${pais}/tipo-documentos`).then((res) => {
            setTipoDocumentos(res.data);
        });

        sendRequest('get', `${URL}/${pais}/genero`).then((res) =>
            setGeneros(res.data),
        );
    }, [URL, pais]);

    const columns = [
        {
            field: 'id',
            headerName: 'ID',
            headerClassName: 'header',
            headerAlign: 'center',
            sortable: false,
        },
        {
            field: 'nombres',
            headerName: 'Nombres',
            flex: 0.5,
            headerClassName: 'header',
            headerAlign: 'center',
            sortable: false,
        },
        {
            field: 'apellidos',
            headerName: 'Apellidos',
            headerClassName: 'header',
            headerAlign: 'center',
            sortable: false,
        },
        {
            field: 'tipo_documento',
            headerName: 'Tipo Doc.',
            headerClassName: 'header',
            headerAlign: 'center',
            sortable: false,
            align: 'center',
            valueGetter: ({ row }) => {
                return row.tipo_documento?.tipo;
            },
        },
        {
            field: 'numero_documento',
            headerName: 'Núm. Doc.',
            headerClassName: 'header',
            headerAlign: 'center',
            sortable: false,
        },
        {
            field: 'genero',
            headerName: 'Género',
            headerClassName: 'header',
            headerAlign: 'center',
            sortable: false,
            valueGetter: ({ row }) => {
                return row.genero?.genero;
            },
        },
        {
            field: 'celular',
            headerName: 'Celular',
            headerClassName: 'header',
            headerAlign: 'center',
            sortable: false,
        },
        {
            field: 'correo',
            headerName: 'Correo',
            flex: 1,
            headerClassName: 'header',
            headerAlign: 'center',
            sortable: false,
        },
        {
            field: 'tratamiento_datos',
            headerName: 'Trat. Datos',
            headerClassName: 'header',
            headerAlign: 'center',
            align: 'center',
            sortable: false,
            renderCell: (params) => {
                if (params.value) {
                    return <CheckIcon></CheckIcon>;
                } else {
                    return <ClearIcon></ClearIcon>;
                }
            },
        },
    ];

    const handlePaginationChange = (event) => {
        setPage(event.page);
        setLimit(event.pageSize);
    };

    return (
        <Box sx={{ height: '100%', width: '100%' }}>
            <Typography
                variant="h4"
                color="primary"
                sx={{
                    textAlign: 'start',
                    marginLeft: '10px',
                    marginTop: '20px',
                }}
            >
                Clientes / {campana?.nombre}
            </Typography>
            <ListHeader
                filtros={filtros}
                setFiltros={setFiltros}
                generos={generos}
                tipoDocumentos={tipoDocumentos}
            ></ListHeader>
            <DataGrid
                localeText={esES.components.MuiDataGrid.defaultProps.localeText}
                disableColumnSelector
                disableDensitySelector
                disableColumnFilter
                disableColumnMenu
                disableRowSelectionOnClick
                loading={loading}
                initialState={{
                    pagination: {
                        paginationModel: { pageSize: limit },
                    },
                }}
                rows={rows ? rows : []}
                columns={columns}
                slots={{
                    toolbar: GridToolbar,
                }}
                slotProps={{
                    toolbar: {
                        showQuickFilter: false,
                        // Estos dos quitan el export
                        printOptions: { disableToolbarButton: true },
                        csvOptions: { disableToolbarButton: true },
                    },
                }}
                paginationMode="server"
                rowCount={rowCount ? rowCount : 0}
                onPaginationModelChange={handlePaginationChange}
                sx={DataGridStyle}
            />
        </Box>
    );
};

export default CampanaClientesVerDetalle;
