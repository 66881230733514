import { Grid, Typography } from '@mui/material';
import { DataGrid, esES } from '@mui/x-data-grid';
import dayjs from 'dayjs';
import DataGridTotalFooterColocacionDiaria from '../../../../../components/utils-components/DataGrid/DataGridTotalFooterPreReporteDiario/DataGridTotalFooterColocacionDiaria';
import { DataGridTitle } from '../../../../../components/utils-components/DataGrid/DataGridTitle/DataGridTitle';
import { DataGridStyle } from '../../../../../styles/DataGridSytle';

export const PreReporteDiario = ({ data, divisionSelected }) => {
    const columns = [
        {
            field: 'nombre_campana',
            headerName: 'Campaña',
            flex: 1,
            headerClassName: 'header',
            headerAlign: 'center',
            sortable: false,
            renderCell: ({ row }) => row.nombre,
        },
        {
            field: 'ciudad',
            headerName: 'Ciudad',
            flex: 'auto',
            headerClassName: 'header',
            headerAlign: 'center',
            sortable: false,
            renderCell: ({ row }) => row.ciudad,
        },
        {
            field: 'cantidad',
            headerName: 'Cantidad',
            flex: 'auto',
            headerClassName: 'header',
            headerAlign: 'center',
            sortable: false,
            renderCell: ({ row }) => row.conteo,
        },
    ];

    if (divisionSelected.nombre === 'Fundraising')
        columns.push({
            field: 'total',
            headerName: 'Donación',
            flex: 'auto',
            headerClassName: 'header',
            headerAlign: 'center',
            sortable: false,
            renderCell: ({ row }) => 30,
        });

    return (
        <Grid container justifyContent="center" alignItems="center">
            <Grid item xs={12} mt={5}>
                <Typography variant="h4" color="primary">
                    Colocación Diaria - {divisionSelected.nombre}
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <Typography variant="h5" color="primary">
                    {dayjs(new Date()).format('MMM DD YYYY')}
                </Typography>
            </Grid>
            {data.tmk.items.length > 0 && (
                <Grid item xs={12} md={4} mt={5} mx={5}>
                    <DataGrid
                        localeText={
                            esES.components.MuiDataGrid.defaultProps.localeText
                        }
                        disableColumnSelector
                        disableDensitySelector
                        disableColumnFilter
                        disableColumnMenu
                        disableRowSelectionOnClick
                        hideFooterPagination
                        columns={columns}
                        rows={data.tmk.items}
                        slots={{
                            toolbar: DataGridTitle,
                            footer: DataGridTotalFooterColocacionDiaria,
                        }}
                        slotProps={{
                            toolbar: {
                                title: 'Telemarketing',
                            },
                            footer: {
                                total: data.tmk.total,
                            },
                        }}
                        sx={[
                            DataGridStyle,
                            {
                                '& .MuiDataGrid-columnHeaders': {
                                    borderRadius: 0,
                                },
                            },
                        ]}
                    />
                </Grid>
            )}
            {data.f2f.items.length > 0 && (
                <Grid item xs={12} md={4} mt={5} mx={5}>
                    {/* F2F */}
                    <DataGrid
                        localeText={
                            esES.components.MuiDataGrid.defaultProps.localeText
                        }
                        disableColumnSelector
                        disableDensitySelector
                        disableColumnFilter
                        disableColumnMenu
                        disableRowSelectionOnClick
                        hideFooterPagination
                        columns={columns}
                        rows={data.f2f.items}
                        slots={{
                            toolbar: DataGridTitle,
                            footer: DataGridTotalFooterColocacionDiaria,
                        }}
                        slotProps={{
                            toolbar: {
                                title: 'Face to Face',
                            },
                            footer: {
                                total: data.f2f.total,
                            },
                        }}
                        sx={[
                            DataGridStyle,
                            {
                                '& .MuiDataGrid-columnHeaders': {
                                    borderRadius: 0,
                                },
                            },
                        ]}
                    />
                </Grid>
            )}
            {data.tmk_f2f.items.length > 0 && (
                <Grid item xs={12} md={4} mt={5} mx={5}>
                    {/* Mix */}
                    <DataGrid
                        localeText={
                            esES.components.MuiDataGrid.defaultProps.localeText
                        }
                        disableColumnSelector
                        disableDensitySelector
                        disableColumnFilter
                        disableColumnMenu
                        disableRowSelectionOnClick
                        hideFooterPagination
                        columns={columns}
                        rows={data.tmk_f2f.items}
                        slots={{
                            toolbar: DataGridTitle,
                            footer: DataGridTotalFooterColocacionDiaria,
                        }}
                        slotProps={{
                            toolbar: {
                                title: 'Telemarketing & F2F',
                            },
                            footer: {
                                total: data.tmk_f2f.total,
                            },
                        }}
                        sx={[
                            DataGridStyle,
                            {
                                '& .MuiDataGrid-columnHeaders': {
                                    borderRadius: 0,
                                },
                            },
                        ]}
                    />
                </Grid>
            )}
        </Grid>
    );
};
