import { CheckBox, CheckBoxOutlineBlank } from '@mui/icons-material';
import {
    Autocomplete,
    Box,
    Card,
    Checkbox,
    Grid,
    TextField,
    Typography,
} from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';

const style = {
    p: 0,
    width: '98%',
    textAlign: 'left',
    marginLeft: '20px',
};

const ListHeader = ({
    filtros,
    setFiltros,
    campanas,
    estadosOrdenImpresion,
}) => {
    const handleSearchNumero = (e) => {
        setTimeout(() => {
            setFiltros({ ...filtros, numero: e.target.value });
        }, 2000);
    };

    const handleSearchCampanas = (e, newValue) => {
        const campanasSelected = [];
        for (const value of newValue) {
            campanasSelected.push(value.id);
        }
        setFiltros({
            ...filtros,
            campanas: campanasSelected,
        });
    };

    const handleSearchEstado = (e, newValue) => {
        setFiltros({
            ...filtros,
            estado: newValue ? newValue.id : '',
        });
    };

    const handleSearchFecha = (newValue) => {
        setFiltros({
            ...filtros,
            fecha: newValue ? dayjs(newValue) : '',
        });
    };

    return (
        <Box sx={style} component="form">
            <Grid container pt={1}>
                <Grid item xs={12} md={9}>
                    <Card
                        variant="outlined"
                        sx={{
                            backgroundColor: '#D9D9D9',
                        }}
                    >
                        <Grid
                            container
                            py={2}
                            px={2}
                            alignItems="flex-start"
                            justifyContent="space-between"
                        >
                            <Grid item xs={12} md={12}>
                                <Typography color="primary" variant="h6">
                                    Filtros
                                </Typography>
                            </Grid>
                            <Grid item xs={2} md="auto">
                                <TextField
                                    label="Número Orden de Inv."
                                    size="small"
                                    variant="standard"
                                    onChange={handleSearchNumero}
                                ></TextField>
                            </Grid>
                            <Grid item xs={2} md={2.4}>
                                <Autocomplete
                                    multiple
                                    disableCloseOnSelect
                                    disablePortal
                                    limitTags={1}
                                    options={campanas}
                                    getOptionLabel={(option) => option.nombre}
                                    onChange={handleSearchCampanas}
                                    size="small"
                                    renderOption={(
                                        props,
                                        option,
                                        { selected },
                                    ) => (
                                        <li {...props} key={option.id}>
                                            <Checkbox
                                                sx={{
                                                    ml: -1,
                                                }}
                                                icon={
                                                    <CheckBoxOutlineBlank fontSize="small" />
                                                }
                                                checkedIcon={
                                                    <CheckBox fontSize="small" />
                                                }
                                                checked={selected}
                                            />
                                            {option.nombre}
                                        </li>
                                    )}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            variant="standard"
                                            label="Campañas"
                                            size="small"
                                        />
                                    )}
                                    ListboxProps={{
                                        sx: {
                                            maxHeight: 200,
                                            overflow: 'auto',
                                        },
                                    }}
                                ></Autocomplete>
                            </Grid>
                            <Grid item xs={2} md={3}>
                                <Autocomplete
                                    disablePortal
                                    options={estadosOrdenImpresion}
                                    getOptionLabel={(option) => option.nombre}
                                    onChange={handleSearchEstado}
                                    size="small"
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            variant="standard"
                                            label="Estado"
                                            size="small"
                                        />
                                    )}
                                    ListboxProps={{
                                        sx: {
                                            maxHeight: 200,
                                            overflow: 'auto',
                                        },
                                    }}
                                ></Autocomplete>
                            </Grid>
                            <Grid item xs={2} md="auto">
                                <LocalizationProvider
                                    dateAdapter={AdapterDayjs}
                                >
                                    <DatePicker
                                        format="DD-MM-YYYY"
                                        label="Fecha de Solicitud"
                                        value={filtros.fecha || null}
                                        slotProps={{
                                            textField: {
                                                variant: 'standard',
                                            },
                                        }}
                                        // onOpen={() =>
                                        //     setFiltros({
                                        //         ...filtros,
                                        //         fecha: filtros.fecha
                                        //             ? dayjs(filtros.fecha)
                                        //             : dayjs(new Date()),
                                        //     })
                                        // }
                                        onChange={handleSearchFecha}
                                    ></DatePicker>
                                </LocalizationProvider>
                            </Grid>
                        </Grid>
                    </Card>
                </Grid>
            </Grid>
        </Box>
    );
};

export default ListHeader;
