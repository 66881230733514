import {
    Box,
    Button,
    Chip,
    Grid,
    IconButton,
    Modal,
    Typography,
} from '@mui/material';
import { DataGrid, esES, GridToolbar } from '@mui/x-data-grid';
import { useEffect, useState } from 'react';
import { AssignmentTurnedIn } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { sendRequest } from '../../../../../services/request';
import ExportarModal from '../exportar/exportarModal';
import { DataGridStyle } from '../../../../../styles/DataGridSytle';

const EnvioCampanaPreview = ({ pais }) => {
    const [campanas, setCampanas] = useState([]);
    const [count, setCount] = useState(0);
    const [limit, setLimit] = useState(10);
    const [page, setPage] = useState(0);

    const [open, setOpen] = useState(0);

    // Loading
    const [loading, setLoading] = useState(false);

    const URL = process.env.REACT_APP_API_URL;
    const navigate = useNavigate();

    useEffect(() => {
        setLoading(true);
        const fetchData = async () => {
            try {
                const res = await sendRequest(
                    'get',
                    `${URL}/${pais}/campana/envios?limit=${limit}&page=${page}`,
                );
                setLoading(false);
                setCampanas(res.data.items);
                setCount(res.data.meta.totalItems);
            } catch (error) {
                console.log(error);
            }
        };
        fetchData();
    }, [URL, pais, limit, page]);

    const columns = [
        {
            field: 'nombre',
            headerName: 'Nombre',
            headerClassName: 'header',
            headerAlign: 'center',
            sortable: false,
            flex: 2,
        },
        {
            field: 'ciudad',
            headerName: 'Ciudad',
            headerClassName: 'header',
            headerAlign: 'center',
            sortable: false,
            flex: 1.5,
            renderCell: ({ row }) => {
                return row.ciudad?.nombre;
            },
        },
        {
            field: 'division',
            headerName: 'Division',
            headerClassName: 'header',
            headerAlign: 'center',
            sortable: false,
            flex: 1,
            renderCell: ({ row }) => {
                return (
                    <Chip
                        label={row.division?.nombre}
                        color={
                            row.division?.id === 1
                                ? 'publicidad'
                                : 'fundraising'
                        }
                    />
                );
            },
        },
        {
            field: 'sector',
            headerName: 'Sector',
            headerClassName: 'header',
            headerAlign: 'center',
            sortable: false,
            flex: 1.5,
            renderCell: ({ row }) => {
                return row.sector?.sector;
                /* return (
                    <Chip
                        label={row.sector?.sector}
                        color={
                            row.sector?.id === 1
                                ? 'secondary'
                                : row.sector?.id === 2
                                ? 'automotriz'
                                : row.sector?.id === 3
                                ? 'entretenimiento'
                                : row.sector?.id === 4
                                ? 'hyt'
                                : row.sector?.id === 5
                                ? 'bienestar'
                                : 'secondary'
                        }
                    />
                ); */
            },
        },
        {
            field: 'estrategia',
            headerName: 'Canal',
            headerClassName: 'header',
            headerAlign: 'center',
            sortable: false,
            flex: 1,
            renderCell: ({ row }) => {
                return row.estrategia;
                /* return (
                    <Chip
                        label={row.estrategia}
                        color={
                            row.estrategia && row.estrategia === 'F2F'
                                ? 'f2f'
                                : 'tmk'
                        }
                        style={
                            row.estrategia === 'TMK y F2F'
                                ? {
                                      background:
                                          'linear-gradient(to right, #05F247, #A605F2)',
                                  }
                                : undefined
                        }
                    />
                ); */
            },
        },
        {
            field: 'acciones',
            type: 'actions',
            cellClassName: 'actions',
            headerName: 'Acciones',
            headerClassName: 'header',
            flex: 1.8,
            getActions: ({ row }) => {
                return [
                    <IconButton
                        color="primary"
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            borderRadius: 0,
                        }}
                        onClick={() => handleVerDetalle(row)}
                    >
                        <AssignmentTurnedIn />
                        <Typography>Ver Detalle</Typography>
                    </IconButton>,
                ];
            },
        },
    ];

    const handlePaginationChange = (event, page) => {
        setPage(page);
        setLimit(event.pageSize);
    };

    const handleVerDetalle = (row) => {
        navigate('/app/inventario/envio/detalle', {
            state: { campana: row },
        });
    };

    const handleExportar = () => {
        setOpen(!open);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <Box sx={{ height: '100%', width: '100%' }}>
            <Grid container spacing={5} py={3} pr={3}>
                <Grid item xs={6} md={8}>
                    <Typography
                        variant="h4"
                        color="primary"
                        sx={{
                            textAlign: 'start',
                            marginLeft: '10px',
                        }}
                    >
                        Envío y Entrega a Clientes Finales / Campañas
                    </Typography>
                </Grid>
                <Grid item xs={6} md={4}>
                    <Button
                        fullWidth
                        variant="contained"
                        onClick={handleExportar}
                    >
                        Generar Ruta según Canal Envío
                    </Button>
                </Grid>
            </Grid>
            {/* <ListHeader
                page={page}
                setPage={setPage}
                setSearchNombre={setSearchNombre}
                departamentos={departamentos}
                departamentoSelected={departamentoSelected}
                setDepartamentoSelected={setDepartamentoSelected}
                ciudades={ciudades}
                setCiudadSelected={setCiudadSelected}
                divisiones={divisiones}
                setDivisionSelected={setDivisionSelected}
                anos={anos}
                setAnoSelected={setAnoSelected}
                canales={canales}
                setCanalSelected={setCanalSelected}
                sectores={sectores}
                setSectorSelected={setSectorSelected}
                vigente={vigente}
                setVigente={setVigente}
                pais={pais}
            ></ListHeader> */}
            <Grid container alignItems="center" justifyContent="center">
                <Grid item lg={9}>
                    <DataGrid
                        localeText={
                            esES.components.MuiDataGrid.defaultProps.localeText
                        }
                        disableColumnSelector
                        disableDensitySelector
                        disableColumnFilter
                        disableColumnMenu
                        loading={loading}
                        initialState={{
                            pagination: {
                                paginationModel: { pageSize: limit },
                            },
                        }}
                        rows={campanas ? campanas : []}
                        columns={columns}
                        paginationMode="server"
                        rowCount={count ? count : 0}
                        onPaginationModelChange={handlePaginationChange}
                        slots={{
                            toolbar: GridToolbar,
                        }}
                        slotProps={{
                            toolbar: {
                                showQuickFilter: false,
                                // Estos dos quitan el export
                                printOptions: { disableToolbarButton: true },
                                csvOptions: { disableToolbarButton: true },
                            },
                            /* footer: {
                        limit,
                        page,
                        count: count,
                        handlePaginationChange,
                    }, */
                        }}
                        sx={DataGridStyle}
                    ></DataGrid>
                </Grid>
            </Grid>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <div>
                    <ExportarModal
                        pais={pais}
                        handleClose={handleClose}
                    ></ExportarModal>
                </div>
            </Modal>
        </Box>
    );
};

export default EnvioCampanaPreview;
