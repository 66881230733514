import { ROLES } from '../components/utils-components/permissions/permissions-map';

const navigationConfig = [
    {
        key: 'Dashboard',
        roles: [
            ROLES.GERENCIA_EFFECTIVE,
            ROLES.DIRECTOR_ADMINISTRATIVO,
            ROLES.LOGISTICA,
            ROLES.IMPRESION,
            ROLES.GERENCIA_WINNER,
            ROLES.DIRECTOR_OPERATIVO,
        ],
        items: [],
    },
    {
        key: 'Clientes',
        roles: [
            ROLES.GERENCIA_EFFECTIVE,
            ROLES.DIRECTOR_ADMINISTRATIVO,
            ROLES.LOGISTICA,
            ROLES.GERENCIA_WINNER,
            ROLES.DIRECTOR_OPERATIVO,
        ],
        items: [],
    },
    {
        key: 'Campañas',
        roles: [
            ROLES.GERENCIA_EFFECTIVE,
            ROLES.DIRECTOR_ADMINISTRATIVO,
            ROLES.GERENCIA_WINNER,
            /* ROLES.DIRECTOR_OPERATIVO, */
        ],
        items: [],
    },
    {
        key: 'Equipos',
        roles: [
            ROLES.GERENCIA_EFFECTIVE,
            ROLES.DIRECTOR_ADMINISTRATIVO,
            ROLES.GERENCIA_WINNER,
            ROLES.DIRECTOR_OPERATIVO,
        ],
        items: [],
    },
    {
        key: 'Inventario',
        roles: [
            ROLES.GERENCIA_EFFECTIVE,
            ROLES.DIRECTOR_ADMINISTRATIVO,
            ROLES.LOGISTICA,
            ROLES.IMPRESION,
            ROLES.GERENCIA_WINNER,
            ROLES.DIRECTOR_OPERATIVO,
        ],
        items: [],
    },
    /* {
        key: 'Validar BD Jotform',
        roles: [ROLES.DIRECTOR_ADMINISTRATIVO, ROLES.DIRECTOR_OPERATIVO],
    }, */
    {
        key: 'Mis Estadísticas',
        roles: [ROLES.PROMOTOR],
    },
    {
        key: 'Agenda',
        roles: [ROLES.DIRECTOR_OPERATIVO, ROLES.PROMOTOR],
    },
    {
        key: 'Mi BD',
        roles: [ROLES.DIRECTOR_OPERATIVO, ROLES.PROMOTOR],
    },
];

export default navigationConfig;
