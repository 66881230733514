import { Grid, Typography } from '@mui/material';

const DataGridTotalFooterColocacionDiaria = (props) => {
    return (
        <Grid container py={1}>
            <Grid item xs={10} textAlign="start" pl={2.7}>
                <Typography variant="p" fontWeight="bold">
                    Total
                </Typography>
            </Grid>
            <Grid item xs={2} textAlign="start" pl={2.7}>
                <Typography variant="p" fontWeight="bold">
                    {props.total}
                </Typography>
            </Grid>
        </Grid>
    );
};

export default DataGridTotalFooterColocacionDiaria;
