import { Box, Button, Grid, Typography } from '@mui/material';
import { DataGrid, GridToolbar, esES } from '@mui/x-data-grid';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { sendRequest } from '../../../../../services/request';
import { DataGridStyle } from '../../../../../styles/DataGridSytle';

const ClientesPorDesasignar = ({ pais }) => {
    const [rows, setRows] = useState();
    const [limit, setLimit] = useState(50);
    const [page, setPage] = useState(0);
    const [rowCount, setRowCount] = useState(null);

    // Campos selected
    const [rowsSelected, setRowsSelected] = useState([]);

    // Condicionales
    const [loading, setLoading] = useState(false);
    const [recargar, setRecargar] = useState(false);

    const URL = `${process.env.REACT_APP_API_URL}`;
    const location = useLocation();
    const campanaId = location.state?.campanaId;
    const promotor = location.state?.promotor;

    useEffect(() => {
        setLoading(true);
        sendRequest(
            'get',
            `${URL}/${pais}/clientes-asignados/campana/${campanaId}/promotor/${promotor.id}?page=${page}&limit=${limit}&notContactabilidad=4`,
        ).then((res) => {
            setLoading(false);
            setRows(res.data.items);
            setRowCount(res.data.meta.totalItems);
        });
    }, [URL, pais, limit, page, campanaId, promotor.id, recargar]);

    const columns = [
        {
            field: 'nombres',
            headerName: 'Nombres',
            flex: 1,
            headerClassName: 'header',
            headerAlign: 'center',
            sortable: false,
            renderCell: ({ row }) => row.cliente.nombres,
        },
        {
            field: 'apellidos',
            headerName: 'Apellidos',
            headerClassName: 'header',
            headerAlign: 'center',
            sortable: false,
            flex: 1,
            renderCell: ({ row }) => row.cliente?.apellidos,
        },
        {
            field: 'celular',
            headerName: 'Celular',
            headerClassName: 'header',
            headerAlign: 'center',
            sortable: false,
            flex: 1,
            renderCell: ({ row }) => row.cliente.celular,
        },
    ];

    const handlePaginationChange = (event) => {
        setPage(event.page);
        setLimit(event.pageSize);
    };

    const handleDesasignar = async () => {
        try {
            if (rowsSelected.length > 0) {
                await sendRequest(
                    'patch',
                    `${URL}/${pais}/clientes-asignados/promotor/desasignar`,
                    {
                        ids: rowsSelected,
                    },
                );
                setRecargar(!recargar);
            }
        } catch (error) {
            console.log(error);
        }
    };

    return (
        <Box sx={{ height: '100%', width: '100%' }}>
            <Grid container spacing={5} py={3} pr={3}>
                <Grid item xs={12} md={8}>
                    <Typography
                        variant="h4"
                        color="primary"
                        sx={{
                            textAlign: 'start',
                            marginLeft: '10px',
                        }}
                    >
                        Desasignar Clientes /{' '}
                        {`${promotor?.nombres} ${promotor?.apellidos}`}
                    </Typography>
                </Grid>
                <Grid item xs={12} md={4}>
                    <Button
                        fullWidth
                        variant="contained"
                        onClick={handleDesasignar}
                    >
                        Desasignar Clientes
                    </Button>
                </Grid>
            </Grid>
            <DataGrid
                localeText={esES.components.MuiDataGrid.defaultProps.localeText}
                disableColumnSelector
                disableDensitySelector
                disableColumnFilter
                disableColumnMenu
                checkboxSelection
                disableRowSelectionOnClick
                loading={loading}
                initialState={{
                    pagination: {
                        paginationModel: { pageSize: limit },
                    },
                }}
                rows={rows ? rows : []}
                columns={columns}
                slots={{
                    toolbar: GridToolbar,
                }}
                slotProps={{
                    toolbar: {
                        showQuickFilter: false,
                        // Estos dos quitan el export
                        printOptions: { disableToolbarButton: true },
                        csvOptions: { disableToolbarButton: true },
                    },
                }}
                paginationMode="server"
                rowCount={rowCount ? rowCount : 0}
                onPaginationModelChange={handlePaginationChange}
                onRowSelectionModelChange={(newRowSelection, values) => {
                    setRowsSelected(newRowSelection);
                }}
                sx={DataGridStyle}
            />
        </Box>
    );
};

export default ClientesPorDesasignar;
