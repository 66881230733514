import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { ThemeProvider } from '@mui/material/styles';
import Logos from '../../../images/logos.png';
import BGImage from '../../../images/Login BG image.png';
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { Alert, AlertTitle, Collapse } from '@mui/material';
import { useTheme } from '@emotion/react';
import { signIn } from '../../../services/CognitoAuthService';
import { useDispatch } from 'react-redux';
import { authenticated } from '../../../redux/actions/auth';
import { sendRequest } from '../../../services/request';
import axios from 'axios';

function Copyright(props) {
    return (
        <Typography
            variant="body2"
            color="text.secondary"
            align="center"
            {...props}
        >
            {'Copyright © '}
            <Link color="inherit" href="https://effectivemk.com/">
                Effective Marketing
            </Link>
            {' & '}
            <Link color="inherit" href="https://www.winnermk.com/">
                Winner Marketing
            </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}

export default function Login() {
    const [alert, setAlert] = useState({
        show: false,
        title: '',
        message: '',
        type: '',
    });
    const [imgUrl, setImgUrl] = useState(null);
    const navigate = useNavigate();
    const defaultTheme = useTheme();
    const dispatch = useDispatch();
    const URL = process.env.REACT_APP_API_URL;
    const UNSPLASH_URL = process.env.REACT_APP_UNSPLASH_URL;
    const UNSPLASH_ACCESS_KEY = process.env.REACT_APP_UNSPLASH_ACCESS_KEY;

    useEffect(() => {
        const fetchImgData = async () => {
            const res = await axios.get(`${UNSPLASH_URL}/photos/random`, {
                headers: {
                    Authorization: `Client-ID ${UNSPLASH_ACCESS_KEY}`,
                },
            });
            setImgUrl(res.data.urls.full);
        };
        fetchImgData();
    }, [UNSPLASH_URL, UNSPLASH_ACCESS_KEY]);

    const handleSubmit = async (event) => {
        try {
            event.preventDefault();
            const data = new FormData(event.currentTarget);
            const cognitoToken = await signIn(
                data.get('email').toLowerCase(),
                data.get('password'),
            );
            const jwtToken = cognitoToken.getIdToken().getJwtToken();
            const refreshToken = cognitoToken.getRefreshToken().getToken();
            const accesToken = cognitoToken.getAccessToken().getJwtToken();
            let usuario = await sendRequest(
                'get',
                `${URL}/usuario?email=${encodeURIComponent(data.get('email'))}`,
            );
            dispatch(
                authenticated(
                    jwtToken,
                    refreshToken,
                    accesToken,
                    usuario.data?.rol?.nombre,
                    usuario.data,
                ),
            );
        } catch (error) {
            if (error === 'changePassword') {
                navigate('/auth/passwordChallenge');
            }
            setAlert({
                show: true,
                title: 'Error',
                type: 'error',
                message:
                    'Correo o contraseña no validas, por favor vuelve a intentarlo.',
            });
            setTimeout(() => {
                setAlert({ show: false, title: '', message: '', type: '' });
            }, 3000);
        }
    };

    return (
        <ThemeProvider theme={defaultTheme}>
            <Grid
                container
                component="main"
                sx={{
                    height: '100vh',
                    backgroundImage: `url('${BGImage}')`,
                    backgroundSize: 'cover',
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: 'center',
                }}
            >
                <Grid
                    container
                    component={Paper}
                    elevation={5}
                    square
                    sx={{
                        height: '90vh',
                        width: '90vw',
                        marginY: '5vh',
                        marginX: '5vw',
                    }}
                >
                    <CssBaseline />
                    <Grid item xs={12} sm={8} md={6}>
                        <Box
                            sx={{
                                my: 8,
                                mx: 4,
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                            }}
                        >
                            <Typography component="h1" variant="h5">
                                CRM - Effective Marketing / Winner Marketing
                            </Typography>
                            <Box
                                component="img"
                                sx={{
                                    height: '10vh',
                                    maxHeight: { xs: '10vh', md: '15vh' },
                                }}
                                alt="logos"
                                src={Logos}
                            ></Box>
                            <Box
                                component="form"
                                noValidate
                                onSubmit={handleSubmit}
                                sx={{ mt: 1 }}
                            >
                                <TextField
                                    margin="normal"
                                    required
                                    fullWidth
                                    id="email"
                                    label="Correo electrónico"
                                    name="email"
                                    autoComplete="email"
                                    autoFocus
                                />
                                <TextField
                                    margin="normal"
                                    required
                                    fullWidth
                                    name="password"
                                    label="Contraseña"
                                    type="password"
                                    id="password"
                                    autoComplete="current-password"
                                />
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            value="remember"
                                            color="primary"
                                        />
                                    }
                                    label="Recordar mis datos"
                                />
                                {alert.show && (
                                    <Collapse in={alert.show}>
                                        <Alert severity={alert.type}>
                                            <AlertTitle
                                                sx={{ textAlign: 'left' }}
                                            >
                                                {alert.title}
                                            </AlertTitle>
                                            {alert.message}
                                        </Alert>
                                    </Collapse>
                                )}
                                <Button
                                    type="submit"
                                    fullWidth
                                    variant="contained"
                                    sx={{ mt: 3, mb: 2 }}
                                >
                                    Ingresar
                                </Button>
                                <Grid container>
                                    <Grid item xs>
                                        <Link href="#" variant="body2">
                                            ¿Olvidó su contraseña?
                                        </Link>
                                    </Grid>
                                </Grid>
                                <Copyright sx={{ mt: 5 }} />
                            </Box>
                        </Box>
                    </Grid>
                    <Grid
                        item
                        xs={false}
                        sm={4}
                        md={6}
                        sx={{
                            backgroundImage: `url(${imgUrl})`,
                            backgroundRepeat: 'no-repeat',
                            backgroundColor: (t) =>
                                t.palette.mode === 'light'
                                    ? t.palette.grey[50]
                                    : t.palette.grey[900],
                            backgroundSize: 'cover',
                            backgroundPosition: 'center',
                        }}
                    />
                </Grid>
            </Grid>
        </ThemeProvider>
    );
}
