import { Grid, Typography } from '@mui/material';

const Reportes = () => {
    return (
        <Grid container spacing={5} py={3} pr={3}>
            <Grid item xs={12} md={8}>
                <Typography
                    variant="h4"
                    color="primary"
                    sx={{
                        textAlign: 'start',
                        marginLeft: '10px',
                    }}
                >
                    Reportes
                </Typography>
            </Grid>
        </Grid>
    );
};

export default Reportes;
