import { Box, Typography } from '@mui/material';

export const DataGridTitle = (props) => {
    return (
        <Box
            sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                color: 'primary.contrastText',
                backgroundColor: 'primary.main',
                borderTopLeftRadius: 5,
                borderTopRightRadius: 5,
            }}
        >
            <Typography
                variant="h5"
                sx={{
                    color: 'primary.contrastText',
                    backgroundColor: 'primary.main',
                }}
            >
                {props.title}
            </Typography>
        </Box>
    );
};
