import {
    Autocomplete,
    Box,
    Button,
    Grid,
    TextField,
    Typography,
} from '@mui/material';
import { useState } from 'react';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '80%',
    height: '55%',
    bgcolor: 'background.paper',
    overflowY: 'auto',
    boxShadow: 24,
    px: 4,
};

const styleModalHeader = {
    top: 0,
    textAlign: 'center',
    position: 'sticky',
    zIndex: 15,
    py: 2,
    /* borderBottom: 'solid #0B3E83 1px', */
    backgroundColor: '#ffffff',
};

const styleModalFooter = {
    bottom: 0,
    top: 0,
    position: 'sticky',
    mt: 12,
    pb: 3,
    backgroundColor: '#ffffff',
};

const EditarEquipo = ({ equipo, lideres, handleClose, handleEditar }) => {
    const [nombre, setNombre] = useState(equipo.nombre);
    const [lider, setLider] = useState(equipo.lider);

    const handleChangeNombre = (e) => {
        setNombre(e.target.value);
    };

    const handleChangeLider = (e, newValue) => {
        console.log(newValue);
        setLider(newValue);
    };

    return (
        <Box sx={style} component="form">
            <Box sx={styleModalHeader}>
                <Typography id="modal-modal-title" variant="h5" color="primary">
                    Editar Equipo
                </Typography>
            </Box>
            <Grid container spacing={5} sx={{ mt: '2px' }}>
                <Grid item xs={12} md={6}>
                    <TextField
                        required
                        fullWidth
                        id="nombre"
                        label="Nombre"
                        placeholder="Nombre del equipo"
                        variant="outlined"
                        value={nombre}
                        onChange={handleChangeNombre}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <Autocomplete
                        disabled={lideres?.length <= 0}
                        disablePortal
                        value={lider}
                        options={lideres}
                        getOptionLabel={(option) =>
                            `${option.nombres} ${option.apellidos}`
                        }
                        sx={{ width: 300 }}
                        renderInput={(params) => (
                            <TextField {...params} label="Lider" />
                        )}
                        onChange={handleChangeLider}
                    />
                    {/* <TextField
                        required
                        fullWidth
                        id="lider"
                        label="Lider"
                        placeholder="Lider del equipo"
                        variant="outlined"
                        value={`${lider.nombres} ${lider.apellidos}`}
                        onChange={handleChangeNombre}
                    /> */}
                </Grid>
            </Grid>
            <Grid container spacing={5} sx={styleModalFooter}>
                <Grid item xs={12} md={6}>
                    <Button fullWidth variant="outlined" onClick={handleClose}>
                        Cerrar
                    </Button>
                </Grid>
                <Grid item xs={12} md={6}>
                    <Button
                        fullWidth
                        variant="contained"
                        onClick={() =>
                            handleEditar(equipo.id, {
                                nombre,
                                lider,
                            })
                        }
                    >
                        Editar
                    </Button>
                </Grid>
            </Grid>
        </Box>
    );
};

export default EditarEquipo;
