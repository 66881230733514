import { Box, Button, Grid, Modal, Tab, Tabs, Typography } from '@mui/material';
import { sendRequest } from '../../../services/request';
import { useEffect, useState } from 'react';
import CrearUsuario from './usuarios/crearUsuario';
import ManejoUsuarios from './usuarios';

const Mantenimiento = () => {
    const URL = process.env.REACT_APP_API_URL;
    const [roles, setRoles] = useState([]);
    const [openModal, setOpenModal] = useState(false);
    const [selectedTab /* setSelectedTab */] = useState(0);
    const [reload, setReload] = useState(false);

    let tabList = [
        {
            label: 'Manejo de Usuarios',
            value: 0,
        },
    ];

    const paises = [
        {
            id: 1,
            nombre: 'Colombia',
        },
        {
            id: 2,
            nombre: 'Panamá',
        },
    ];

    useEffect(() => {
        const fetchData = async () => {
            try {
                const res = await sendRequest('get', `${URL}/rol`);
                setRoles(res.data);
            } catch (error) {
                console.log(error);
            }
        };
        fetchData();
    }, [URL]);

    const handleCloseModal = () => {
        setOpenModal(false);
    };

    const handleOpenModal = () => {
        setOpenModal(true);
    };

    const handleCrearUsuario = async (dataUsuario) => {
        try {
            await sendRequest('post', `${URL}/usuario`, dataUsuario);
            setReload(!reload);
            handleCloseModal();
        } catch (error) {
            console.log(error);
        }
    };

    const hanldeChangeTab = () => {};

    return (
        <>
            <Box sx={{ height: '100%', width: '100%' }}>
                <Grid container spacing={5} py={3} pr={3}>
                    <Grid item xs={12} md={8}>
                        <Typography
                            variant="h4"
                            color="primary"
                            sx={{
                                textAlign: 'start',
                                marginLeft: '10px',
                            }}
                        >
                            Mantenimiento
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Button
                            fullWidth
                            variant="contained"
                            onClick={handleOpenModal}
                        >
                            Crear nuevo Usuario
                        </Button>
                    </Grid>
                </Grid>
                <Tabs
                    value={selectedTab}
                    onChange={hanldeChangeTab}
                    sx={{ pl: 2 }}
                >
                    {tabList.map((item) => (
                        <Tab
                            sx={{ textTransform: 'none' }}
                            label={item.label}
                            value={item.value}
                        ></Tab>
                    ))}
                </Tabs>
                {selectedTab === 0 && <ManejoUsuarios></ManejoUsuarios>}
            </Box>
            <Modal
                open={openModal}
                onClose={handleCloseModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <div>
                    <CrearUsuario
                        roles={roles}
                        paises={paises}
                        handleClose={handleCloseModal}
                        handleCrear={handleCrearUsuario}
                        reload={reload}
                    ></CrearUsuario>
                </div>
            </Modal>
        </>
    );
};

export default Mantenimiento;
