import {
    Box,
    Card,
    Grid,
    TextField,
    Typography,
    Autocomplete,
    Checkbox,
} from '@mui/material';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import dayjs from 'dayjs';

const style = {
    p: 0,
    textAlign: 'left',
    marginLeft: '20px',
};

const ListHeader = ({ campañas, canales, search, setSearch }) => {
    const handleCanalesSelected = (e, newValue) => {
        setSearch({ ...search, canal: newValue?.canal || '' });
    };

    const handleCampanaSelected = (e, newValue) => {
        const campanasSelected = [];
        for (const value of newValue) {
            campanasSelected.push(value.id);
        }
        setSearch({
            ...search,
            campana: [search.campana[0], ...campanasSelected],
        });
    };

    return (
        <Box sx={style} component="form">
            <Grid
                container
                spacing={2}
                justifyContent="flex-start"
                alignItems="flex-start"
            >
                <Grid item sm={12}>
                    <Card
                        variant="outlined"
                        sx={{
                            backgroundColor: '#D9D9D9',
                            mt: 2,
                        }}
                    >
                        <Typography
                            color="primary"
                            variant="h6"
                            sx={{ pl: 2, pb: 2 }}
                        >
                            Filtrar por
                        </Typography>
                        <Grid
                            container
                            spacing={1}
                            justifyContent="flex-start"
                            alignItems="flex-start"
                            pb={2}
                            ml={1}
                        >
                            <Grid item xs={5} mr={2}>
                                <Autocomplete
                                    options={canales}
                                    getOptionLabel={(option) => option.canal}
                                    onChange={handleCanalesSelected}
                                    size="small"
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            placeholder=""
                                            variant="standard"
                                            label="Canal"
                                        />
                                    )}
                                />
                            </Grid>

                            <Grid item xs={5}>
                                <Autocomplete
                                    disabled={!search.canal}
                                    multiple
                                    disableCloseOnSelect
                                    disablePortal
                                    limitTags={1}
                                    options={campañas}
                                    getOptionLabel={(option) => option.nombre}
                                    onChange={handleCampanaSelected}
                                    size="small"
                                    renderOption={(
                                        props,
                                        option,
                                        { selected },
                                    ) => (
                                        <li {...props} key={option.id}>
                                            <Checkbox
                                                sx={{
                                                    ml: -1,
                                                }}
                                                icon={
                                                    <CheckBoxOutlineBlankIcon fontSize="small" />
                                                }
                                                checkedIcon={
                                                    <CheckBoxIcon fontSize="small" />
                                                }
                                                checked={selected}
                                            />
                                            {option.nombre}
                                            {option.ciudad
                                                ? ` - ${option.ciudad.nombre}`
                                                : ''}{' '}
                                            -{' '}
                                            {dayjs(option.vigencia).format(
                                                'YYYY',
                                            )}
                                        </li>
                                    )}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            placeholder=""
                                            variant="standard"
                                            label="Campaña"
                                        />
                                    )}
                                    ListboxProps={{
                                        sx: {
                                            maxHeight: 200,
                                            overflow: 'auto',
                                        },
                                    }}
                                />
                            </Grid>
                        </Grid>
                    </Card>
                </Grid>
            </Grid>
        </Box>
    );
};

export default ListHeader;
