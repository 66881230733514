import {
    Box,
    Card,
    Grid,
    MenuItem,
    TextField,
    Typography,
} from '@mui/material';

const style = {
    p: 0,
    width: '98%',
    textAlign: 'left',
    marginLeft: '20px',
};

const ListHeader = ({
    setSearchId,
    setSearchNombres,
    setSearchApellidos,
    tipoDocumentos,
    tipoDocumentoSelected,
    setTipoDocumentoSelected,
    setSearchNumeroDoc,
    generos,
    generoSelected,
    setGeneroSelected,
    setSearchCelular,
    setSearchCorreo,
    tratamientoDatosSelected,
    setTratamientoDatosSelected,
}) => {
    const handleChangeSearchId = (e) => {
        setTimeout(() => {
            setSearchId(e.target.value);
        }, 2000);
    };

    const handleChangeSearchNombres = (e) => {
        setTimeout(() => {
            setSearchNombres(e.target.value);
        }, 2000);
    };

    const handleChangeSearchApellidos = (e) => {
        setTimeout(() => {
            setSearchApellidos(e.target.value);
        }, 2000);
    };

    const handleSelectTipoDocumento = (e) => {
        setTipoDocumentoSelected(e.target.value);
    };

    const handleChangeSearchNumeroDoc = (e) => {
        setTimeout(() => {
            setSearchNumeroDoc(e.target.value);
        }, 2000);
    };

    const handleSelectGenero = (e) => {
        setGeneroSelected(e.target.value);
    };

    const handleChangeSearchCelular = (e) => {
        setTimeout(() => {
            setSearchCelular(e.target.value);
        }, 2000);
    };

    const handleChangeSearchCorreo = (e) => {
        setTimeout(() => {
            setSearchCorreo(e.target.value);
        }, 2000);
    };

    const handleSelectTratamientoDatos = (e) => {
        setTratamientoDatosSelected(e.target.value);
    };

    return (
        <Box sx={style} component="form">
            <Grid container pt={1}>
                <Grid item xs={12} md={11}>
                    <Card
                        variant="outlined"
                        sx={{
                            backgroundColor: '#D9D9D9',
                        }}>
                        <Grid
                            container
                            py={2}
                            px={2}
                            alignItems="flex-start"
                            justifyContent="space-between">
                            <Grid item xs={12} md={12}>
                                <Typography color="primary" variant="h6">
                                    Filtros
                                </Typography>
                            </Grid>
                            <Grid item xs={2} md={1}>
                                <TextField
                                    label="ID"
                                    size="small"
                                    variant="standard"
                                    onChange={handleChangeSearchId}></TextField>
                            </Grid>
                            <Grid item xs={2} md={2}>
                                <TextField
                                    label="Nombres"
                                    size="small"
                                    variant="standard"
                                    onChange={
                                        handleChangeSearchNombres
                                    }></TextField>
                            </Grid>
                            <Grid item xs={2} md={2}>
                                <TextField
                                    label="Apellidos"
                                    size="small"
                                    variant="standard"
                                    onChange={
                                        handleChangeSearchApellidos
                                    }></TextField>
                            </Grid>
                            <Grid item xs={2} md={1}>
                                <TextField
                                    select
                                    label="Tipo Doc."
                                    size="small"
                                    value={tipoDocumentoSelected}
                                    variant="standard"
                                    onChange={handleSelectTipoDocumento}>
                                    <MenuItem value="tipo">Todos</MenuItem>
                                    {tipoDocumentos &&
                                        tipoDocumentos.map((tipoDocumento) => {
                                            return (
                                                <MenuItem
                                                    key={tipoDocumento.id}
                                                    value={tipoDocumento.id}>
                                                    {tipoDocumento.tipo}
                                                </MenuItem>
                                            );
                                        })}
                                </TextField>
                            </Grid>
                            <Grid item xs={2} md={1}>
                                <TextField
                                    label="N° doc."
                                    size="small"
                                    variant="standard"
                                    onChange={
                                        handleChangeSearchNumeroDoc
                                    }></TextField>
                            </Grid>
                            <Grid item xs={2} md={1}>
                                <TextField
                                    select
                                    label="Género"
                                    size="small"
                                    value={generoSelected}
                                    variant="standard"
                                    onChange={handleSelectGenero}>
                                    <MenuItem value="genero">Todos</MenuItem>
                                    {generos.map((item) => {
                                        return (
                                            <MenuItem
                                                key={item.id}
                                                value={item.id}>
                                                {item.genero}
                                            </MenuItem>
                                        );
                                    })}
                                </TextField>
                            </Grid>
                            <Grid item xs={2} md={1}>
                                <TextField
                                    label="Celular"
                                    size="small"
                                    variant="standard"
                                    onChange={
                                        handleChangeSearchCelular
                                    }></TextField>
                            </Grid>
                            <Grid item xs={2} md={1}>
                                <TextField
                                    label="Correo"
                                    size="small"
                                    variant="standard"
                                    onChange={
                                        handleChangeSearchCorreo
                                    }></TextField>
                            </Grid>
                            <Grid item xs={2} md={1.5}>
                                <TextField
                                    select
                                    label="Trat. Datos"
                                    size="small"
                                    value={tratamientoDatosSelected}
                                    variant="standard"
                                    onChange={handleSelectTratamientoDatos}>
                                    <MenuItem value="tratamiento">
                                        Todos
                                    </MenuItem>
                                    <MenuItem value="1">Si</MenuItem>
                                    <MenuItem value="0">No</MenuItem>
                                </TextField>
                            </Grid>
                        </Grid>
                    </Card>
                </Grid>
            </Grid>
        </Box>
    );
};

export default ListHeader;
