import { Box, Button, Chip, Grid, Typography } from '@mui/material';
import { DataGrid, GridToolbar, esES } from '@mui/x-data-grid';
import { useEffect, useState } from 'react';
import { sendRequest } from '../../../../services/request';
import dayjs from 'dayjs';
import { DataGridStyle } from '../../../../styles/DataGridSytle';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '80%',
    height: '80%',
    bgcolor: 'background.paper',
    overflowY: 'scroll',
    boxShadow: 24,
    px: 4,
};

const styleModalHeader = {
    top: 0,
    textAlign: 'center',
    position: 'sticky',
    zIndex: 15,
    pt: 3,
    pb: 2,
    borderBottom: 'solid #0B3E83 1px',
    backgroundColor: '#ffffff',
};

const styleModalFooter = {
    bottom: 0,
    bgcolor: '#ffffff',
    pt: 4,
};

const Historial = ({ clientRow, handleClose, handleAtras, pais }) => {
    const [rows, setRows] = useState([]);
    const [, /* page */ setPage] = useState(0);
    const [limit, setLimit] = useState(20);
    const [rowCount /* setRowCount */] = useState(null);
    const [estratificacion, setEstratificacion] = useState('');

    const URL = process.env.REACT_APP_API_URL;

    useEffect(() => {
        sendRequest(
            'get',
            `${URL}/${pais}/tarjeton/historial?id=${clientRow.id}`,
        ).then((res) => {
            setRows(res.data);
            if (res.data.length >= 3) {
                setEstratificacion('A');
            } else if (res.data.length >= 2) {
                setEstratificacion('B');
            } else {
                setEstratificacion('C');
            }
        });
    }, [URL, pais, clientRow]);

    const columns = [
        {
            field: 'item',
            headerName: 'Item',
            headerClassName: 'header',
            sortable: false,
            width: 100,
            headerAlign: 'center',
            align: 'center',
        },
        {
            field: 'fecha_entrega',
            headerName: 'Fecha',
            headerClassName: 'header',
            sortable: false,
            width: 100,
            headerAlign: 'center',
            align: 'center',
            valueGetter: ({ row }) => {
                return dayjs(row.envio?.fecha_envio || row.created_at).format(
                    'DD/MM/YYYY',
                );
            },
        },
        {
            field: 'campana',
            headerName: 'Campaña',
            headerClassName: 'header',
            sortable: false,
            width: 250,
            headerAlign: 'center',
            valueGetter: ({ row }) => {
                return row.campana?.nombre;
            },
        },
        {
            field: 'promotor',
            headerName: 'Promotor',
            headerClassName: 'header',
            sortable: false,
            width: 235.4,
            headerAlign: 'center',
            valueGetter: ({ row }) => {
                if (row.promotor) {
                    return row.promotor.nombres + ' ' + row.promotor.apellidos;
                }
                return 'No hay informacion';
            },
        },
    ];

    const handlePaginationChange = (event) => {
        setPage(event.page);
        setLimit(event.pageSize);
    };

    return (
        <Box sx={style}>
            <Box sx={styleModalHeader}>
                <Typography id="modal-modal-title" variant="h5" color="primary">
                    Historial Cliente
                </Typography>
            </Box>
            <Grid
                container
                spacing={2}
                alignItems="center"
                justifyContent="center"
                sx={{ mt: 2 }}
            >
                {/* Primera Fila */}
                <Grid item lg={3}></Grid>
                <Grid item lg={3} sx={{ textAlign: 'right' }}>
                    <Typography color="primary">Nombre del cliente:</Typography>
                </Grid>
                <Grid item lg={3}>
                    <Typography sx={{ pl: 3 }}>
                        {clientRow.nombres ? clientRow.nombres : ''}{' '}
                        {clientRow.apellidos ? clientRow?.apellidos : ''}
                    </Typography>
                </Grid>
                <Grid item lg={3}></Grid>
                {/* Segunda Fila */}
                <Grid item lg={3}></Grid>
                <Grid item lg={3} sx={{ textAlign: 'right' }}>
                    <Typography color="primary">Documento:</Typography>
                </Grid>
                <Grid item lg={3}>
                    {(clientRow.tipo_documento?.tipo ||
                        clientRow.numero_documento) && (
                        <Typography sx={{ pl: 3 }}>
                            {clientRow.tipo_documento?.tipo
                                ? clientRow.tipo_documento.tipo
                                : ''}{' '}
                            {clientRow.numero_documento
                                ? clientRow.numero_documento
                                : ''}
                        </Typography>
                    )}
                    {!clientRow.tipo_documento?.tipo &&
                        !clientRow.numero_documento && (
                            <Typography sx={{ pl: 3 }}>
                                No hay información
                            </Typography>
                        )}
                </Grid>
                <Grid item lg={3}></Grid>
                {/* Segunda Fila */}
                <Grid item lg={3}></Grid>
                <Grid item lg={3} sx={{ textAlign: 'right' }}>
                    <Typography color="primary">Estratificación:</Typography>
                </Grid>
                <Grid item lg={3}>
                    <Chip
                        label={estratificacion || 'C'}
                        color={
                            estratificacion === 'A'
                                ? 'success'
                                : estratificacion === 'B'
                                ? 'warning'
                                : 'error'
                        }
                        sx={{ ml: 3 }}
                    />
                </Grid>
                <Grid item lg={3}></Grid>
            </Grid>
            <Grid container alignItems="center" justifyContent="center">
                <Grid item lg={7}>
                    <DataGrid
                        localeText={
                            esES.components.MuiDataGrid.defaultProps.localeText
                        }
                        disableColumnSelector
                        disableDensitySelector
                        disableColumnFilter
                        disableColumnMenu
                        disableRowSelectionOnClick
                        initialState={{
                            pagination: {
                                paginationModel: { pageSize: limit },
                            },
                        }}
                        rows={
                            rows
                                ? rows.map((row, i) => ({
                                      item: i + 1,
                                      ...row,
                                  }))
                                : []
                        }
                        columns={columns}
                        slots={{ toolbar: GridToolbar }}
                        slotProps={{
                            toolbar: {
                                showQuickFilter: false,
                                // Estos dos quitan el export
                                printOptions: { disableToolbarButton: true },
                                csvOptions: { disableToolbarButton: true },
                            },
                        }}
                        paginationMode="server"
                        rowCount={rowCount ? rowCount : 0}
                        onPaginationModelChange={handlePaginationChange}
                        sx={DataGridStyle}
                    />
                </Grid>
            </Grid>
            <Grid container spacing={5} sx={styleModalFooter}>
                <Grid item xs={12} md={6}>
                    <Button fullWidth variant="outlined" onClick={handleAtras}>
                        Atrás
                    </Button>
                </Grid>
                <Grid item xs={12} md={6}>
                    <Button fullWidth variant="contained" onClick={handleClose}>
                        Salir
                    </Button>
                </Grid>
            </Grid>
        </Box>
    );
};

export default Historial;
