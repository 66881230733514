import { CheckBox, CheckBoxOutlineBlank } from '@mui/icons-material';
import {
    Autocomplete,
    Box,
    Card,
    Checkbox,
    Grid,
    TextField,
    Typography,
} from '@mui/material';
import { useRoleValidation } from '../../../../hooks/useRoles';

const style = {
    p: 0,
    width: '98%',
    textAlign: 'left',
    marginLeft: '20px',
};

const ListHeader = ({
    filtros,
    setFiltros,
    lideres,
    departamentos,
    ciudades,
    campanas,
}) => {
    const { isDirectorOperativo } = useRoleValidation();
    const handleSearchEquipo = (e) => {
        setTimeout(() => {
            setFiltros({ ...filtros, equipo: e.target.value });
        }, 2000);
    };

    const handleSearchLideres = (e, newValue) => {
        const lideresSelected = [];
        for (const value of newValue) {
            lideresSelected.push(value.id);
        }
        setFiltros({
            ...filtros,
            lideres: lideresSelected,
        });
    };

    const handleSearchCampanas = (e, newValue) => {
        const campanasSelected = [];
        for (const value of newValue) {
            campanasSelected.push(value.id);
        }
        setFiltros({
            ...filtros,
            campanas: campanasSelected,
        });
    };

    const handleDepartamentoSelected = (e, newValue) => {
        const departamentosSelected = [];
        for (const value of newValue) {
            departamentosSelected.push(value.id);
        }
        setFiltros({
            ...filtros,
            departamentos: departamentosSelected,
        });
    };

    const handleCiudadesSelected = (e, newValue) => {
        const ciudadesSelected = [];
        for (const value of newValue) {
            ciudadesSelected.push(value.id);
        }
        setFiltros({ ...filtros, ciudades: ciudadesSelected });
    };

    return (
        <Box sx={style} component="form">
            <Grid container pt={1}>
                <Grid item xs={12} md={9}>
                    <Card
                        variant="outlined"
                        sx={{
                            backgroundColor: '#D9D9D9',
                        }}
                    >
                        <Grid
                            container
                            columnSpacing={5}
                            py={2}
                            px={2}
                            alignItems="flex-start"
                            justifyContent="flex-start"
                        >
                            <Grid item xs={12} md={12}>
                                <Typography color="primary" variant="h6">
                                    Filtros
                                </Typography>
                            </Grid>
                            <Grid item xs={2} md="auto">
                                <TextField
                                    label="Nombre del Equipo"
                                    size="small"
                                    variant="standard"
                                    onChange={handleSearchEquipo}
                                ></TextField>
                            </Grid>
                            {!isDirectorOperativo && (
                                <Grid item xs={2} md={2.5}>
                                    <Autocomplete
                                        multiple
                                        disableCloseOnSelect
                                        disablePortal
                                        limitTags={1}
                                        options={lideres}
                                        getOptionLabel={(option) =>
                                            `${option.nombres} ${option.apellidos}`
                                        }
                                        onChange={handleSearchLideres}
                                        size="small"
                                        renderOption={(
                                            props,
                                            option,
                                            { selected },
                                        ) => (
                                            <li {...props} key={option.id}>
                                                <Checkbox
                                                    sx={{
                                                        ml: -1,
                                                    }}
                                                    icon={
                                                        <CheckBoxOutlineBlank fontSize="small" />
                                                    }
                                                    checkedIcon={
                                                        <CheckBox fontSize="small" />
                                                    }
                                                    checked={selected}
                                                />
                                                {option.nombres}{' '}
                                                {option.apellidos}
                                            </li>
                                        )}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                variant="standard"
                                                label="Lideres"
                                                size="small"
                                            />
                                        )}
                                        ListboxProps={{
                                            sx: {
                                                maxHeight: 200,
                                                overflow: 'auto',
                                            },
                                        }}
                                    ></Autocomplete>
                                </Grid>
                            )}
                            <Grid item xs={2} md={2.4}>
                                <Autocomplete
                                    multiple
                                    disableCloseOnSelect
                                    disablePortal
                                    limitTags={1}
                                    options={campanas}
                                    getOptionLabel={(option) => option.nombre}
                                    onChange={handleSearchCampanas}
                                    size="small"
                                    renderOption={(
                                        props,
                                        option,
                                        { selected },
                                    ) => (
                                        <li {...props} key={option.id}>
                                            <Checkbox
                                                sx={{
                                                    ml: -1,
                                                }}
                                                icon={
                                                    <CheckBoxOutlineBlank fontSize="small" />
                                                }
                                                checkedIcon={
                                                    <CheckBox fontSize="small" />
                                                }
                                                checked={selected}
                                            />
                                            {option.nombre}
                                        </li>
                                    )}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            variant="standard"
                                            label="Campañas"
                                            size="small"
                                        />
                                    )}
                                    ListboxProps={{
                                        sx: {
                                            maxHeight: 200,
                                            overflow: 'auto',
                                        },
                                    }}
                                ></Autocomplete>
                            </Grid>
                            <Grid item xs={2} md={2.4}>
                                <Autocomplete
                                    multiple
                                    disableCloseOnSelect
                                    disablePortal
                                    limitTags={1}
                                    options={departamentos}
                                    getOptionLabel={(option) => option.nombre}
                                    onChange={handleDepartamentoSelected}
                                    size="small"
                                    renderOption={(
                                        props,
                                        option,
                                        { selected },
                                    ) => (
                                        <li {...props} key={option.id}>
                                            <Checkbox
                                                sx={{
                                                    ml: -1,
                                                }}
                                                icon={
                                                    <CheckBoxOutlineBlank fontSize="small" />
                                                }
                                                checkedIcon={
                                                    <CheckBox fontSize="small" />
                                                }
                                                checked={selected}
                                            />
                                            {option.nombre}
                                        </li>
                                    )}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            variant="standard"
                                            label="Departamento"
                                            size="small"
                                        />
                                    )}
                                    ListboxProps={{
                                        sx: {
                                            maxHeight: 200,
                                            overflow: 'auto',
                                        },
                                    }}
                                ></Autocomplete>
                            </Grid>
                            {filtros.departamentos?.length !== 0 && (
                                <Grid item xs={2} md={2.4}>
                                    <Autocomplete
                                        multiple
                                        disableCloseOnSelect
                                        disablePortal
                                        limitTags={1}
                                        options={ciudades}
                                        getOptionLabel={(option) =>
                                            option.nombre
                                        }
                                        onChange={handleCiudadesSelected}
                                        size="small"
                                        renderOption={(
                                            props,
                                            option,
                                            { selected },
                                        ) => (
                                            <li {...props} key={option.id}>
                                                <Checkbox
                                                    sx={{
                                                        ml: -1,
                                                    }}
                                                    icon={
                                                        <CheckBoxOutlineBlank fontSize="small" />
                                                    }
                                                    checkedIcon={
                                                        <CheckBox fontSize="small" />
                                                    }
                                                    checked={selected}
                                                />
                                                {option.nombre}
                                            </li>
                                        )}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                variant="standard"
                                                label="Ciudad"
                                            />
                                        )}
                                        ListboxProps={{
                                            sx: {
                                                maxHeight: 200,
                                                overflow: 'auto',
                                            },
                                        }}
                                    ></Autocomplete>
                                </Grid>
                            )}
                        </Grid>
                    </Card>
                </Grid>
            </Grid>
        </Box>
    );
};

export default ListHeader;
