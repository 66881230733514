import { combineReducers } from 'redux';
import auth from './auth';
import filters from './filters';

const reducers = combineReducers({
    auth,
    filters,
});

export default reducers;
