import {
    Box,
    Button,
    Checkbox,
    FormControlLabel,
    FormGroup,
    Grid,
    MenuItem,
    TextField,
    Typography,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { sendRequest } from '../../../../../services/request';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '80%',
    height: '80%',
    bgcolor: 'background.paper',
    overflowY: 'scroll',
    boxShadow: 24,
    px: 4,
};

const styleModalHeader = {
    top: 0,
    textAlign: 'center',
    position: 'sticky',
    zIndex: 15,
    pt: 3,
    pb: 2,
    borderBottom: 'solid #0B3E83 1px',
    backgroundColor: '#ffffff',
};

const styleModalFooter = {
    bottom: 0,
    bgcolor: '#ffffff',
    pt: 4,
};

const OrdenImpresion = ({ pais, campanas, handleCrear, handleClose }) => {
    const [campanaSelected, setCampanaSelected] = useState(null);
    const [cantidad, setCantidad] = useState(null);
    const [observaciones, setObservaciones] = useState('');
    const [isShrunk, setIsShrunk] = useState(false);
    const [numeracion, setNumeracion] = useState(null);
    const [numeracionFin, setNumeracionFin] = useState(null);

    const URL = process.env.REACT_APP_API_URL;
    useEffect(() => {
        if (campanaSelected) {
            sendRequest(
                'get',
                `${URL}/${pais}/ordenes-impresion/campana/${campanaSelected.id}`,
            )
                .then((res) => {
                    if (res.data) {
                        setNumeracionFin(res.data.numeracion?.split('-')[1]);
                    } else {
                        setNumeracionFin(0);
                    }
                })
                .catch((err) => {
                    console.log(err);
                    setNumeracion(0);
                });
        }
    }, [URL, pais, campanaSelected]);

    const handleChangeCantidadSolicitada = (event) => {
        setNumeracion(
            event.target.value
                ? `${parseInt(numeracionFin) + 1} - ${
                      parseInt(numeracionFin) + parseInt(event.target.value)
                  }`
                : numeracionFin,
        );
        setCantidad(event.target.value);
    };

    return (
        <Box sx={style}>
            <Box sx={styleModalHeader}>
                <Typography id="modal-modal-title" variant="h5" color="primary">
                    Agregar nueva orden de impresión
                </Typography>
            </Box>
            <Grid container spacing={5} sx={{ my: '5px' }}>
                <Grid item xs={12} md={6}>
                    <TextField
                        select
                        fullWidth
                        id="campana"
                        label="Nombre Campaña"
                        variant="outlined"
                        width="100%"
                        onChange={(event) => {
                            const campana = campanas.find(
                                (campana) => campana.id === event.target.value,
                            );
                            setCampanaSelected(campana);
                            setIsShrunk(true);
                        }}
                    >
                        {campanas.length > 0 &&
                            campanas.map((campana) => (
                                <MenuItem key={campana.id} value={campana.id}>
                                    {campana.nombre}
                                </MenuItem>
                            ))}
                    </TextField>
                </Grid>
                <Grid item xs={12} md={6}>
                    <TextField
                        fullWidth
                        id="cantidad"
                        type="number"
                        label="Cantidad Solicitada"
                        variant="outlined"
                        width="100%"
                        value={cantidad}
                        /* error={errorMessage}
                        helperText={errorMessage} */
                        onChange={handleChangeCantidadSolicitada}
                    ></TextField>
                </Grid>
                <Grid item xs={12} md={6}>
                    <TextField
                        InputProps={{ readOnly: true }}
                        InputLabelProps={{
                            shrink: isShrunk,
                        }}
                        fullWidth
                        id="precio"
                        label="Numeración"
                        variant="outlined"
                        width="100%"
                        value={numeracion}
                    />
                </Grid>
                <Grid item xs={6} md={6}>
                    <FormGroup>
                        <FormControlLabel
                            control={<Checkbox />}
                            label={'Inventario no impreso'}
                        />
                    </FormGroup>
                </Grid>
                <Grid item xs={12} md={12}>
                    <TextField
                        fullWidth
                        id="observacion"
                        label="Observaciones"
                        variant="outlined"
                        width="100%"
                        onChange={(event) =>
                            setObservaciones(event.target.value)
                        }
                    />
                </Grid>
            </Grid>
            <Grid container spacing={5} sx={styleModalFooter}>
                <Grid item xs={12} md={6}>
                    <Button fullWidth variant="outlined" onClick={handleClose}>
                        Cancelar
                    </Button>
                </Grid>
                <Grid item xs={12} md={6}>
                    <Button
                        fullWidth
                        variant="contained"
                        onClick={() =>
                            handleCrear({
                                cantidad,
                                campana: campanaSelected?.id,
                                observaciones,
                                numeracion,
                            })
                        }
                    >
                        Agregar
                    </Button>
                </Grid>
            </Grid>
        </Box>
    );
};

export default OrdenImpresion;
