import { Grid, IconButton, Typography } from '@mui/material';
import { DataGrid, esES, GridToolbar } from '@mui/x-data-grid';
import RolesGate from '../../../../components/utils-components/permissions/rolesGate';
import { ROLES } from '../../../../components/utils-components/permissions/permissions-map';
import { Edit, PersonRemove } from '@mui/icons-material';
import { useEffect, useState } from 'react';
import { sendRequest } from '../../../../services/request';
import { DataGridStyle } from '../../../../styles/DataGridSytle';

const ManejoUsuarios = ({ reload }) => {
    const URL = process.env.REACT_APP_API_URL;

    const [data, setData] = useState([]);
    const [rowCount, setRowCount] = useState(0);
    const [limit, setLimit] = useState(10);
    const [page, setPage] = useState(0);

    // Condicionales
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        const fetchData = async () => {
            try {
                setLoading(true);
                const res = await sendRequest(
                    'get',
                    `${URL}/usuario/all?page=${page}&limit=${limit}`,
                );
                const dataWithIds = res.data.items.map((item, index) => ({
                    item: index + 1,
                    ...item,
                }));
                setData(dataWithIds);
                setRowCount(res.data.meta.totalItems);
                setLoading(false);
            } catch (error) {
                console.log(error);
                setLoading(false);
            }
        };
        fetchData();
    }, [URL, limit, page, reload]);

    const columns = [
        {
            field: 'item',
            headerName: 'Item',
            headerClassName: 'header',
            headerAlign: 'center',
            sortable: false,
            flex: 1,
            renderCell: ({ row }) => {
                return row.item;
            },
        },
        {
            field: 'codigo',
            headerName: 'Código',
            headerClassName: 'header',
            headerAlign: 'center',
            sortable: false,
            flex: 1,
            renderCell: ({ row }) => {
                return row.codigo;
            },
        },
        {
            field: 'nombres',
            headerName: 'Nombres',
            headerClassName: 'header',
            headerAlign: 'center',
            sortable: false,
            flex: 1,
            renderCell: ({ row }) => {
                return row.nombres;
            },
        },
        {
            field: 'apellidos',
            headerName: 'Apellidos',
            headerClassName: 'header',
            headerAlign: 'center',
            sortable: false,
            flex: 1,
            renderCell: ({ row }) => {
                return row.apellidos;
            },
        },
        {
            field: 'acciones',
            type: 'actions',
            cellClassName: 'actions',
            headerName: 'Acciones',
            headerClassName: 'header',
            flex: 1.8,
            getActions: ({ row }) => {
                return [
                    <RolesGate
                        roles={[
                            ROLES.GERENCIA_EFFECTIVE,
                            ROLES.GERENCIA_WINNER,
                            ROLES.DIRECTOR_OPERATIVO,
                        ]}
                    >
                        <IconButton
                            color="primary"
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                borderRadius: 0,
                            }}
                            onClick={() => handleEditar(row)}
                        >
                            <Edit />
                            <Typography>Editar</Typography>
                        </IconButton>
                    </RolesGate>,
                    <RolesGate
                        roles={[
                            ROLES.GERENCIA_EFFECTIVE,
                            ROLES.GERENCIA_WINNER,
                            ROLES.DIRECTOR_OPERATIVO,
                        ]}
                    >
                        <IconButton
                            color="danger"
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                borderRadius: 0,
                            }}
                            onClick={() => handleEliminar(row)}
                        >
                            <PersonRemove />
                            <Typography>Eliminar</Typography>
                        </IconButton>
                    </RolesGate>,
                ];
            },
        },
    ];

    const handleEditar = () => {};

    const handleEliminar = () => {};

    const handlePaginationChange = (event) => {
        setPage(event.page);
        setLimit(event.pageSize);
    };

    return (
        <Grid container>
            <Grid item xs={24} md={24}>
                <DataGrid
                    localeText={
                        esES.components.MuiDataGrid.defaultProps.localeText
                    }
                    disableColumnSelector
                    disableDensitySelector
                    disableColumnFilter
                    disableColumnMenu
                    loading={loading}
                    initialState={{
                        pagination: {
                            paginationModel: { pageSize: limit },
                        },
                    }}
                    rows={data ? data : []}
                    columns={columns}
                    paginationMode="server"
                    rowCount={rowCount ? rowCount : 0}
                    onPaginationModelChange={handlePaginationChange}
                    slots={{
                        toolbar: GridToolbar,
                    }}
                    slotProps={{
                        toolbar: {
                            showQuickFilter: false,
                            // Estos dos quitan el export
                            printOptions: { disableToolbarButton: true },
                            csvOptions: { disableToolbarButton: true },
                        },
                    }}
                    sx={DataGridStyle}
                ></DataGrid>
            </Grid>
        </Grid>
    );
};

export default ManejoUsuarios;
