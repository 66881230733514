import { useEffect, useState } from 'react';
import HeaderNav from '../../components/layout-components/headerNav';
import AppViews from '../app-views';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

const AppLayout = () => {
    const usuario = useSelector((state) => state.auth?.usuario);
    const [pais, setPais] = useState(usuario?.pais?.nombre);
    const navigate = useNavigate();

    useEffect(() => {
        if (!pais) {
            navigate('/auth/login');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <HeaderNav pais={pais} setPais={setPais}></HeaderNav>
            <AppViews pais={pais}></AppViews>
        </>
    );
};

export default AppLayout;
