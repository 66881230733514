import { Box, Tab, Tabs } from '@mui/material';
import { useState } from 'react';
import ResumenInventario from '../resumenInventario';
import EnviosInternos from '../enviosInternos';
import OrdenesImpresion from '../ordenesInventario';
import RolesGate from '../../../../components/utils-components/permissions/rolesGate';
import { ROLES } from '../../../../components/utils-components/permissions/permissions-map';
import { useRoleValidation } from '../../../../hooks/useRoles';
import EnvioCampanaPreview from '../enviosClientesFinales/campana-preview';
import { useDispatch, useSelector } from 'react-redux';
import { filterEnvioClientesFinales } from '../../../../redux/actions/filters';

const TabsInventario = ({ pais }) => {
    const dispatch = useDispatch();
    const rolesValidation = useRoleValidation();
    const filters = useSelector((state) => state.filters);
    const tab = useSelector(
        (state) => state.filters?.filtersEnvioClientesFinales?.tab,
    );
    const [selectedTab, setSelectedTab] = useState(tab || 0);

    let tabList = [
        {
            label: 'Resumen Inventario',
            value: 0,
        },
        {
            label: 'Ordenes Impresión',
            value: 1,
        },
        {
            label: 'Envíos Internos',
            value: 2,
        },
        {
            label: 'Envío y Entrega a Clientes Finales',
            value: 3,
        },
    ];
    const removeTabs = [];

    //Esta parte elimina los tabs segun el rol
    if (rolesValidation.isImpresion) {
        removeTabs.push(0, 2, 3);
    }
    if (
        rolesValidation.isGerenciaWinner ||
        rolesValidation.isDirectorOperativo
    ) {
        removeTabs.push(1);
    }
    tabList = tabList.filter((item) => !removeTabs.includes(item.value));

    const hanldeChangeTab = (event, value) => {
        setSelectedTab(value);
        dispatch(
            filterEnvioClientesFinales({
                filtersEnvioClientesFinales: {
                    ...filters.filterEnvioClientesFinales,
                    tab: value,
                },
            }),
        );
    };

    return (
        <>
            <Box sx={{ height: '100%', width: '100%' }}>
                <Tabs value={selectedTab} onChange={hanldeChangeTab}>
                    {tabList.map((item) => (
                        <Tab
                            sx={{ textTransform: 'none' }}
                            label={item.label}
                            value={item.value}
                        ></Tab>
                    ))}
                </Tabs>
                {selectedTab === 0 && (
                    <RolesGate
                        roles={[
                            ROLES.GERENCIA_EFFECTIVE,
                            ROLES.DIRECTOR_ADMINISTRATIVO,
                            ROLES.LOGISTICA,
                            ROLES.GERENCIA_WINNER,
                            ROLES.DIRECTOR_OPERATIVO,
                        ]}
                    >
                        <ResumenInventario pais={pais}></ResumenInventario>
                    </RolesGate>
                )}
                {selectedTab === 1 && (
                    <RolesGate
                        roles={[
                            ROLES.GERENCIA_EFFECTIVE,
                            ROLES.DIRECTOR_ADMINISTRATIVO,
                            ROLES.LOGISTICA,
                            ROLES.IMPRESION,
                        ]}
                    >
                        <OrdenesImpresion pais={pais}></OrdenesImpresion>
                    </RolesGate>
                )}
                {selectedTab === 2 && (
                    <RolesGate
                        roles={[
                            ROLES.GERENCIA_EFFECTIVE,
                            ROLES.DIRECTOR_ADMINISTRATIVO,
                            ROLES.LOGISTICA,
                            ROLES.GERENCIA_WINNER,
                            ROLES.DIRECTOR_OPERATIVO,
                        ]}
                    >
                        <EnviosInternos pais={pais}></EnviosInternos>
                    </RolesGate>
                )}
                {selectedTab === 3 && (
                    <RolesGate
                        roles={[
                            ROLES.GERENCIA_EFFECTIVE,
                            ROLES.DIRECTOR_ADMINISTRATIVO,
                            ROLES.LOGISTICA,
                            ROLES.GERENCIA_WINNER,
                            ROLES.DIRECTOR_OPERATIVO,
                        ]}
                    >
                        <EnvioCampanaPreview pais={pais}></EnvioCampanaPreview>
                    </RolesGate>
                )}
            </Box>
        </>
    );
};

export default TabsInventario;
