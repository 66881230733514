import { useEffect, useState } from 'react';
import { sendRequest } from '../../../../services/request';
import { useLocation } from 'react-router-dom';
import { DataGrid, GridToolbar, esES } from '@mui/x-data-grid';
import { Box, Typography, Grid, Button, TextField } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
import dayjs from 'dayjs';
import weekOfYear from 'dayjs/plugin/weekOfYear';
import { DataGridStyle } from '../../../../styles/DataGridSytle';

dayjs.extend(weekOfYear);

const EquipoAsignarCliente = ({ pais }) => {
    const [rows, setRows] = useState();
    const [limit, setLimit] = useState(50);
    const [page, setPage] = useState(0);
    const [rowCount, setRowCount] = useState(null);

    const URL = `${process.env.REACT_APP_API_URL}`;
    const location = useLocation();
    const equipo = location.state?.equipo;
    const promotor = location.state?.promotor;

    const cliente_donante_string =
        location.state?.campana?.division?.nombre === 'Publicidad'
            ? 'Clientes'
            : 'Donantes';

    // Condicionales
    const [loading, setLoading] = useState(false);

    // Campos selected
    const [rowsSelected, setRowsSelected] = useState([]);

    //Re-render
    const [guardado, setGuardado] = useState(false);

    useEffect(() => {
        setLoading(true);
        sendRequest(
            'get',
            `${URL}/${pais}/cliente/equipo/${equipo.id}?limit=${limit}&page=${page}`,
        ).then((res) => {
            setLoading(false);
            setRows(res.data.items);
            setRowCount(res.data.meta.totalItems);
        });
    }, [URL, pais, limit, page, guardado, equipo.id]);

    const columns = [
        {
            field: 'campana',
            headerName: 'Campaña',
            flex: 0.6,
            headerClassName: 'header',
            headerAlign: 'center',
            sortable: false,
            renderCell: ({ row }) => {
                const nombresCampana = new Set(
                    row.tarjetones?.map(
                        (tarjeton) =>
                            `${tarjeton.campana.nombre} - ${dayjs(
                                tarjeton.campana.vigencia,
                            ).format('YYYY')}`,
                    ),
                );
                return [...nombresCampana].toString();
            },
        },
        {
            field: 'nombres',
            headerName: 'Nombres',
            flex: 0.5,
            headerClassName: 'header',
            headerAlign: 'center',
            sortable: false,
        },
        {
            field: 'apellidos',
            headerName: 'Apellidos',
            headerClassName: 'header',
            headerAlign: 'center',
            sortable: false,
        },
        {
            field: 'tipo_documento',
            headerName: 'Tipo Doc.',
            headerClassName: 'header',
            headerAlign: 'center',
            sortable: false,
            align: 'center',
            valueGetter: ({ row }) => {
                return row.tipo_documento?.tipo;
            },
        },
        {
            field: 'numero_documento',
            headerName: 'Núm. Doc.',
            headerClassName: 'header',
            headerAlign: 'center',
            sortable: false,
        },
        {
            field: 'genero',
            headerName: 'Género',
            headerClassName: 'header',
            headerAlign: 'center',
            sortable: false,
            valueGetter: ({ row }) => {
                return row.genero?.genero;
            },
        },
        {
            field: 'celular',
            headerName: 'Celular',
            headerClassName: 'header',
            headerAlign: 'center',
            sortable: false,
        },
        {
            field: 'correo',
            headerName: 'Correo',
            flex: 1,
            headerClassName: 'header',
            headerAlign: 'center',
            sortable: false,
        },
        {
            field: 'tratamiento_datos',
            headerName: 'Trat. Datos',
            headerClassName: 'header',
            headerAlign: 'center',
            align: 'center',
            sortable: false,
            renderCell: (params) => {
                if (params.value) {
                    return <CheckIcon></CheckIcon>;
                } else {
                    return <ClearIcon></ClearIcon>;
                }
            },
        },
    ];

    const handlePaginationChange = (event) => {
        setPage(event.page);
        setLimit(event.pageSize);
    };

    const handleAsignar = async () => {
        if (rowsSelected.length > 0) {
            await sendRequest(
                'patch',
                `${URL}/${pais}/clientes-asignados/promotor`,
                {
                    clientes: rowsSelected,
                    campana: equipo.campana.id,
                    equipo: equipo.id,
                    lider: equipo.lider.id,
                    promotor: promotor.id,
                    cliente_promotor_current_week: dayjs(new Date()).week(),
                },
            );
            setGuardado(!guardado);
        }
    };

    const handleRowSelectionModelChange = (newSelectionModel) => {
        setRowsSelected(newSelectionModel);
    };

    // Selecciona datos en el datagrid desde un input.
    const handleOnChangeDatosSeleccionar = async (event) => {
        const value = event.target.value;
        const numberOfRowsToSelect = parseInt(value, 10);

        if (!isNaN(numberOfRowsToSelect)) {
            const rowsToSelect = [];
            let currentPage = page;
            let remainingRows = numberOfRowsToSelect;

            while (remainingRows > 0) {
                setLoading(true);
                const res = await sendRequest(
                    'get',
                    `${URL}/${pais}/cliente/equipo/${equipo.id}?limit=${limit}&page=${currentPage}`,
                );
                setLoading(false);
                const rowsFromPage = res.data.items.slice(0, remainingRows);
                rowsToSelect.push(...rowsFromPage.map((row) => row.id));

                remainingRows -= rowsFromPage.length;
                currentPage += 1;

                if (currentPage * limit >= rowCount) break;
            }

            setRowsSelected(rowsToSelect);
        } else {
            setRowsSelected([]);
        }
    };

    return (
        <>
            <Box sx={{ height: '100%', width: '100%' }}>
                <Grid container spacing={5} py={3} pr={3}>
                    <Grid item xs={12} md={8}>
                        <Typography
                            variant="h4"
                            color="primary"
                            sx={{
                                textAlign: 'start',
                                marginLeft: '10px',
                            }}
                        >
                            Asignar {cliente_donante_string} / {equipo?.nombre}{' '}
                            / {promotor?.nombres + ' ' + promotor?.apellidos}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Button
                            fullWidth
                            variant="contained"
                            onClick={handleAsignar}
                        >
                            Asignar {cliente_donante_string} a Promotor
                        </Button>
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid item sx={12} md={12}>
                        <TextField
                            label="Datos a seleccionar"
                            onChange={handleOnChangeDatosSeleccionar}
                        ></TextField>
                    </Grid>
                    <DataGrid
                        localeText={
                            esES.components.MuiDataGrid.defaultProps.localeText
                        }
                        disableColumnSelector
                        disableDensitySelector
                        disableColumnFilter
                        disableColumnMenu
                        checkboxSelection
                        keepNonExistentRowsSelected
                        /* disableRowSelectionOnClick */
                        loading={loading}
                        initialState={{
                            pagination: {
                                paginationModel: { pageSize: limit },
                            },
                        }}
                        rows={rows ? rows : []}
                        columns={columns}
                        slots={{
                            toolbar: GridToolbar,
                        }}
                        slotProps={{
                            toolbar: {
                                showQuickFilter: false,
                                // Estos dos quitan el export
                                printOptions: { disableToolbarButton: true },
                                csvOptions: { disableToolbarButton: true },
                            },
                        }}
                        paginationMode="server"
                        rowCount={rowCount ? rowCount : 0}
                        onPaginationModelChange={handlePaginationChange}
                        rowSelectionModel={rowsSelected}
                        onRowSelectionModelChange={
                            handleRowSelectionModelChange
                        }
                        sx={[
                            DataGridStyle,
                            {
                                minHeight: '30vh',
                                '& .MuiDataGrid-virtualScroller': {
                                    minHeight: '20vh',
                                },
                            },
                        ]}
                    />
                </Grid>
            </Box>
        </>
    );
};

export default EquipoAsignarCliente;
