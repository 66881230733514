import { Box, Grid, Typography } from '@mui/material';

const DataGridTotalFooterColocacion = (props) => {
    return (
        <Box display={'flex'} padding={2}>
            <Grid container spacing={2}>
                <Grid item xs={props.totalSize} textAlign={'start'}>
                    <Typography variant="subtitle2" fontWeight="bold">
                        Total
                    </Typography>
                </Grid>
                <Grid item xs={1.28} textAlign={'start'}>
                    <Typography variant="subtitle2" fontWeight="bold">
                        {props.total_lunes}
                    </Typography>
                </Grid>
                <Grid item xs={1.28} textAlign={'start'}>
                    <Typography variant="subtitle2" fontWeight="bold">
                        {props.total_martes}
                    </Typography>
                </Grid>
                <Grid item xs={1.25} textAlign={'start'}>
                    <Typography variant="subtitle2" fontWeight="bold">
                        {props.total_miercoles}
                    </Typography>
                </Grid>
                <Grid item xs={1.25} textAlign={'start'}>
                    <Typography variant="subtitle2" fontWeight="bold">
                        {props.total_jueves}
                    </Typography>
                </Grid>
                <Grid item xs={1.28} textAlign={'start'}>
                    <Typography variant="subtitle2" fontWeight="bold">
                        {props.total_viernes}
                    </Typography>
                </Grid>
                <Grid item xs={1.28} textAlign={'start'}>
                    <Typography variant="subtitle2" fontWeight="bold">
                        {props.total_sabado}
                    </Typography>
                </Grid>
                <Grid item xs={1.28} textAlign={'start'}>
                    <Typography variant="subtitle2" fontWeight="bold">
                        {props.total_domingo}
                    </Typography>
                </Grid>
                <Grid item textAlign={'start'}>
                    <Typography variant="subtitle2" fontWeight="bold">
                        {props.total_semana}
                    </Typography>
                </Grid>
            </Grid>
        </Box>
    );
};

export default DataGridTotalFooterColocacion;
