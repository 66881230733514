import {
    Button,
    Grid /* Typography */,
    Modal,
    Typography,
} from '@mui/material';
import { useEffect, useState } from 'react';
import ModalColocacion from './modalColocacion';
import { sendRequest } from '../../../../services/request';
import { DataGrid, esES, useGridApiRef } from '@mui/x-data-grid';
import dayjs from 'dayjs';
import DataGridTotalFooterColocacion from '../../../../components/utils-components/DataGrid/DataGridTotalFooter/DataGridTotalFooterColocacion';
import updateLocale from 'dayjs/plugin/updateLocale';
import weekday from 'dayjs/plugin/weekday';
import weekOfYear from 'dayjs/plugin/weekOfYear';
import 'dayjs/locale/es';
import ModalDiario from './Diario/modalDiario';
import { PreReporteDiario } from './Diario';
import { DataGridStyle } from '../../../../styles/DataGridSytle';

dayjs.extend(updateLocale);
dayjs.extend(weekday);
dayjs.extend(weekOfYear);
dayjs.updateLocale('es', {
    weekStart: 0,
});

const PreReportes = ({ pais }) => {
    const URL = process.env.REACT_APP_API_URL;
    const [divisiones, setDivisiones] = useState([]);
    const [reporteDataSemanal, setReporteDataSemanal] = useState(null);
    const [reporteDataDiario, setReporteDataDiario] = useState(null);
    const [columns, setColumns] = useState([]);
    const [totalesFooter, setTotalesFooter] = useState({
        total_lunes: 0,
        total_martes: 0,
        total_miercoles: 0,
        total_jueves: 0,
        total_viernes: 0,
        total_sabado: 0,
        total_domingo: 0,
        total_semana: 0,
    });
    const [campanaSelected, setCampanaSelected] = useState(null);
    const [divisionSelected, setDivisionSelected] = useState(null);
    const [tipoSelected, setTipoSelected] = useState(null);

    // Week Picker
    const [fecha, setFecha] = useState(null);
    const [hoveredDay, setHoveredDay] = useState(null);

    //Modales
    const [open, setOpen] = useState(false);
    const [openPreReporteColocacion, setOpenPreReporteColocacion] =
        useState(false);
    const [openPreReporteDiario, setOpenPreReporteDiario] = useState(false);

    const [loading, setLoading] = useState(false);
    const [isButtonSelected, setIsButtonSelected] = useState({
        diario: false,
        semanal: false,
    });

    const dataGridRef = useGridApiRef();

    let weekDays = [
        dayjs(fecha).locale('es').weekday(1),
        dayjs(fecha).locale('es').weekday(2),
        dayjs(fecha).locale('es').weekday(3),
        dayjs(fecha).locale('es').weekday(4),
        dayjs(fecha).locale('es').weekday(5),
        dayjs(fecha).locale('es').weekday(6),
        dayjs(fecha).locale('es').weekday(7),
    ];

    // Los pongo con la primera en mayúscula
    const weekDaysName = weekDays.map((day) => {
        day =
            day.format('dddd DD').charAt(0).toUpperCase() +
            day.format('dddd DD').slice(1);
        return day;
    });

    const weekMonthsName = weekDays.map((day) => {
        day =
            day.format('MMM DD').charAt(0).toUpperCase() +
            day.format('MMM DD').slice(1);
        return day;
    });

    useEffect(() => {
        const fetchData = async () => {
            try {
                const res = await sendRequest('get', `${URL}/${pais}/division`);
                setDivisiones(res.data);
            } catch (error) {
                console.log(error);
            }
        };
        fetchData();
    }, [URL, pais]);

    const handleColocacionSemanal = () => {
        setOpen(true);
        setOpenPreReporteColocacion(true);
        setIsButtonSelected({
            semanal: true,
            diario: false,
        });
    };

    const handlePrereporteDiario = () => {
        setOpen(true);
        setOpenPreReporteDiario(true);
        setIsButtonSelected({
            diario: true,
            semanal: false,
        });
    };

    const handleClose = () => {
        setOpen(false);
        setOpenPreReporteDiario(false);
        setOpenPreReporteColocacion(false);
    };

    const handleContinuar = async (reporteType, dataReporte) => {
        setLoading(true);
        const { tipo, fecha, campana, division } = dataReporte;
        setDivisionSelected(division);
        setTipoSelected(tipo);
        const columns = [
            {
                field: 'first_day',
                headerName: `${weekDaysName[0]}`,
                flex: 0.5,
                headerClassName: 'header',
                headerAlign: 'center',
                sortable: false,
                renderCell: ({ row }) => {
                    let cantidad = 0;
                    for (const envio of row.envios) {
                        if (dayjs(envio.created_at).day() === 1) {
                            cantidad += envio.cantidad;
                        }
                    }
                    return cantidad;
                },
            },
            {
                field: 'second_day',
                headerName: `${weekDaysName[1]}`,
                flex: 0.5,
                headerClassName: 'header',
                headerAlign: 'center',
                sortable: false,
                renderCell: ({ row }) => {
                    let cantidad = 0;
                    for (const envio of row.envios) {
                        if (dayjs(envio.created_at).day() === 2) {
                            cantidad += envio.cantidad;
                        }
                    }
                    return cantidad;
                },
            },
            {
                field: 'third_day',
                headerName: `${weekDaysName[2]}`,
                flex: 0.5,
                headerClassName: 'header',
                headerAlign: 'center',
                sortable: false,
                renderCell: ({ row }) => {
                    let cantidad = 0;
                    for (const envio of row.envios) {
                        if (dayjs(envio.created_at).day() === 3) {
                            cantidad += envio.cantidad;
                        }
                    }
                    return cantidad;
                },
            },
            {
                field: 'fourth_day',
                headerName: `${weekDaysName[3]}`,
                flex: 0.5,
                headerClassName: 'header',
                headerAlign: 'center',
                sortable: false,
                renderCell: ({ row }) => {
                    let cantidad = 0;
                    for (const envio of row.envios) {
                        if (dayjs(envio.created_at).day() === 4) {
                            cantidad += envio.cantidad;
                        }
                    }
                    return cantidad;
                },
            },
            {
                field: 'fifth_day',
                headerName: `${weekDaysName[4]}`,
                flex: 0.5,
                headerClassName: 'header',
                headerAlign: 'center',
                sortable: false,
                renderCell: ({ row }) => {
                    let cantidad = 0;
                    for (const envio of row.envios) {
                        if (dayjs(envio.created_at).day() === 5) {
                            cantidad += envio.cantidad;
                        }
                    }
                    return cantidad;
                },
            },
            {
                field: 'sixth_day',
                headerName: `${weekDaysName[5]}`,
                flex: 0.5,
                headerClassName: 'header',
                headerAlign: 'center',
                sortable: false,
                renderCell: ({ row }) => {
                    let cantidad = 0;
                    for (const envio of row.envios) {
                        if (dayjs(envio.created_at).day() === 6) {
                            cantidad += envio.cantidad;
                        }
                    }
                    return cantidad;
                },
            },
            {
                field: 'seventh_day',
                headerName: `${weekDaysName[6]}`,
                flex: 0.5,
                headerClassName: 'header',
                headerAlign: 'center',
                sortable: false,
                renderCell: ({ row }) => {
                    let cantidad = 0;
                    for (const envio of row.envios) {
                        if (dayjs(envio.created_at).day() === 0) {
                            cantidad += envio.cantidad;
                        }
                    }
                    return cantidad;
                },
            },
            {
                field: 'total_semana',
                renderHeader: () => {
                    return <b>Total Semana</b>;
                },
                flex: 0.5,
                headerClassName: 'header',
                headerAlign: 'center',
                sortable: false,
                renderCell: ({ row }) => {
                    return <b>{row.envios.length}</b>;
                },
            },
        ];
        switch (reporteType) {
            case 'colocacion_semanal':
                let res;
                if (tipo && tipo?.nombre === 'Promotor') {
                    res = await sendRequest(
                        'get',
                        `${URL}/usuario/colocacion-semanal?campana=${
                            campana.id
                        }&semana=${dayjs(fecha).week()}`,
                    );
                    setColumns([
                        {
                            field: 'codigo',
                            headerName: 'Código',
                            flex: 'auto',
                            headerClassName: 'header',
                            headerAlign: 'center',
                            sortable: false,
                        },
                        {
                            field: 'promotor',
                            headerName: 'Promotor',
                            flex: 0.5,
                            headerClassName: 'header',
                            headerAlign: 'center',
                            sortable: false,
                            renderCell: ({ row }) =>
                                `${row.nombres} ${row.apellidos}`,
                        },
                        ...columns,
                    ]);
                }
                if (tipo && tipo?.nombre === 'Campaña') {
                    res = await sendRequest(
                        'get',
                        `${URL}/${pais}/campana/colocacion-semanal?semana=${dayjs(
                            fecha,
                        ).week()}`,
                    );
                    setColumns([
                        {
                            field: 'campana',
                            headerName: 'Nombre Campaña',
                            flex: 0.8,
                            headerClassName: 'header',
                            sortable: false,
                            renderCell: ({ row }) => row?.nombre,
                        },
                        ...columns,
                    ]);
                }
                handleClose();
                setCampanaSelected(campana);
                setLoading(false);
                setReporteDataSemanal(res?.data || []);
                calculaTotales(res?.data || []);
                setReporteDataDiario(null);
                break;
            case 'pre-reporte_diario':
                const resDiario = await sendRequest(
                    'get',
                    `${URL}/${pais}/envio/colocacion/diaria?division=${division.id}`,
                );
                setReporteDataDiario(resDiario.data);
                setReporteDataSemanal(null);
                handleClose();
                setLoading(false);
                break;
            default:
                break;
        }
    };

    const calculaTotales = (data) => {
        let sumaLunes = 0;
        let sumaMartes = 0;
        let sumaMiercoles = 0;
        let sumaJueves = 0;
        let sumaViernes = 0;
        let sumaSabado = 0;
        let sumaDomingo = 0;
        for (const row of data) {
            for (const envio of row.envios) {
                switch (dayjs(envio.created_at).day()) {
                    case 1:
                        sumaLunes += envio.cantidad;
                        break;
                    case 2:
                        sumaMartes += envio.cantidad;
                        break;
                    case 3:
                        sumaMiercoles += envio.cantidad;
                        break;
                    case 4:
                        sumaJueves += envio.cantidad;
                        break;
                    case 5:
                        sumaViernes += envio.cantidad;
                        break;
                    case 6:
                        sumaSabado += envio.cantidad;
                        break;
                    default:
                        sumaDomingo += envio.cantidad;
                        break;
                }
            }
        }
        setTotalesFooter({
            total_lunes: sumaLunes,
            total_martes: sumaMartes,
            total_miercoles: sumaMiercoles,
            total_jueves: sumaJueves,
            total_viernes: sumaViernes,
            total_sabado: sumaSabado,
            total_domingo: sumaDomingo,
            total_semana:
                sumaLunes +
                sumaMartes +
                sumaMiercoles +
                sumaJueves +
                sumaViernes +
                sumaSabado +
                sumaDomingo,
        });
    };

    return (
        <>
            <Grid container spacing={5} py={3} pr={3}>
                <Grid container justifyContent="center" alignItems="center">
                    <Grid item xs={12} md={2} mt={5} mx={5}>
                        <Button
                            variant="contained"
                            fullWidth
                            style={{ textTransform: 'none' }}
                            color={
                                isButtonSelected.diario
                                    ? 'publicidad'
                                    : 'primary'
                            }
                            onClick={handlePrereporteDiario}
                        >
                            Pre-reporte Diario
                        </Button>
                    </Grid>
                    <Grid item xs={12} md={2} mt={5} mx={5}>
                        <Button
                            variant="contained"
                            fullWidth
                            color={
                                isButtonSelected.semanal
                                    ? 'publicidad'
                                    : 'primary'
                            }
                            style={{ textTransform: 'none' }}
                            onClick={handleColocacionSemanal}
                        >
                            Cierre Colocación Semanal
                        </Button>
                    </Grid>
                </Grid>
                {reporteDataSemanal && (
                    <Grid container justifyContent="center" alignItems="center">
                        <Grid item xs={12} mt={5}>
                            <Typography variant="h4" color="primary">
                                Colocación Semanal{' '}
                                {campanaSelected
                                    ? ` - ${campanaSelected?.nombre}`
                                    : ''}{' '}
                                - {divisionSelected.nombre}
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="h5" color="primary">
                                Semana {dayjs(fecha).week()}
                            </Typography>
                            <Typography variant="h5" color="primary">
                                {weekMonthsName[0]} - {weekMonthsName[6]}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} md={10} mt={5}>
                            <DataGrid
                                apiRef={dataGridRef}
                                localeText={
                                    esES.components.MuiDataGrid.defaultProps
                                        .localeText
                                }
                                disableColumnSelector
                                disableDensitySelector
                                disableColumnFilter
                                disableColumnMenu
                                disableRowSelectionOnClick
                                hideFooterPagination
                                columns={columns}
                                rows={reporteDataSemanal}
                                loading={loading}
                                slots={{
                                    footer: DataGridTotalFooterColocacion,
                                }}
                                slotProps={{
                                    footer: {
                                        ...totalesFooter,
                                        totalSize:
                                            tipoSelected?.nombre === 'Campaña'
                                                ? 1.95
                                                : 2.15,
                                    },
                                }}
                                sx={DataGridStyle}
                            ></DataGrid>
                        </Grid>
                    </Grid>
                )}
                {reporteDataDiario && (
                    <PreReporteDiario
                        data={reporteDataDiario}
                        divisionSelected={divisionSelected}
                    />
                )}
            </Grid>
            <Modal open={open} onClose={handleClose}>
                <>
                    {openPreReporteColocacion && (
                        <ModalColocacion
                            URL={URL}
                            pais={pais}
                            divisiones={divisiones}
                            handleClose={handleClose}
                            handleContinuar={handleContinuar}
                            fecha={fecha}
                            setFecha={setFecha}
                            hoveredDay={hoveredDay}
                            setHoveredDay={setHoveredDay}
                        />
                    )}
                    {openPreReporteDiario && (
                        <ModalDiario
                            URL={URL}
                            pais={pais}
                            divisiones={divisiones}
                            handleClose={handleClose}
                            handleContinuar={handleContinuar}
                            fecha={fecha}
                            setFecha={setFecha}
                        />
                    )}
                </>
            </Modal>
        </>
    );
};

export default PreReportes;
