import {
    Autocomplete,
    Box,
    Button,
    Grid,
    TextField,
    Typography,
} from '@mui/material';
import { PDFDownloadLink } from '@react-pdf/renderer';
import RutaDocument from '../../../../../components/pdf-components/RutaDocument';
import { useState } from 'react';
import { sendRequest } from '../../../../../services/request';
import dayjs from 'dayjs';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '50%',
    height: '50%',
    bgcolor: 'background.paper',
    boxShadow: 24,
    px: 4,
};

const styleModalHeader = {
    top: 0,
    textAlign: 'center',
    position: 'sticky',
    zIndex: 15,
    py: 2,
    borderBottom: 'solid #0B3E83 1px',
    backgroundColor: '#ffffff',
};

const styleModalFooter = {
    width: '95%',
    bottom: 0,
    top: 0,
    position: 'sticky',
    ml: 2,
    mt: 2,
    pb: 3,
    backgroundColor: '#ffffff',
    zIndex: 15,
    alignItems: 'center',
};
const empresaEnvioOptions = [
    'Mensajero local',
    'Uno express',
    'Recoge en oficina',
];

const ExportarModal = ({ pais, handleClose }) => {
    const [data, setData] = useState('');
    const [empresaSelected, setEmpresaSelected] = useState('');
    const [pdfDataLoaded, setPDFDataLoaded] = useState(false);

    const URL = process.env.REACT_APP_API_URL;

    const handleChangeCanalEnvio = (event, newValue) => {
        setEmpresaSelected(newValue);
    };

    const handleGenerarPDF = async () => {
        try {
            const res = await sendRequest(
                'get',
                `${URL}/${pais}/envio/ruta?empresa=${empresaSelected}`,
            );
            setData(res.data);
            setPDFDataLoaded(true);
        } catch (error) {}
    };

    return (
        <Box sx={style}>
            <form>
                <Box sx={styleModalHeader}>
                    <Typography
                        id="modal-modal-title"
                        variant="h5"
                        color="primary"
                    >
                        Generar Ruta Mensajero
                    </Typography>
                </Box>
                <Box alignContent="center" justifyItems="center">
                    <Grid container spacing={2} sx={{ mt: '2px' }}>
                        <Grid item xs={12} md={6}>
                            <Autocomplete
                                disablePortal
                                fullWidth
                                value={empresaSelected}
                                options={empresaEnvioOptions}
                                getOptionLabel={(option) => option}
                                onChange={handleChangeCanalEnvio}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="Canal de envio"
                                        placeholder=""
                                        variant="standard"
                                    />
                                )}
                            ></Autocomplete>
                        </Grid>
                    </Grid>
                    <Grid container spacing={4} sx={styleModalFooter}>
                        <Grid item xs={12} md={6}>
                            <Button
                                fullWidth
                                variant="outlined"
                                onClick={handleClose}
                            >
                                Cerrar
                            </Button>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            {!pdfDataLoaded && (
                                <Button
                                    fullWidth
                                    variant="contained"
                                    onClick={handleGenerarPDF}
                                    disabled={!empresaSelected}
                                >
                                    Generar PDF
                                </Button>
                            )}
                            {pdfDataLoaded && (
                                <PDFDownloadLink
                                    document={
                                        <RutaDocument
                                            data={data}
                                            canal={empresaSelected}
                                        />
                                    }
                                    fileName={`Ruta_entrega_${dayjs(
                                        new Date(),
                                    ).format('DD-MM-YYYY')}.pdf`}
                                    onClick={() => setTimeout(handleClose, 500)}
                                >
                                    {({ blob, url, loading, error }) => (
                                        <Button fullWidth variant="contained">
                                            Descargar PDF
                                        </Button>
                                    )}
                                </PDFDownloadLink>
                            )}
                        </Grid>
                    </Grid>
                </Box>
            </form>
        </Box>
    );
};

export default ExportarModal;
