import { Box, Grid, Tab, Tabs, Typography } from '@mui/material';
import { useState } from 'react';
import { useLocation } from 'react-router-dom';
import Reportes from './reportes';
import PreReportes from './pre-reportes';

const Dashboard = ({ pais }) => {
    const location = useLocation();
    const [selectedTab, setSelectedTab] = useState(location.state?.tab || 0);

    const tabList = [
        {
            label: 'Reportes',
            value: 0,
        },
        {
            label: 'Pre-reportes',
            value: 1,
        },
    ];

    const hanldeChangeTab = (event, value) => {
        setSelectedTab(value);
    };

    return (
        <Box sx={{ height: '100%', width: '100%' }}>
            <Grid item xs={12} md={8} mt={2}>
                <Typography
                    variant="h4"
                    color="primary"
                    sx={{
                        textAlign: 'start',
                        marginLeft: '10px',
                    }}
                >
                    Bienvenido a tu CRM
                </Typography>
            </Grid>
            <Tabs value={selectedTab} onChange={hanldeChangeTab}>
                {tabList.map((item) => (
                    <Tab
                        sx={{ textTransform: 'none' }}
                        label={item.label}
                        value={item.value}
                    ></Tab>
                ))}
            </Tabs>
            {selectedTab === 0 && <Reportes />}
            {selectedTab === 1 && <PreReportes pais={pais} />}
        </Box>
    );
};

export default Dashboard;
