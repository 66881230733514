export const DataGridStyle = {
    maxHeight: '80vh',
    margin: '20px 10px',
    '& .header': {
        backgroundColor: 'primary.main',
        color: '#ffffff',
    },
    //Hace el wrap del texto en el header
    '& .MuiDataGrid-columnHeaderTitle': {
        whiteSpace: 'normal',
        lineHeight: 'normal',
        textAlign: 'center',
    },
    '.MuiDataGrid-columnHeaderTitleContainer': {
        backgroundColor: '#014181',
        color: 'white',
        '.MuiSvgIcon-root': {
            color: 'white',
        },
    },
    '& .MuiDataGrid-columnsContainer': {
        border: '1px solid',
    },
    '& .MuiDataGrid-columnHeader, .MuiDataGrid-cell': {
        borderRight: `1px solid #ffffff`,
    },
    '& .MuiDataGrid-cell': {
        whiteSpace: 'normal !important',
        wordWrap: 'normal',
        borderBottom: `1px solid #0B3E83`,
        borderRight: `1px solid #0B3E83`,
    },
    '& .MuiDataGrid-row:hover': {
        backgroundColor: 'rowShadow.main',
    },
};
