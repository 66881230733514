import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { useState } from 'react';
import { Day } from './Day';
import dayjs from 'dayjs';
import { TextField } from '@mui/material';
import 'dayjs/locale/es';

function ButtonField(props) {
    const {
        setOpen,
        label,
        id,
        disabled,
        InputProps: { ref } = {},
        inputProps: { 'aria-label': ariaLabel } = {},
    } = props;

    return (
        <TextField
            variant="standard"
            id={id}
            disabled={disabled}
            ref={ref}
            aria-label={ariaLabel}
            onClick={() => setOpen?.((prev) => !prev)}
            label={'Semana del año'}
            value={label ? `Semana ${label}` : ''}
        ></TextField>
    );
}

function ButtonDatePicker(props) {
    const [open, setOpen] = useState(false);

    return (
        <DatePicker
            slots={{ ...props.slots, field: ButtonField, day: Day }}
            slotProps={{
                ...props.slotProps,
                field: { setOpen },
                day: (ownerState) => ({
                    selectedDay: props.value,
                    hoveredDay: props.hoveredDay,
                    onPointerEnter: () => props.setHoveredDay(ownerState.day),
                    onPointerLeave: () => props.setHoveredDay(null),
                }),
            }}
            {...props}
            open={open}
            onClose={() => setOpen(false)}
            onOpen={() => setOpen(true)}
            displayWeekNumber
            showDaysOutsideCurrentMonth
            disableHighlightToday
            disableFuture
        />
    );
}

export default function WeekPicker({
    value,
    setValue,
    hoveredDay,
    setHoveredDay,
}) {
    return (
        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="es">
            <ButtonDatePicker
                label={value == null ? null : dayjs(value).week()}
                value={value}
                onChange={(newValue) => setValue(newValue)}
                hoveredDay={hoveredDay}
                setHoveredDay={setHoveredDay}
            />
        </LocalizationProvider>
    );
}
