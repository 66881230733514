import { Edit } from '@mui/icons-material';
import { Box, IconButton, Typography } from '@mui/material';
import { DataGrid, GridToolbar, esES } from '@mui/x-data-grid';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { sendRequest } from '../../../../../services/request';
import { useSelector } from 'react-redux';
import { DataGridStyle } from '../../../../../styles/DataGridSytle';

const Preview = ({ pais }) => {
    const [loading /* setLoading */] = useState(false);
    const [limit, setLimit] = useState(10);
    const [rowCount /* setRowCount */] = useState(0);
    const [rows, setRows] = useState([]);

    const usuario = useSelector((state) => state.auth.usuario);
    const URL = process.env.REACT_APP_API_URL;
    const navigate = useNavigate();

    useEffect(() => {
        sendRequest('get', `${URL}/${pais}/campana/promotor/${usuario?.id}`)
            .then((res) => {
                setRows(res.data);
            })
            .catch((err) => console.log(err));
    }, [URL, pais, usuario?.id]);

    const columns = [
        {
            field: 'campana',
            headerName: 'Campaña',
            headerClassName: 'header',
            headerAlign: 'center',
            sortable: false,
            flex: 1,
            renderCell: ({ row }) => {
                return row.nombre;
            },
        },
        {
            field: 'cantidad',
            headerName: 'Cantidad de datos Asignados',
            headerClassName: 'header',
            headerAlign: 'center',
            sortable: false,
            flex: 1,
            renderCell: ({ row }) => row.clientes_asignados.length,
        },
        {
            field: 'lider',
            headerName: 'Lider',
            headerClassName: 'header',
            headerAlign: 'center',
            sortable: false,
            flex: 1,
            renderCell: ({ row }) =>
                `${row.clientes_asignados[0].equipo.lider.nombres} ${row.clientes_asignados[0].equipo.lider.apellidos}`,
        },
        {
            field: 'acciones',
            type: 'actions',
            cellClassName: 'actions',
            headerName: 'Acciones',
            headerClassName: 'header',
            flex: 1,
            getActions: ({ row }) => {
                return [
                    <IconButton
                        color="primary"
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            borderRadius: 0,
                        }}
                        onClick={() =>
                            navigate('/app/promotor/bd', {
                                state: {
                                    campana: row,
                                },
                            })
                        }
                    >
                        <Edit />
                        <Typography>Trabajar BD</Typography>
                    </IconButton>,
                ];
            },
        },
    ];

    const handlePaginationChange = (event) => {
        /* setPage(event.page); */
        setLimit(event.pageSize);
    };

    return (
        <Box sx={{ height: '100%', width: '100%' }}>
            <Typography
                variant="h4"
                color="primary"
                sx={{
                    textAlign: 'start',
                    marginLeft: '10px',
                    marginTop: '20px',
                }}
            >
                Mis Bases de Datos
            </Typography>
            <DataGrid
                getRowId={(row) => row.id}
                localeText={esES.components.MuiDataGrid.defaultProps.localeText}
                disableColumnSelector
                disableDensitySelector
                disableColumnFilter
                disableColumnMenu
                disableRowSelectionOnClick
                loading={loading}
                initialState={{
                    pagination: {
                        paginationModel: { pageSize: limit },
                    },
                }}
                rows={rows ? rows : []}
                columns={columns}
                slots={{
                    toolbar: GridToolbar,
                }}
                slotProps={{
                    toolbar: {
                        showQuickFilter: false,
                        // Estos dos quitan el export
                        printOptions: { disableToolbarButton: true },
                        csvOptions: { disableToolbarButton: true },
                    },
                }}
                paginationMode="server"
                rowCount={rowCount ? rowCount : 0}
                onPaginationModelChange={handlePaginationChange}
                sx={DataGridStyle}
            />
        </Box>
    );
};

export default Preview;
