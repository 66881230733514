import {
    Box,
    Button,
    Grid,
    MenuItem,
    TextField,
    Typography,
} from '@mui/material';
import { useState } from 'react';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '80%',
    height: '80%',
    bgcolor: 'background.paper',
    overflowY: 'auto',
    boxShadow: 24,
    px: 4,
};

const styleModalHeader = {
    top: 0,
    textAlign: 'center',
    position: 'sticky',
    zIndex: 15,
    py: 2,
    /* borderBottom: 'solid #0B3E83 1px', */
    backgroundColor: '#ffffff',
};

const styleModalFooter = {
    bottom: 0,
    top: 0,
    position: 'sticky',
    mt: 24,
    pb: 3,
    backgroundColor: '#ffffff',
    zIndex: 15,
};

const CrearUsuario = ({ roles, handleClose, handleCrear, paises }) => {
    const [dataUsuario, setDataUsuario] = useState({
        nombres: '',
        apellidos: '',
        telefono: '',
        correo: '',
        rol: '',
        pais: '',
    });

    return (
        <Box sx={style}>
            <form>
                <Box sx={styleModalHeader}>
                    <Typography
                        id="modal-modal-title"
                        variant="h5"
                        color="primary"
                    >
                        Crear nuevo usuario
                    </Typography>
                </Box>
                <Grid container spacing={5} sx={{ mt: '2px' }}>
                    <Grid item xs={12} md={6}>
                        <TextField
                            required
                            fullWidth
                            id="nombres"
                            label="Nombre"
                            placeholder="Nombre del Usuario"
                            variant="outlined"
                            value={dataUsuario.nombres}
                            onChange={(event) =>
                                setDataUsuario({
                                    ...dataUsuario,
                                    nombres: event.target.value,
                                })
                            }
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField
                            required
                            fullWidth
                            id="apellidos"
                            label="Apellidos"
                            placeholder="Apellidos del Usuario"
                            variant="outlined"
                            value={dataUsuario.apellidos}
                            onChange={(event) =>
                                setDataUsuario({
                                    ...dataUsuario,
                                    apellidos: event.target.value,
                                })
                            }
                        />
                    </Grid>
                    {/* <Grid item xs={12} md={6}>
                        <TextField
                            required
                            fullWidth
                            id="telefono"
                            label="Telefono"
                            placeholder="Telefono de contacto"
                            variant="outlined"
                            value={dataUsuario.telefono}
                            onChange={(event) =>
                                setDataUsuario({
                                    ...dataUsuario,
                                    telefono: event.target.value,
                                })
                            }
                        />
                    </Grid> */}
                    <Grid item xs={12} md={6}>
                        <TextField
                            required
                            fullWidth
                            id="correo"
                            label="Correo"
                            placeholder="Correo del Usuario"
                            variant="outlined"
                            value={dataUsuario.correo}
                            onChange={(event) =>
                                setDataUsuario({
                                    ...dataUsuario,
                                    correo: event.target.value,
                                })
                            }
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField
                            select
                            fullWidth
                            id="rol"
                            label="Roles"
                            value={dataUsuario.rol}
                            variant="outlined"
                            width="100%"
                            onChange={(event) =>
                                setDataUsuario({
                                    ...dataUsuario,
                                    rol: event.target.value,
                                })
                            }
                        >
                            {roles &&
                                roles.map((rol) => {
                                    return (
                                        <MenuItem key={rol.id} value={rol.id}>
                                            {rol.nombre} - {rol.empresa.empresa}
                                        </MenuItem>
                                    );
                                })}
                        </TextField>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField
                            select
                            fullWidth
                            id="pais"
                            label="País predeterminado"
                            value={dataUsuario.pais}
                            variant="outlined"
                            width="100%"
                            onChange={(event) =>
                                setDataUsuario({
                                    ...dataUsuario,
                                    pais: event.target.value,
                                })
                            }
                        >
                            {paises &&
                                paises.map((pais) => {
                                    return (
                                        <MenuItem key={pais.id} value={pais.id}>
                                            {pais.nombre
                                                .charAt(0)
                                                .toUpperCase() +
                                                pais.nombre.slice(1)}
                                        </MenuItem>
                                    );
                                })}
                        </TextField>
                    </Grid>
                </Grid>
                <Grid container spacing={5} sx={styleModalFooter}>
                    <Grid item xs={12} md={6}>
                        <Button
                            fullWidth
                            variant="outlined"
                            onClick={handleClose}
                        >
                            Cerrar
                        </Button>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Button
                            fullWidth
                            variant="contained"
                            onClick={() => handleCrear(dataUsuario)}
                        >
                            Crear
                        </Button>
                    </Grid>
                </Grid>
            </form>
        </Box>
    );
};

export default CrearUsuario;
