import {
    Autocomplete,
    Box,
    Checkbox,
    IconButton,
    TextField,
    Typography,
} from '@mui/material';
import { DataGrid, esES } from '@mui/x-data-grid';
import { useCallback, useEffect, useState } from 'react';
import { sendRequest } from '../../../../services/request';
import dayjs from 'dayjs';
import weekOfYear from 'dayjs/plugin/weekOfYear';
import { CheckBox, CheckBoxOutlineBlank, DoneAll } from '@mui/icons-material';
import { useLocation } from 'react-router-dom';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DataGridStyle } from '../../../../styles/DataGridSytle';

dayjs.extend(weekOfYear);

const empresaEnvioOptions = [
    'Mensajero local',
    'Uno express',
    'Recoge en oficina',
];

const EnviosClientesFinales = ({ pais }) => {
    const [rows, setRows] = useState([]);
    const [limit, setLimit] = useState(50);
    const [page, setPage] = useState(0);
    const [rowCount, setRowCount] = useState(null);

    // tarjetones
    const [tarjetones, setTarjetones] = useState([]);

    //Condicionales
    const [loading, setLoading] = useState(false);
    const [recargar, setRecargar] = useState(false);

    const location = useLocation();
    const URL = process.env.REACT_APP_API_URL;

    const campana = location.state?.campana || '';
    //const fieldsToExport = ['cliente', 'destino'];

    useEffect(() => {
        const fetchData = async () => {
            try {
                setLoading(true);
                const res = await sendRequest(
                    'get',
                    `${URL}/${pais}/envio?page=${page}&limit=${limit}&campanaId=${campana.id}`,
                );

                setLoading(false);
                setRows(res.data.items);
                setRowCount(res.data.meta.totalItems);
            } catch (error) {
                console.log(error);
            }
        };
        fetchData();
    }, [URL, pais, page, limit, campana, recargar]);

    useEffect(() => {
        const fetchData = async () => {
            const res = await sendRequest(
                'get',
                `${URL}/${pais}/tarjeton/unpaginated?campanaId=${campana.id}&sin_envio=1`,
            );
            setTarjetones(res.data);
        };
        fetchData();
    }, [URL, pais, campana]);

    const columns = [
        /* {
            field: 'numero',
            headerName: 'Número',
            flex: 0.5,
            headerClassName: 'header',
            sortable: false,
            renderCell: ({ row }) => row.id,
        }, */
        {
            field: 'cliente',
            headerName: campana.division.id === 1 ? 'Cliente' : 'Donante',
            flex: 1,
            headerClassName: 'header',
            headerAlign: 'center',
            sortable: false,
            valueFormatter: ({ value }) =>
                `${value.nombres} ${value.apellidos ? value.apellidos : ''}`,
            renderCell: ({ row }) =>
                `${row.cliente?.nombres} ${
                    row.cliente.apellidos ? row.cliente.apellidos : ''
                }`,
        },
        {
            field: 'celular',
            headerName: 'Celular',
            headerClassName: 'header',
            headerAlign: 'center',
            sortable: false,
            renderCell: ({ row }) => row.cliente.celular,
        },
        {
            field: 'destino',
            headerName: 'Destino',
            flex: 1.5,
            headerClassName: 'header',
            headerAlign: 'center',
            sortable: false,
            valueGetter: ({ row }) => row.cliente?.direccion_exacta,
            renderCell: ({ row }) => row.cliente?.direccion_exacta,
        },
        {
            field: 'numero_tarjeton',
            headerName:
                campana.division.id === 1 ? '# Tarjeton' : '# Tarjeta / Cert.',
            flex: 1.5,
            headerClassName: 'header',
            sortable: false,
            headerAlign: 'center',
            align: 'center',
            renderCell: ({ row }) => {
                return (
                    <Autocomplete
                        disabled={row.fecha_envio || row.cantidad <= 0}
                        multiple
                        disableCloseOnSelect
                        disablePortal
                        fullWidth
                        value={row.tarjetones}
                        options={tarjetones}
                        getOptionLabel={(option) => option.numero}
                        getOptionDisabled={(option) =>
                            row.tarjetones.length >= row.cantidad
                        }
                        size="small"
                        onChange={(event, newValue) =>
                            handleChangeTarjeton(newValue, row)
                        }
                        renderOption={(props, option, { selected }) => (
                            <li {...props} key={option.id}>
                                <Checkbox
                                    sx={{
                                        ml: -1,
                                    }}
                                    icon={
                                        <CheckBoxOutlineBlank fontSize="small" />
                                    }
                                    checkedIcon={<CheckBox fontSize="small" />}
                                    checked={selected}
                                />
                                {option.numero}
                            </li>
                        )}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                placeholder=""
                                variant="standard"
                            />
                        )}
                    ></Autocomplete>
                );
            },
        },
        {
            field: 'estado',
            headerName: 'Estado',
            flex: 1,
            headerClassName: 'header',
            headerAlign: 'center',
            sortable: false,
            renderCell: ({ row }) => {
                //Verifico si el estado es Enviado entonces le agrego el a Cliente o Donante segun la division
                if (row.estado.id === 7) {
                    return `${row.estado?.estado} a ${
                        campana.division.id === 1 ? 'Cliente' : 'Donante'
                    }`;
                }
                return row.estado?.estado;
            },
        },
        {
            field: 'semana_preventa',
            headerName: 'Semana Preventa',
            headerClassName: 'header',
            sortable: false,
            headerAlign: 'center',
            align: 'center',
            renderCell: ({ row }) => dayjs(row.created_at).week(),
        },
        {
            field: 'fecha_envio',
            headerName: 'Fecha envio',
            flex: 1,
            headerClassName: 'header',
            sortable: false,
            editable: true,
            headerAlign: 'center',
            align: 'center',
            renderCell: ({ row }) =>
                row.fecha_envio ? (
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                            value={dayjs(row.fecha_envio)}
                            format="DD/MM/YYYY"
                            slotProps={{
                                textField: {
                                    variant: 'standard',
                                },
                            }}
                        ></DatePicker>
                    </LocalizationProvider>
                ) : (
                    'No entregado'
                ),
        },
        {
            field: 'acciones',
            type: 'actions',
            cellClassName: 'actions',
            headerName: 'Acciones',
            headerClassName: 'header',
            flex: 1.2,
            getActions: ({ row }) => {
                return [
                    <IconButton
                        disabled={row.fecha_envio}
                        color="primary"
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            borderRadius: 0,
                        }}
                        onClick={() => handleConfirmarEntrega(row)}
                    >
                        <DoneAll />
                        <Typography>
                            {row.fecha_envio
                                ? 'Entregado'
                                : 'Confirmar Entrega'}
                        </Typography>
                    </IconButton>,
                ];
            },
        },
    ];
    // Verifico si la campaña es publicidad o fundraising
    if (campana.division.id === 1) {
        const pubColumns = [
            {
                field: 'cantidad',
                headerName: 'Cantidad',
                flex: 'auto',
                headerClassName: 'header',
                editable: true,
                sortable: false,
                headerAlign: 'center',
                align: 'center',
                renderCell: ({ row }) => row.cantidad,
            },
            {
                field: 'numero_guia',
                headerName: 'No. de Guía',
                flex: 1,
                headerClassName: 'header',
                editable: true,
                sortable: false,
                headerAlign: 'center',
                align: 'center',
                renderCell: ({ row }) => row.numero_guia,
            },
            {
                field: 'empresa_envio',
                headerName: 'Canal de Envío',
                flex: 1.5,
                headerClassName: 'header',
                headerAlign: 'center',
                sortable: false,
                renderCell: ({ row }) => {
                    return (
                        <Autocomplete
                            disabled={row.fecha_envio || row.cantidad <= 0}
                            disablePortal
                            fullWidth
                            value={row.empresa_envio}
                            options={empresaEnvioOptions}
                            getOptionLabel={(option) => option}
                            size="small"
                            onChange={(event, newValue) =>
                                handleChangeEmpresaEnvio(newValue, row)
                            }
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    placeholder=""
                                    variant="standard"
                                />
                            )}
                        ></Autocomplete>
                    );
                },
            },
        ];
        if (pais === 'panama') {
            pubColumns.splice(1, 1);
        }
        columns.splice(4, 0, ...pubColumns);
        // Add cantidad to the export fields
        //fieldsToExport.push('cantidad');
    } else if (campana.division.id === 2) {
        const fundColumns = [
            {
                field: 'donacion_total',
                headerName: 'Donación Total',
                flex: 1,
                headerClassName: 'header',
                editable: true,
                sortable: false,
                headerAlign: 'center',
                align: 'center',
            },
        ];
        columns.splice(4, 0, ...fundColumns);
    }

    const handleChangeTarjeton = async (newValue, envio) => {
        try {
            for (const tarjeton of newValue) {
                await sendRequest(
                    'patch',
                    `${URL}/${pais}/tarjeton/${tarjeton.id}`,
                    {
                        ...tarjeton,
                        envio: envio.id,
                    },
                );
            }
            setRecargar(!recargar);
        } catch (error) {
            console.log(error);
        }
    };

    const handleChangeEmpresaEnvio = async (newValue, row) => {
        try {
            await sendRequest('put', `${URL}/${pais}/envio/${row.id}`, {
                ...row,
                empresa_envio: newValue,
                estado: newValue ? 7 : 6,
            });
            setRecargar(!recargar);
        } catch (error) {
            console.log(error);
        }
    };

    const handleConfirmarEntrega = async (envio) => {
        try {
            await sendRequest('put', `${URL}/${pais}/envio/${envio.id}`, {
                ...envio,
                fecha_envio: dayjs(new Date()).format('YYYY-MM-DD HH:MM:ss'),
                estado: {
                    id: 9,
                    estado: 'Afiliado',
                },
                confirmado: true,
            });
            setRecargar(!recargar);
        } catch (error) {
            console.log(error);
        }
    };

    const handlePaginationChange = (event) => {
        setPage(event.page);
        setLimit(event.pageSize);
    };

    const handleRowUpdate = useCallback(
        async (newRow, originalRow) => {
            const res = await sendRequest(
                'put',
                `${URL}/${pais}/envio/${newRow.id}`,
                {
                    ...newRow,
                    estado: newRow.empresa_envio
                        ? {
                              id: 7,
                              estado: 'Enviado a Cliente',
                          }
                        : {
                              id: 6,
                              estado: 'Preventa',
                          },
                },
            );
            return res.data;
        },
        [URL, pais],
    );

    const handleRowUpdateError = (error) => {
        console.log(error);
    };

    const handleIsEditable = ({ row, field }) => {
        let isEditable = false;
        switch (field) {
            case 'numero_tarjeton':
                if (row.cantidad > 0 && !row.fecha_envio) isEditable = true;
                break;
            case 'cantidad':
                if (row.cantidad >= 0 && !row.fecha_envio) isEditable = true;
                break;
            case 'numero_guia':
                if (row.cantidad > 0 && !row.fecha_envio) isEditable = true;
                break;
            case 'empresa_envio':
                if (row.cantidad > 0 && !row.fecha_envio) isEditable = true;
                break;
            case 'fecha_envio':
                if (row.fecha_envio) isEditable = true;
                break;
            case 'donacion_total':
                /* if (row.donacion_total)  */ isEditable = true;
                break;
            default:
                isEditable = false;
                break;
        }
        return isEditable;
    };

    /**
     * Realiza la validacion de las filas que cumplen con estado en enviado a cliente para poder hacer el export
     * @param  {{string, Object}}  params Parametros del data grid, en esta función solo se usan el field y row
     * @return {string}      Devuelve el nombre del className segun el campo
     */
    /*  */
    const handleGetClassName = ({ field, row }) => {
        switch (field) {
            case 'numero_tarjeton':
            case 'empresa_envio':
                if (row.cantidad > 0 && !row.fecha_envio)
                    return 'custom-editable';
                break;
            default:
                break;
        }
    };

    /**
     * Realiza la validacion de las filas que cumplen con estado en enviado a cliente para poder hacer el export
     * @param  {import('@mui/x-data-grid').GridCsvGetRowsToExportParams} [params] apiRef del Datagrid
     * @return {number[]}      Devuleve el listado de los items que se quieren exportar
     */
    /*  */
    /* const handleRowsToExport = (params) => {
        const rowsIds = rows
            .filter((item) => item.estado.estado === 'Enviado a Cliente')
            .map((item) => item.id);
        return rowsIds;
    }; */

    return (
        <>
            <Box sx={{ height: '100%', width: '100%' }}>
                <Typography
                    variant="h4"
                    color="primary"
                    sx={{
                        textAlign: 'start',
                        marginLeft: '10px',
                        marginTop: '20px',
                    }}
                >
                    Envío y Entrega a Clientes Finales / {campana.nombre}
                </Typography>

                <DataGrid
                    localeText={
                        esES.components.MuiDataGrid.defaultProps.localeText
                    }
                    disableColumnSelector
                    disableDensitySelector
                    disableColumnFilter
                    disableColumnMenu
                    disableRowSelectionOnClick
                    loading={loading}
                    initialState={{
                        pagination: {
                            paginationModel: { pageSize: limit },
                        },
                    }}
                    rows={rows ? rows : []}
                    columns={columns}
                    slots={
                        {
                            // Esto solo muestra el exportar a CSV
                            /* toolbar: CustomToolbar, */
                        }
                    }
                    slotProps={{
                        toolbar: {
                            /* csvOptions: {
                                //fields: fieldsToExport,
                                delimiter: ';',
                                utf8WithBom: true,
                                getRowsToExport: handleRowsToExport,
                                fileName: `Listado de Envios - ${dayjs(
                                    new Date(),
                                ).format('DD-MM-YYYY')}`,
                            }, */
                            showQuickFilter: false,
                            // Estos dos quitan el export
                            printOptions: { disableToolbarButton: true },
                            csvOptions: { disableToolbarButton: true },
                        },
                    }}
                    paginationMode="server"
                    rowCount={rowCount ? rowCount : 0}
                    onPaginationModelChange={handlePaginationChange}
                    processRowUpdate={handleRowUpdate}
                    onProcessRowUpdateError={handleRowUpdateError}
                    isCellEditable={handleIsEditable}
                    getCellClassName={handleGetClassName}
                    sx={[
                        DataGridStyle,
                        {
                            //Hace el cambio del color de los campos editables
                            '& .MuiDataGrid-cell--editable': {
                                border: 1,
                                borderColor: '#ffffff',
                                bgcolor: '#016A8225', // Los ultimos digitos son la transparencia (25%)
                            },
                        },
                    ]}
                />
            </Box>
        </>
    );
};

export default EnviosClientesFinales;
