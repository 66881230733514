import {
    Box,
    Button,
    Grid,
    IconButton,
    Modal,
    Typography,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import AñadirUsuariosEquipo from './añadirUsuarios';
import { DataGrid, GridToolbar, esES } from '@mui/x-data-grid';
import { sendRequest } from '../../../../services/request';
import { GroupAdd, GroupRemove, PersonRemove } from '@mui/icons-material';
import RolesGate from '../../../../components/utils-components/permissions/rolesGate';
import { ROLES } from '../../../../components/utils-components/permissions/permissions-map';
import { LinearProgressWithLabel } from '../../../../components/layout-components/LinearProgressWithLabel';
import { DataGridStyle } from '../../../../styles/DataGridSytle';

const DetalleEquipo = ({ pais }) => {
    const URL = process.env.REACT_APP_API_URL;
    const location = useLocation();
    const navigate = useNavigate();
    const campanaId = location.state?.campana?.id;
    const equipoId = location.state?.equipo?.id;
    const totalClientesAsignadosAlEquipo =
        location.state?.equipo?.clientes_asignados?.length;

    const [data, setData] = useState([]);
    const [rowCount, setRowCount] = useState(0);
    const [limit, setLimit] = useState(10);
    const [page, setPage] = useState(0);
    const [clientesPorAsignarDisponibles, setClientesPorAsignarDisponibles] =
        useState(0);

    const cliente_donante_string =
        location.state?.campana?.division?.nombre === 'Publicidad'
            ? 'Clientes'
            : 'Donantes';

    // Condicionales
    const [loading, setLoading] = useState(false);

    // Modal
    const [openModal, setOpenModal] = useState(false);

    // Re-render
    const [recargar, setRecargar] = useState(false);

    useEffect(() => {
        setLoading(true);
        const fetchData = async () => {
            try {
                const res = await sendRequest(
                    'get',
                    `${URL}/${pais}/equipos/${equipoId}/usuarios?page=${page}&limit=${limit}`,
                );
                setLoading(false);
                setData(res.data);
                setRowCount(res.data.length);
                let sumaClientesPorAsignar = 0;
                for (const row of res.data) {
                    sumaClientesPorAsignar +=
                        row.equipo?.clientes_asignados?.length;
                }
                setClientesPorAsignarDisponibles(
                    totalClientesAsignadosAlEquipo - sumaClientesPorAsignar,
                );
            } catch (error) {
                console.log(error);
            }
        };
        fetchData();
    }, [
        URL,
        pais,
        page,
        limit,
        equipoId,
        recargar,
        totalClientesAsignadosAlEquipo,
    ]);

    const columns = [
        {
            field: 'codigo',
            headerName: 'Código',
            headerClassName: 'header',
            headerAlign: 'center',
            sortable: false,
            flex: 1,
            renderCell: ({ row }) => {
                return row.usuario?.codigo;
            },
        },
        {
            field: 'nombres',
            headerName: 'Nombres',
            headerClassName: 'header',
            headerAlign: 'center',
            sortable: false,
            flex: 1,
            renderCell: ({ row }) => {
                return row.usuario?.nombres;
            },
        },
        {
            field: 'apellidos',
            headerName: 'Apellidos',
            headerClassName: 'header',
            headerAlign: 'center',
            sortable: false,
            flex: 1,
            renderCell: ({ row }) => {
                return row.usuario?.apellidos;
            },
        },
        {
            field: 'clientes_asignados',
            headerName: `${cliente_donante_string} Asignados`,
            headerClassName: 'header',
            headerAlign: 'center',
            sortable: false,
            flex: 1,
            renderCell: ({ row }) => {
                if (pais === 'colombia') {
                    return row.usuario?.cliente_asignado?.length;
                }
                return row.usuario?.cliente_asignado_panama?.length;
            },
        },
        {
            field: 'rendimiento',
            headerName: 'Rendimiento semanal',
            headerClassName: 'header',
            headerAlign: 'center',
            sortable: false,
            flex: 1,
            renderCell: ({ row }) => {
                let rendimiento = 0;
                if (pais === 'colombia') {
                    rendimiento =
                        ((row.equipo.clientes_asignados.length -
                            row.usuario.cliente_asignado?.length) /
                            row.equipo.clientes_asignados.length) *
                        100;
                } else {
                    rendimiento =
                        ((row.equipo.clientes_asignados.length -
                            row.usuario.cliente_asignado_panama?.length) /
                            row.equipo.clientes_asignados.length) *
                        100;
                }
                return (
                    <Box sx={{ width: '100%' }}>
                        <LinearProgressWithLabel
                            value={isNaN(rendimiento) ? 0 : rendimiento}
                        />
                    </Box>
                );
            },
        },
        {
            field: 'acciones',
            type: 'actions',
            cellClassName: 'actions',
            headerName: 'Acciones',
            headerClassName: 'header',
            flex: 1.8,
            getActions: ({ row }) => {
                return [
                    <RolesGate
                        roles={[
                            ROLES.GERENCIA_EFFECTIVE,
                            ROLES.GERENCIA_WINNER,
                            ROLES.DIRECTOR_OPERATIVO,
                        ]}
                    >
                        <IconButton
                            color="primary"
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                borderRadius: 0,
                            }}
                            onClick={() => handleAsignar(row)}
                        >
                            <GroupAdd />
                            <Typography>Asignar</Typography>
                        </IconButton>
                    </RolesGate>,
                    <RolesGate
                        roles={[
                            ROLES.GERENCIA_EFFECTIVE,
                            ROLES.GERENCIA_WINNER,
                            ROLES.DIRECTOR_OPERATIVO,
                        ]}
                    >
                        {(row.usuario.cliente_asignado?.length > 0 ||
                            row.usuario.cliente_asignado_panama?.length >
                                0) && (
                            <IconButton
                                color="primary"
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    borderRadius: 0,
                                }}
                                onClick={() => handleDesasignar(row)}
                            >
                                <GroupRemove />
                                <Typography>Desasignar</Typography>
                            </IconButton>
                        )}
                    </RolesGate>,
                    <RolesGate
                        roles={[
                            ROLES.GERENCIA_EFFECTIVE,
                            ROLES.GERENCIA_WINNER,
                            ROLES.DIRECTOR_OPERATIVO,
                        ]}
                    >
                        <IconButton
                            color="danger"
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                borderRadius: 0,
                            }}
                            onClick={() => handleEliminar(row)}
                        >
                            <PersonRemove />
                            <Typography>Eliminar</Typography>
                        </IconButton>
                    </RolesGate>,
                ];
            },
        },
    ];

    const handleAsignar = (row) => {
        navigate('/app/equipos/clientes/asignar', {
            state: {
                equipo: row.equipo,
                promotor: row.usuario,
                campana: row.equipo.campana,
            },
        });
    };

    const handleDesasignar = (equipoUsuario) => {
        navigate('/app/equipos/clientes/desasignar', {
            state: {
                promotor: equipoUsuario.usuario,
                equipo: location.state?.equipo?.id,
                campanaId,
            },
        });
    };

    const handleEliminar = async (row) => {
        try {
            await sendRequest('delete', `${URL}/${pais}/equipos/usuario`, {
                equipo: equipoId,
                usuario: row.usuario.id,
                campana: row.equipo.campana.id,
            });
            setRecargar(!recargar);
        } catch (error) {
            console.log(error);
        }
    };

    const handlePaginationChange = (event, page) => {
        setPage(page);
        setLimit(event.pageSize);
    };

    const handleAñadirUsuariosAEquipo = () => {
        setOpenModal(!openModal);
    };

    const handleClose = () => {
        setOpenModal(!openModal);
        setRecargar(!recargar);
    };

    return (
        <>
            <Grid container spacing={5} py={3} pr={3}>
                <Grid item xs={12} sm={6} md={8}>
                    <Typography
                        variant="h4"
                        color="primary"
                        sx={{
                            textAlign: 'start',
                            marginLeft: '10px',
                        }}
                    >
                        Equipos / {location.state?.equipo?.nombre}
                    </Typography>
                </Grid>
                <Grid item xs={12} md={4}>
                    <Button
                        fullWidth
                        variant="contained"
                        onClick={handleAñadirUsuariosAEquipo}
                    >
                        Añadir promotor al Equipo
                    </Button>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="h6">
                        {cliente_donante_string} disponibles por asignar:{' '}
                        <b>{clientesPorAsignarDisponibles}</b>
                    </Typography>
                </Grid>
            </Grid>
            <Grid container minHeight={'50vh'} maxHeight={'80vh'}>
                <DataGrid
                    localeText={
                        esES.components.MuiDataGrid.defaultProps.localeText
                    }
                    disableColumnSelector
                    disableDensitySelector
                    disableColumnFilter
                    disableColumnMenu
                    loading={loading}
                    initialState={{
                        pagination: {
                            paginationModel: { pageSize: limit },
                        },
                    }}
                    rows={data ? data : []}
                    getRowId={(row) => `${row.equipo}-${row.usuario?.id}`}
                    columns={columns}
                    paginationMode="server"
                    rowCount={rowCount ? rowCount : 0}
                    onPaginationModelChange={handlePaginationChange}
                    slots={{
                        toolbar: GridToolbar,
                    }}
                    slotProps={{
                        toolbar: {
                            showQuickFilter: false,
                            // Estos dos quitan el export
                            printOptions: {
                                disableToolbarButton: true,
                            },
                            csvOptions: { disableToolbarButton: true },
                        },
                    }}
                    sx={DataGridStyle}
                />
            </Grid>
            <Modal
                open={openModal}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <AñadirUsuariosEquipo
                    pais={pais}
                    equipoId={location.state?.equipo.id}
                ></AñadirUsuariosEquipo>
            </Modal>
        </>
    );
};

export default DetalleEquipo;
