import { useEffect, useState } from 'react';
import { sendRequest } from '../../../../../services/request';
import { useLocation } from 'react-router-dom';
import { DataGrid, GridToolbar, esES } from '@mui/x-data-grid';
import { Box, Typography, Grid, Button } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
import { DataGridStyle } from '../../../../../styles/DataGridSytle';
/* import ListHeader from './listHeader'; */

const CampanaDesasignarClientes = ({ pais }) => {
    const [rows, setRows] = useState();
    const [limit, setLimit] = useState(50);
    const [page, setPage] = useState(0);
    const [rowCount, setRowCount] = useState(null);
    const URL = `${process.env.REACT_APP_API_URL}`;

    const location = useLocation();
    const campana = location.state?.campana;

    // Condicionales
    const [loading, setLoading] = useState(false);

    // Campos selected
    const [rowsSelected, setRowsSelected] = useState([]);

    //Re-render
    const [guardado, setGuardado] = useState(false);

    useEffect(() => {
        setLoading(true);
        sendRequest(
            'get',
            `${URL}/${pais}/clientes-asignados/campana/${campana?.id}?limit=${limit}&page=${page}`,
        ).then((res) => {
            setLoading(false);
            setRows(res.data.items);
            setRowCount(res.data.meta.totalItems);
        });
    }, [URL, pais, limit, page, campana, guardado]);

    const columns = [
        {
            field: 'nombres',
            headerName: 'Nombres',
            flex: 0.5,
            headerClassName: 'header',
            headerAlign: 'center',
            sortable: false,
            renderCell: ({ row }) => {
                return row.cliente?.nombres;
            },
        },
        {
            field: 'apellidos',
            headerName: 'Apellidos',
            headerClassName: 'header',
            headerAlign: 'center',
            sortable: false,
            renderCell: ({ row }) => {
                return row.cliente?.apellidos;
            },
        },
        {
            field: 'tipo_documento',
            headerName: 'Tipo Doc.',
            headerClassName: 'header',
            headerAlign: 'center',
            sortable: false,
            align: 'center',
            valueGetter: ({ row }) => {
                return row.cliente?.tipo_documento?.tipo;
            },
        },
        {
            field: 'numero_documento',
            headerName: 'Núm. Doc.',
            headerClassName: 'header',
            headerAlign: 'center',
            sortable: false,
            valueGetter: ({ row }) => {
                return row.cliente?.numero_documento;
            },
        },
        {
            field: 'genero',
            headerName: 'Género',
            headerClassName: 'header',
            headerAlign: 'center',
            sortable: false,
            valueGetter: ({ row }) => {
                return row.cliente?.genero?.genero;
            },
        },
        {
            field: 'celular',
            headerName: 'Celular',
            headerClassName: 'header',
            headerAlign: 'center',
            sortable: false,
            valueGetter: ({ row }) => {
                return row.cliente?.celular;
            },
        },
        {
            field: 'correo',
            headerName: 'Correo',
            flex: 1,
            headerClassName: 'header',
            headerAlign: 'center',
            sortable: false,
            valueGetter: ({ row }) => {
                return row.cliente?.correo;
            },
        },
        {
            field: 'tratamiento_datos',
            headerName: 'Trat. Datos',
            headerClassName: 'header',
            headerAlign: 'center',
            align: 'center',
            sortable: false,
            renderCell: (params) => {
                if (params.value) {
                    return <CheckIcon></CheckIcon>;
                } else {
                    return <ClearIcon></ClearIcon>;
                }
            },
        },
    ];

    const handlePaginationChange = (event) => {
        setPage(event.page);
        setLimit(event.pageSize);
    };

    const handleDesasignar = async () => {
        try {
            if (rowsSelected.length > 0) {
                await sendRequest(
                    'delete',
                    `${URL}/${pais}/clientes-asignados/campana/desasignar`,
                    {
                        ids: rowsSelected,
                    },
                );
                setGuardado(!guardado);
            }
        } catch (error) {
            console.log(error);
        }
    };

    return (
        <>
            <Box sx={{ height: '100%', width: '100%' }}>
                <Grid container spacing={5} py={3} pr={3}>
                    <Grid item xs={12} md={8}>
                        <Typography
                            variant="h4"
                            color="primary"
                            sx={{
                                textAlign: 'start',
                                marginLeft: '10px',
                            }}
                        >
                            Desasignar Clientes / {campana?.nombre}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Button
                            fullWidth
                            variant="contained"
                            onClick={handleDesasignar}
                        >
                            Desasignar Clientes
                        </Button>
                    </Grid>
                </Grid>
                {/* <ListHeader
                    setLimit={setLimit}
                    campañas={campañas}
                    searchCampana={searchCampana}
                    setSearchCampana={setSearchCampana}></ListHeader> */}
                <DataGrid
                    localeText={
                        esES.components.MuiDataGrid.defaultProps.localeText
                    }
                    disableColumnSelector
                    disableDensitySelector
                    disableColumnFilter
                    disableColumnMenu
                    checkboxSelection
                    disableRowSelectionOnClick
                    loading={loading}
                    initialState={{
                        pagination: {
                            paginationModel: { pageSize: limit },
                        },
                    }}
                    rows={rows ? rows : []}
                    columns={columns}
                    slots={{
                        toolbar: GridToolbar,
                    }}
                    slotProps={{
                        toolbar: {
                            showQuickFilter: false,
                            // Estos dos quitan el export
                            printOptions: { disableToolbarButton: true },
                            csvOptions: { disableToolbarButton: true },
                        },
                    }}
                    paginationMode="server"
                    rowCount={rowCount ? rowCount : 0}
                    onPaginationModelChange={handlePaginationChange}
                    onRowSelectionModelChange={(newRowSelection) => {
                        setRowsSelected(newRowSelection);
                    }}
                    sx={DataGridStyle}
                />
            </Box>
            {/* <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description">
                <ModalAsignarClienteEquipo
                    URL={URL}
                    pais={pais}
                    equipos={equipos}
                    handleClose={handleClose}
                    handleAsignar={handleAsignar}></ModalAsignarClienteEquipo>
            </Modal> */}
        </>
    );
};

export default CampanaDesasignarClientes;
