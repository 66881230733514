import {
    Autocomplete,
    Box,
    Button,
    Grid,
    TextField,
    Typography,
} from '@mui/material';
import { useState } from 'react';
import dayjs from 'dayjs';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '40%',
    bgcolor: 'background.paper',
    overflowY: 'auto',
    boxShadow: 24,
    px: 4,
};

const styleModalHeader = {
    top: 0,
    textAlign: 'center',
    position: 'sticky',
    zIndex: 15,
    py: 2,
    /* borderBottom: 'solid #0B3E83 1px', */
    backgroundColor: '#ffffff',
};

const styleModalFooter = {
    bottom: 0,
    top: 0,
    position: 'sticky',
    mt: 10,
    pb: 3,
    backgroundColor: '#ffffff',
    zIndex: 15,
};

const ModalDiario = ({
    URL,
    pais,
    divisiones,
    handleClose,
    handleContinuar,
}) => {
    const fecha = dayjs(new Date()).format('MMM DD');
    const [division, setDivision] = useState(null);

    const handleChangeDivisiones = (event, newValue) => {
        setDivision(newValue);
    };

    return (
        <Box sx={style}>
            <form>
                <Box sx={styleModalHeader}>
                    <Typography
                        id="modal-modal-title"
                        variant="h5"
                        color="primary"
                    >
                        Pre-reporte Diario
                    </Typography>
                    <Typography
                        id="modal-modal-title"
                        variant="p"
                        color="primary"
                    >
                        {fecha}
                    </Typography>
                </Box>
                <Grid container spacing={2} sx={{ mt: '2px' }}>
                    <Grid item xs={12}>
                        <Autocomplete
                            disablePortal
                            fullWidth
                            value={division}
                            options={divisiones}
                            getOptionLabel={(option) => option.nombre}
                            onChange={handleChangeDivisiones}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="Divisiones"
                                    placeholder=""
                                    variant="standard"
                                />
                            )}
                        ></Autocomplete>
                    </Grid>
                </Grid>
                <Grid container spacing={5} sx={styleModalFooter}>
                    <Grid item xs={12} md={6}>
                        <Button
                            fullWidth
                            variant="outlined"
                            onClick={handleClose}
                        >
                            Cerrar
                        </Button>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Button
                            fullWidth
                            variant="contained"
                            onClick={() =>
                                handleContinuar('pre-reporte_diario', {
                                    division,
                                })
                            }
                        >
                            Continuar
                        </Button>
                    </Grid>
                </Grid>
            </form>
        </Box>
    );
};

export default ModalDiario;
