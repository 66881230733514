import { Route, Routes } from 'react-router-dom';
import ConsultaCliente from './cliente/consulta';
import ConsultaCampana from './campaña/consulta';
import ConsultaEquipo from './equipo/consulta';
import TabsInventario from './inventario/tabsInventario';
import CampanaAsignarCliente from './campaña/clientes';
import EquipoAsignarCliente from './equipo/cliente';
import PromotorView from './promotor';
import ValidarOrdenImpresion from './inventario/ordenesInventario/validarOrdenImpresion';
import CampanaClientesVerDetalle from './campaña/clientes/verDetalle';
import Mantenimiento from './mantenimiento';
import DetalleEquipo from './equipo/detalle';
import ClientesPorDesasignar from './equipo/detalle/desasignarClientes';
import CampanaDesasignarClientes from './campaña/clientes/desasignarClientes';
/* import ValidarClienteJotform from './validarClienteJotform';
import ValidarClienteCampanaPreview from './validarClienteJotform/campana-preview'; */
import EnviosClientesFinales from './inventario/enviosClientesFinales';
import Dashboard from './dashboard';

const AppViews = ({ pais }) => {
    return (
        <Routes>
            <Route
                path="dashboard"
                element={<Dashboard pais={pais}></Dashboard>}
            ></Route>
            <Route
                path="clientes"
                element={<ConsultaCliente pais={pais}></ConsultaCliente>}
            ></Route>
            <Route
                path="campanas"
                element={<ConsultaCampana pais={pais}></ConsultaCampana>}
            ></Route>
            <Route
                path="campana/clientes"
                element={
                    <CampanaClientesVerDetalle
                        pais={pais}
                    ></CampanaClientesVerDetalle>
                }
            ></Route>
            <Route
                path="campana/clientes/asignar"
                element={
                    <CampanaAsignarCliente pais={pais}></CampanaAsignarCliente>
                }
            ></Route>
            <Route
                path="campana/clientes/desasignar"
                element={
                    <CampanaDesasignarClientes
                        pais={pais}
                    ></CampanaDesasignarClientes>
                }
            ></Route>
            <Route
                path="equipos"
                element={<ConsultaEquipo pais={pais}></ConsultaEquipo>}
            ></Route>
            <Route
                path="equipos/detalle"
                element={<DetalleEquipo pais={pais}></DetalleEquipo>}
            ></Route>
            <Route
                path="equipos/clientes/asignar"
                element={
                    <EquipoAsignarCliente pais={pais}></EquipoAsignarCliente>
                }
            ></Route>
            <Route
                path="equipos/clientes/desasignar"
                element={
                    <ClientesPorDesasignar pais={pais}></ClientesPorDesasignar>
                }
            ></Route>
            <Route
                path="inventario"
                element={<TabsInventario pais={pais}></TabsInventario>}
            ></Route>
            <Route
                path="inventario/orden-impresion/validar"
                element={
                    <ValidarOrdenImpresion pais={pais}></ValidarOrdenImpresion>
                }
            ></Route>
            <Route
                path="inventario/envio/detalle"
                element={
                    <EnviosClientesFinales pais={pais}></EnviosClientesFinales>
                }
            ></Route>
            <Route
                path="promotor/*"
                element={<PromotorView pais={pais}></PromotorView>}
            ></Route>
            <Route
                path="mantenimiento"
                element={<Mantenimiento></Mantenimiento>}
            ></Route>
            {/* <Route
                path="validar-clientes-jotform"
                element={
                    <ValidarClienteCampanaPreview
                        pais={pais}
                    ></ValidarClienteCampanaPreview>
                }
            ></Route> */}
            {/* <Route
                path="validar-clientes-jotform/detalle"
                element={
                    <ValidarClienteJotform pais={pais}></ValidarClienteJotform>
                }
            ></Route> */}
        </Routes>
    );
};

export default AppViews;
