import { styled } from '@mui/material';
import { PickersDay } from '@mui/x-date-pickers';
import dayjs from 'dayjs';
import isBetweenPlugin from 'dayjs/plugin/isBetween';

dayjs.extend(isBetweenPlugin);

const CustomPickersDay = styled(PickersDay, {
    shouldForwardProp: (prop) => prop !== 'isSelected' && prop !== 'isHovered',
})(({ theme, isSelected, isHovered, day }) => {
    return {
        borderRadius: 0,
        ...(isSelected && {
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.primary.contrastText,
            '&:hover, &:focus': {
                backgroundColor: theme.palette.primary.main,
            },
        }),
        ...(isHovered && {
            backgroundColor: theme.palette.primary.light,
            '&:hover, &:focus': {
                backgroundColor: theme.palette.primary.light,
            },
            /* ...theme?.applyStyles("dark", {
      backgroundColor: theme.palette.primary.dark,
      "&:hover, &:focus": {
        backgroundColor: theme.palette.primary.dark,
      },
    }), */
        }),
        //En locale 'es' Lunes es 1 y Domingo es 0 Se debe cambiar si se cambia la BD de ubicación
        ...(day.day() === 0 && {
            borderTopLeftRadius: '50%',
            borderBottomLeftRadius: '50%',
        }),
        ...(day.day() === 6 && {
            borderTopRightRadius: '50%',
            borderBottomRightRadius: '50%',
        }),
    };
});

const isInSameWeek = (dayA, dayB) => {
    if (dayB == null) {
        return false;
    }

    return dayA.isSame(dayB, 'week');
};

export function Day(props) {
    const { day, selectedDay, hoveredDay, ...other } = props;

    return (
        <CustomPickersDay
            {...other}
            day={day}
            sx={{ px: 2.5 }}
            disableMargin
            selected={false}
            isSelected={isInSameWeek(day, selectedDay)}
            isHovered={isInSameWeek(day, hoveredDay)}
        />
    );
}
