import {
    Box,
    Card,
    Grid,
    TextField,
    Typography,
    Autocomplete,
    Checkbox,
} from '@mui/material';

import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';

const style = {
    p: 0,
    width: '98%',
    textAlign: 'left',
    marginLeft: '20px',
};

const ListHeader = ({
    page,
    setPage,
    setSearchNombre,
    departamentos,
    departamentoSelected,
    setDepartamentoSelected,
    ciudades,
    setCiudadSelected,
    divisiones,
    setDivisionSelected,
    anos,
    setAnoSelected,
    canales,
    setCanalSelected,
    sectores,
    setSectorSelected,
    setVigente,
    pais,
}) => {
    const vigencias = [
        { nombre: 'Vigente', value: true },
        { nombre: 'No Vigente', value: false },
    ];

    const handleChangeNombre = (e) => {
        setTimeout(() => {
            setSearchNombre(e.target.value);
            verifyPage();
        }, 2000);
    };

    const handleDepartamentoSelected = (e, newValue) => {
        const departamentosSelected = [];
        for (const value of newValue) {
            departamentosSelected.push(value.id);
        }
        setDepartamentoSelected(departamentosSelected);
        setCiudadSelected([]);
        verifyPage();
    };

    const handleCiudadSelected = (e, newValue) => {
        const ciudadesSelected = [];
        for (const value of newValue) {
            ciudadesSelected.push(value.id);
        }
        setCiudadSelected(ciudadesSelected);
        verifyPage();
    };

    const handleDivisionSelected = (e, newValue) => {
        const divisionesSelected = [];
        for (const value of newValue) {
            divisionesSelected.push(value.id);
        }
        setDivisionSelected(divisionesSelected);
        verifyPage();
    };

    const handleAnoSelected = (e, newValue) => {
        const anosSelected = [];
        for (const value of newValue) {
            anosSelected.push(value.ano);
        }
        setAnoSelected(anosSelected);
        verifyPage();
    };

    const handleCanalSelected = (e, newValue) => {
        const canalesSelected = [];
        for (const value of newValue) {
            canalesSelected.push(value.canal);
        }
        setCanalSelected(canalesSelected);
        verifyPage();
    };

    const handleSectorSelected = (e, newValue) => {
        const sectoresSelected = [];
        for (const value of newValue) {
            sectoresSelected.push(value.id);
        }
        setSectorSelected(sectoresSelected);
        verifyPage();
    };

    const handleChangeVigente = (e, newValue) => {
        setVigente(newValue ? newValue.value : '');
    };

    const verifyPage = () => {
        if (page > 0) {
            setPage(0);
        }
    };

    return (
        <Box sx={style} component="form">
            <Grid container pt={1}>
                <Grid item xs={12} md={11}>
                    <Card
                        variant="outlined"
                        sx={{
                            backgroundColor: '#D9D9D9',
                        }}
                    >
                        <Grid
                            container
                            py={2}
                            px={2}
                            alignItems="flex-start"
                            justifyContent="space-between"
                        >
                            <Grid item xs={12} md={12}>
                                <Typography color="primary" variant="h6">
                                    Filtros
                                </Typography>
                            </Grid>
                            <Grid item xs={2} md="auto">
                                <TextField
                                    label="Nombre de la Campaña"
                                    size="small"
                                    variant="standard"
                                    onChange={handleChangeNombre}
                                ></TextField>
                            </Grid>
                            <Grid item sm={12} md={1.5}>
                                <Autocomplete
                                    multiple
                                    disableCloseOnSelect
                                    disablePortal
                                    limitTags={1}
                                    options={departamentos}
                                    getOptionLabel={(option) => option.nombre}
                                    onChange={handleDepartamentoSelected}
                                    size="small"
                                    renderOption={(
                                        props,
                                        option,
                                        { selected },
                                    ) => (
                                        <li {...props} key={option.id}>
                                            <Checkbox
                                                sx={{
                                                    ml: -1,
                                                }}
                                                icon={
                                                    <CheckBoxOutlineBlankIcon fontSize="small" />
                                                }
                                                checkedIcon={
                                                    <CheckBoxIcon fontSize="small" />
                                                }
                                                checked={selected}
                                            />
                                            {option.nombre}
                                        </li>
                                    )}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            placeholder=""
                                            variant="standard"
                                            label={
                                                pais === 'colombia'
                                                    ? 'Departamento'
                                                    : 'Provincia'
                                            }
                                        />
                                    )}
                                    ListboxProps={{
                                        sx: {
                                            maxHeight: 200,
                                            overflow: 'auto',
                                        },
                                    }}
                                ></Autocomplete>
                            </Grid>
                            {departamentoSelected.length > 0 && (
                                <Grid item sm={12} md="auto">
                                    <Autocomplete
                                        multiple
                                        disableCloseOnSelect
                                        disablePortal
                                        limitTags={1}
                                        options={ciudades}
                                        getOptionLabel={(option) =>
                                            option.nombre
                                        }
                                        onChange={handleCiudadSelected}
                                        size="small"
                                        renderOption={(
                                            props,
                                            option,
                                            { selected },
                                        ) => (
                                            <li {...props} key={option.id}>
                                                <Checkbox
                                                    sx={{
                                                        ml: -1,
                                                    }}
                                                    icon={
                                                        <CheckBoxOutlineBlankIcon fontSize="small" />
                                                    }
                                                    checkedIcon={
                                                        <CheckBoxIcon fontSize="small" />
                                                    }
                                                    checked={selected}
                                                />
                                                {option.nombre}
                                            </li>
                                        )}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                variant="standard"
                                                label="Ciudad"
                                            />
                                        )}
                                        ListboxProps={{
                                            sx: {
                                                maxHeight: 200,
                                                overflow: 'auto',
                                            },
                                        }}
                                        sx={{
                                            minWidth: 170,
                                            maxWidth: 170,
                                        }}
                                    ></Autocomplete>
                                </Grid>
                            )}
                            <Grid item sm={12} md="auto">
                                <Autocomplete
                                    multiple
                                    disableCloseOnSelect
                                    disablePortal
                                    limitTags={1}
                                    options={divisiones}
                                    getOptionLabel={(option) => option.nombre}
                                    onChange={handleDivisionSelected}
                                    size="small"
                                    renderOption={(
                                        props,
                                        option,
                                        { selected },
                                    ) => (
                                        <li {...props} key={option.id}>
                                            <Checkbox
                                                sx={{
                                                    ml: -1,
                                                }}
                                                icon={
                                                    <CheckBoxOutlineBlankIcon fontSize="small" />
                                                }
                                                checkedIcon={
                                                    <CheckBoxIcon fontSize="small" />
                                                }
                                                checked={selected}
                                            />
                                            {option.nombre}
                                        </li>
                                    )}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            variant="standard"
                                            label="Division"
                                        />
                                    )}
                                    ListboxProps={{
                                        sx: {
                                            maxHeight: 200,
                                            overflow: 'auto',
                                        },
                                    }}
                                    sx={{
                                        minWidth: 150,
                                        maxWidth: 150,
                                    }}
                                ></Autocomplete>
                            </Grid>
                            <Grid item sm={12} md="auto">
                                <Autocomplete
                                    multiple
                                    disableCloseOnSelect
                                    disablePortal
                                    limitTags={1}
                                    options={anos}
                                    getOptionLabel={(option) => option.ano}
                                    onChange={handleAnoSelected}
                                    size="small"
                                    renderOption={(
                                        props,
                                        option,
                                        { selected },
                                    ) => (
                                        <li {...props} key={option.id}>
                                            <Checkbox
                                                sx={{
                                                    ml: -1,
                                                }}
                                                icon={
                                                    <CheckBoxOutlineBlankIcon fontSize="small" />
                                                }
                                                checkedIcon={
                                                    <CheckBoxIcon fontSize="small" />
                                                }
                                                checked={selected}
                                            />
                                            {option.ano}
                                        </li>
                                    )}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            variant="standard"
                                            label="Año"
                                        />
                                    )}
                                    ListboxProps={{
                                        sx: {
                                            maxHeight: 200,
                                        },
                                    }}
                                    sx={{
                                        minWidth: 120,
                                        maxWidth: 140,
                                    }}
                                ></Autocomplete>
                            </Grid>
                            <Grid item sm={12} md="auto">
                                <Autocomplete
                                    multiple
                                    disableCloseOnSelect
                                    disablePortal
                                    limitTags={1}
                                    options={canales}
                                    getOptionLabel={(option) => option.canal}
                                    onChange={handleCanalSelected}
                                    size="small"
                                    renderOption={(
                                        props,
                                        option,
                                        { selected },
                                    ) => (
                                        <li {...props} key={option.id}>
                                            <Checkbox
                                                sx={{
                                                    ml: -1,
                                                }}
                                                icon={
                                                    <CheckBoxOutlineBlankIcon fontSize="small" />
                                                }
                                                checkedIcon={
                                                    <CheckBoxIcon fontSize="small" />
                                                }
                                                checked={selected}
                                            />
                                            {option.canal}
                                        </li>
                                    )}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            variant="standard"
                                            label="Canal"
                                        />
                                    )}
                                    ListboxProps={{
                                        sx: {
                                            maxHeight: 200,
                                            overflow: 'auto',
                                        },
                                    }}
                                    sx={{
                                        minWidth: 120,
                                        maxWidth: 140,
                                    }}
                                ></Autocomplete>
                            </Grid>
                            <Grid item sm={12} md="auto">
                                <Autocomplete
                                    multiple
                                    disableCloseOnSelect
                                    disablePortal
                                    limitTags={1}
                                    options={sectores}
                                    getOptionLabel={(option) => option.sector}
                                    onChange={handleSectorSelected}
                                    size="small"
                                    renderOption={(
                                        props,
                                        option,
                                        { selected },
                                    ) => (
                                        <li {...props} key={option.id}>
                                            <Checkbox
                                                sx={{
                                                    ml: -1,
                                                }}
                                                icon={
                                                    <CheckBoxOutlineBlankIcon fontSize="small" />
                                                }
                                                checkedIcon={
                                                    <CheckBoxIcon fontSize="small" />
                                                }
                                                checked={selected}
                                            />
                                            {option.sector}
                                        </li>
                                    )}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            variant="standard"
                                            label="Sector"
                                        />
                                    )}
                                    ListboxProps={{
                                        sx: {
                                            maxHeight: 200,
                                        },
                                    }}
                                    sx={{
                                        minWidth: 180,
                                        maxWidth: 180,
                                    }}
                                ></Autocomplete>
                            </Grid>
                            <Grid item sm={12} md="auto">
                                <Autocomplete
                                    limitTags={1}
                                    options={vigencias}
                                    getOptionLabel={(option) => option.nombre}
                                    onChange={handleChangeVigente}
                                    size="small"
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            variant="standard"
                                            label="Vigencia"
                                        />
                                    )}
                                    ListboxProps={{
                                        sx: {
                                            maxHeight: 200,
                                            overflow: 'auto',
                                        },
                                    }}
                                    sx={{
                                        minWidth: 120,
                                        maxWidth: 120,
                                    }}
                                ></Autocomplete>
                            </Grid>
                        </Grid>
                    </Card>
                </Grid>
            </Grid>
        </Box>
    );
};

export default ListHeader;
