import { Box, CircularProgress, Typography } from '@mui/material';
import PropTypes from 'prop-types';

export const CircularProgressWithLabel = (props) => {
    return (
        <Box sx={{ position: 'relative', display: 'inline-flex' }}>
            <CircularProgress
                variant="determinate"
                value={100}
                size={props.circleSize}
                thickness={props.cirlceThickness}
                color="primaryShadow"
                sx={{ position: 'absolute', left: 0 }}
            />
            <CircularProgress
                variant="determinate"
                size={props.circleSize}
                thickness={props.cirlceThickness}
                color="primary"
                {...props}
            />
            <Box
                sx={{
                    top: 0,
                    left: 0,
                    bottom: 0,
                    right: 0,
                    position: 'absolute',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                <Typography variant="h6" component="div" color="text.secondary">
                    {`${props.value?.toFixed(2)}%`}
                </Typography>
            </Box>
        </Box>
    );
};

CircularProgressWithLabel.propTypes = {
    /**
     * The value of the progress indicator for the determinate variant.
     * Value between 0 and 100.
     * @default 0
     */
    value: PropTypes.number.isRequired,
    circleSize: PropTypes.number,
    cirlceThickness: PropTypes.number,
};
