import { Box, Card, Grid, Typography } from '@mui/material';
import { CircularProgressWithLabel } from '../../../../components/layout-components/CircularProgressWithLabel';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { sendRequest } from '../../../../services/request';

const style = {
    p: 0,
    width: '98%',
    textAlign: 'left',
    marginLeft: '20px',
};

const MisEstadisticas = ({ pais }) => {
    const URL = process.env.REACT_APP_API_URL;
    const usuario = useSelector((state) => state.auth.usuario);

    const [rendimiento, setRendimiento] = useState(0);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const res = await sendRequest(
                    'get',
                    `${URL}/${pais}/clientes-asignados/promotor/${usuario?.id}/rendimiento`,
                );
                setRendimiento(res.data?.rendimiento);
            } catch (error) {
                console.log(error);
            }
        };
        fetchData();
    }, [URL, pais, usuario?.id]);

    return (
        <Box sx={style} component="form">
            <Grid container spacing={5} py={3} pr={3}>
                <Grid item xs={12} md={8}>
                    <Typography
                        variant="h4"
                        color="primary"
                        sx={{
                            textAlign: 'start',
                            marginLeft: '10px',
                        }}
                    >
                        Mis Estadisticas
                    </Typography>
                </Grid>
            </Grid>
            <Grid container pt={1} pl={2}>
                <Grid item xs={4} md={2.5} lg={2}>
                    <Card elevation={6}>
                        <Grid container py={2} px={2} alignItems="flex-start">
                            <Grid item xs={12} md={12}>
                                <Typography color="primary" variant="h6">
                                    Rendimiento
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid
                            container
                            pb={2}
                            alignItems="center"
                            justifyContent="center"
                        >
                            <Grid item xs={7.5} md={7.5}>
                                <CircularProgressWithLabel
                                    value={rendimiento || 0}
                                    circleSize={150}
                                    cirlceThickness={6}
                                ></CircularProgressWithLabel>
                            </Grid>
                        </Grid>
                    </Card>
                </Grid>
            </Grid>
        </Box>
    );
};

export default MisEstadisticas;
