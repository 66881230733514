import {
    Box,
    Button,
    Chip,
    Grid,
    IconButton,
    Modal,
    Typography,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { sendRequest } from '../../../../services/request';
import { AÑOS, CANALES, thousandFormatter } from '../../../../utils/utils';
import ListHeader from '../../campaña/consulta/listHeader';
import NuevaCampana from '../nueva_campana';
import EditarCampana from '../editar';
import { useNavigate } from 'react-router-dom';
import { GroupAdd, GroupRemove, Edit, Shortcut } from '@mui/icons-material';
import { DataGrid, GridToolbar, esES } from '@mui/x-data-grid';
import CustomFooter from './customFooter';
import RolesGate from '../../../../components/utils-components/permissions/rolesGate';
import { ROLES } from '../../../../components/utils-components/permissions/permissions-map';
import dayjs from 'dayjs';
import { DataGridStyle } from '../../../../styles/DataGridSytle';

const ConsultaCampana = ({ pais }) => {
    const [campanas, setCampanas] = useState([]);
    const [count, setCount] = useState(0);
    const [limit, setLimit] = useState(10);
    const [page, setPage] = useState(0);
    const [divisiones, setDivisiones] = useState([]);
    const [departamentos, setDepartamentos] = useState([]);
    const [ciudades, setCiudades] = useState([]);
    const [sectores, setSectores] = useState([]);
    const [campanaRow, setCampanaRow] = useState(null);
    const [total, setTotal] = useState(0);

    // Campos Filtrar
    const [searchNombre, setSearchNombre] = useState('');
    const [departamentoSelected, setDepartamentoSelected] = useState([]);
    const [ciudadSelected, setCiudadSelected] = useState([]);
    const [divisionSelected, setDivisionSelected] = useState([]);
    const [anoSelected, setAnoSelected] = useState([]);
    const [canalSelected, setCanalSelected] = useState([]);
    const [sectorSelected, setSectorSelected] = useState([]);
    const [vigente, setVigente] = useState('');

    // Modal
    const [open, setOpen] = useState(false);
    const [openNuevaCampana, setOpenNuevaCampana] = useState(false);
    const [openEditCampana, setOpenEditCampana] = useState(false);

    // Condicionales
    const [loading, setLoading] = useState(false);
    const [recargar, setRecargar] = useState(false);

    const URL = process.env.REACT_APP_API_URL;
    const navigate = useNavigate();

    useEffect(() => {
        const fetchData = async () => {
            try {
                setLoading(true);
                const res = await sendRequest(
                    'get',
                    `${URL}/${pais}/campana/paginated?limit=${limit}&page=${page}&searchNombre=${searchNombre}&searchCiudad=${
                        ciudadSelected === 'ciudad' ? '' : ciudadSelected
                    }&searchDivision=${
                        divisionSelected === 'division' ? '' : divisionSelected
                    }&searchAno=${
                        anoSelected === 'ano' ? '' : anoSelected
                    }&searchEstrategia=${
                        canalSelected === 'canal' ? '' : canalSelected
                    }&searchSector=${
                        sectorSelected === 'sector' ? '' : sectorSelected
                    }&vigente=${vigente === 'vigencia' ? '' : vigente}`,
                );
                setLoading(false);
                // Esto es temporal
                /* const campañasConDatos = res.data.items.filter(
                    (item) => item.tarjetones_count > 0,
                );
                setCampanas(campañasConDatos); */
                setCampanas(res.data.items);
                setCount(res.data.meta.totalItems);
            } catch (error) {
                console.log(error);
            }
        };
        fetchData();
    }, [
        URL,
        pais,
        limit,
        page,
        searchNombre,
        ciudadSelected,
        divisionSelected,
        anoSelected,
        canalSelected,
        sectorSelected,
        recargar,
        vigente,
    ]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                setLoading(true);
                const res = await sendRequest(
                    'get',
                    `${URL}/${pais}/campana/clientes/count?searchNombre=${searchNombre}&searchCiudad=${
                        ciudadSelected === 'ciudad' ? '' : ciudadSelected
                    }&searchDivision=${
                        divisionSelected === 'division' ? '' : divisionSelected
                    }&searchAno=${
                        anoSelected === 'ano' ? '' : anoSelected
                    }&searchEstrategia=${
                        canalSelected === 'canal' ? '' : canalSelected
                    }&searchSector=${
                        sectorSelected === 'sector' ? '' : sectorSelected
                    }&vigente=${vigente === 'vigencia' ? '' : vigente}`,
                );
                setLoading(false);
                setTotal(res.data.count);
            } catch (error) {
                console.log(error);
            }
        };
        fetchData();
    }, [
        URL,
        pais,
        searchNombre,
        ciudadSelected,
        divisionSelected,
        anoSelected,
        canalSelected,
        sectorSelected,
        recargar,
        vigente,
    ]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const resDiviciones = await sendRequest(
                    'get',
                    `${URL}/${pais}/division`,
                );
                const resSectores = await sendRequest(
                    'get',
                    `${URL}/${pais}/sector`,
                );
                const resDepartamentos = await sendRequest(
                    'get',
                    `${URL}/${pais}/departamentos`,
                );

                setDivisiones(resDiviciones.data);
                setSectores(resSectores.data);
                setDepartamentos(resDepartamentos.data);
            } catch (error) {
                console.log(error);
            }
        };
        fetchData();
    }, [URL, pais]);

    useEffect(() => {
        if (departamentoSelected) {
            const fetchData = async () => {
                try {
                    const res = await sendRequest(
                        'get',
                        `${URL}/${pais}/ciudades?departamento=${departamentoSelected}`,
                    );
                    setCiudades(res.data);
                } catch (error) {
                    console.log(error);
                }
            };
            fetchData();
        }
    }, [URL, pais, departamentoSelected]);

    const columns = [
        /* {
            field: 'id',
            headerName: 'ID',
            headerClassName: 'header',
            sortable: false,
            flex: 0.5,
        }, */
        {
            field: 'nombre',
            headerName: 'Nombre',
            headerClassName: 'header',
            headerAlign: 'center',
            sortable: false,
            flex: 2,
        },
        {
            field: 'ciudad',
            headerName: 'Ciudad',
            headerClassName: 'header',
            headerAlign: 'center',
            sortable: false,
            flex: 1,
            renderCell: ({ row }) => {
                return row.ciudad?.nombre;
            },
        },
        {
            field: 'division',
            headerName: 'Division',
            headerClassName: 'header',
            headerAlign: 'center',
            sortable: false,
            flex: 1,
            renderCell: ({ row }) => {
                return (
                    <Chip
                        label={row.division?.nombre}
                        color={
                            row.division?.id === 1
                                ? 'publicidad'
                                : 'fundraising'
                        }
                    />
                );
            },
        },
        {
            field: 'sector',
            headerName: 'Sector',
            headerClassName: 'header',
            headerAlign: 'center',
            sortable: false,
            flex: 1.1,
            renderCell: ({ row }) => {
                return row.sector?.sector;
                /* return (
                    <Chip
                        label={row.sector?.sector}
                        color={
                            row.sector?.id === 1
                                ? 'secondary'
                                : row.sector?.id === 2
                                ? 'automotriz'
                                : row.sector?.id === 3
                                ? 'entretenimiento'
                                : row.sector?.id === 4
                                ? 'hyt'
                                : row.sector?.id === 5
                                ? 'bienestar'
                                : 'secondary'
                        }
                    />
                ); */
            },
        },
        {
            field: 'estrategia',
            headerName: 'Canal',
            headerClassName: 'header',
            headerAlign: 'center',
            sortable: false,
            flex: 'auto',
            renderCell: ({ row }) => {
                return row.estrategia;
                /* return (
                    <Chip
                        label={row.estrategia}
                        color={
                            row.estrategia && row.estrategia === 'F2F'
                                ? 'f2f'
                                : 'tmk'
                        }
                        style={
                            row.estrategia === 'TMK y F2F'
                                ? {
                                      background:
                                          'linear-gradient(to right, #05F247, #A605F2)',
                                  }
                                : undefined
                        }
                    />
                ); */
            },
        },
        {
            field: 'vigencia',
            headerName: 'Vigencia',
            headerClassName: 'header',
            headerAlign: 'center',
            sortable: false,
            flex: 1,
            renderCell: ({ row }) => {
                return dayjs(row.vigencia).format('DD/MM/YYYY');
            },
        },
        {
            field: 'clientes_validos',
            headerName: 'Total Donantes/Clientes',
            headerClassName: 'header',
            headerAlign: 'center',
            sortable: false,
            flex: 1,
            align: 'center',
            renderCell: ({ row }) => {
                return thousandFormatter.format(row.tarjetones_count);
            },
        },
        {
            field: 'acciones',
            type: 'actions',
            cellClassName: 'actions',
            headerName: 'Acciones',
            headerClassName: 'header',
            headerAlign: 'center',
            flex: 1.8,
            getActions: ({ row }) => {
                return [
                    <RolesGate
                        roles={[
                            ROLES.GERENCIA_EFFECTIVE,
                            ROLES.DIRECTOR_ADMINISTRATIVO,
                        ]}
                    >
                        {dayjs(row.vigencia) > dayjs(new Date()) && (
                            <IconButton
                                color="primary"
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    borderRadius: 0,
                                }}
                                onClick={() => handleEditClick(row)}
                            >
                                <Edit />
                                <Typography>Editar</Typography>
                            </IconButton>
                        )}
                    </RolesGate>,
                    <RolesGate
                        roles={[
                            ROLES.GERENCIA_EFFECTIVE,
                            ROLES.DIRECTOR_ADMINISTRATIVO,
                            ROLES.GERENCIA_WINNER,
                            ROLES.DIRECTOR_OPERATIVO,
                        ]}
                    >
                        {dayjs(row.vigencia) < dayjs(new Date()) && (
                            <IconButton
                                color="primary"
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    borderRadius: 0,
                                }}
                                onClick={() => handleVerBDD(row)}
                            >
                                <Shortcut />
                                <Typography>Ver BD</Typography>
                            </IconButton>
                        )}
                    </RolesGate>,
                    <RolesGate
                        roles={[
                            ROLES.GERENCIA_EFFECTIVE,
                            ROLES.DIRECTOR_ADMINISTRATIVO,
                            /* ROLES.GERENCIA_WINNER, */
                        ]}
                    >
                        {dayjs(row.vigencia) > dayjs(new Date()) && (
                            <>
                                <IconButton
                                    color="text"
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        borderRadius: 0,
                                    }}
                                    onClick={() => handleAsignar(row)}
                                >
                                    <GroupAdd />
                                    <Typography>Asignar</Typography>
                                </IconButton>
                                <RolesGate
                                    roles={[
                                        ROLES.GERENCIA_EFFECTIVE,
                                        /* ROLES.GERENCIA_WINNER, */
                                        ROLES.DIRECTOR_ADMINISTRATIVO,
                                        ROLES.DIRECTOR_OPERATIVO,
                                    ]}
                                >
                                    {row.clientes_asignados?.length > 0 && (
                                        <IconButton
                                            color="text"
                                            sx={{
                                                display: 'flex',
                                                flexDirection: 'column',
                                                borderRadius: 0,
                                            }}
                                            onClick={() =>
                                                handleDesasignar(row)
                                            }
                                        >
                                            <GroupRemove />
                                            <Typography>Desasignar</Typography>
                                        </IconButton>
                                    )}
                                </RolesGate>
                            </>
                        )}
                    </RolesGate>,
                ];
            },
        },
    ];

    const handleVerBDD = (row) => {
        navigate('/app/campana/clientes', { state: { campana: row } });
    };

    const handleAsignar = (row) => {
        navigate('/app/campana/clientes/asignar', {
            state: { campana: row },
        });
    };

    const handleDesasignar = (row) => {
        navigate('/app/campana/clientes/desasignar', {
            state: { campana: row },
        });
    };

    /* const handleExpandClick = (campanaId) => {
        const updatedCampanas = campanas.map((campana) => {
            if (campana.id === campanaId) {
                campana.expanded = !campana.expanded;
            }
            return campana;
        });
        setCampanas(updatedCampanas);
    }; */

    const handleRowsPerPage = (event) => {
        setLimit(event.target.value);
    };

    const handleCreateClick = () => {
        setOpen(!open);
        setOpenNuevaCampana(!openNuevaCampana);
        setOpenEditCampana(false);
    };

    const handleEditClick = (campanaSelected) => {
        setOpen(!open);
        setOpenEditCampana(!openEditCampana);
        setOpenNuevaCampana(false);
        setCampanaRow(campanaSelected);
    };

    const handleClose = () => {
        setOpen(false);
        setOpenNuevaCampana(false);
        setOpenEditCampana(false);
    };

    const handlePaginationChange = (event, page) => {
        setPage(page);
    };

    const handleAgregar = async (newData) => {
        try {
            await sendRequest('post', `${URL}/${pais}/campana`, newData);
            handleClose();
            setRecargar(!recargar);
        } catch (error) {
            console.log(error);
        }
    };

    const handleEditar = async (id, editData) => {
        try {
            await sendRequest('put', `${URL}/${pais}/campana/${id}`, editData);
            handleClose();
            setRecargar(!recargar);
        } catch (error) {
            console.log(error);
        }
    };

    return (
        <>
            <Box sx={{ height: '100%', width: '100%' }}>
                <Grid container spacing={5} py={3} pr={3}>
                    <Grid item xs={12} md={8}>
                        <Typography
                            variant="h4"
                            color="primary"
                            sx={{
                                textAlign: 'start',
                                marginLeft: '10px',
                            }}
                        >
                            Campañas
                        </Typography>
                    </Grid>
                    <RolesGate
                        roles={[
                            ROLES.GERENCIA_EFFECTIVE,
                            ROLES.DIRECTOR_ADMINISTRATIVO,
                        ]}
                    >
                        <Grid item xs={12} md={4}>
                            <Button
                                fullWidth
                                variant="contained"
                                onClick={handleCreateClick}
                            >
                                Crear nueva campaña
                            </Button>
                        </Grid>
                    </RolesGate>
                </Grid>
                <ListHeader
                    page={page}
                    setPage={setPage}
                    setSearchNombre={setSearchNombre}
                    departamentos={departamentos}
                    departamentoSelected={departamentoSelected}
                    setDepartamentoSelected={setDepartamentoSelected}
                    ciudades={ciudades}
                    setCiudadSelected={setCiudadSelected}
                    divisiones={divisiones}
                    setDivisionSelected={setDivisionSelected}
                    anos={AÑOS}
                    setAnoSelected={setAnoSelected}
                    canales={CANALES}
                    setCanalSelected={setCanalSelected}
                    sectores={sectores}
                    setSectorSelected={setSectorSelected}
                    vigente={vigente}
                    setVigente={setVigente}
                    pais={pais}
                ></ListHeader>
                <DataGrid
                    localeText={
                        esES.components.MuiDataGrid.defaultProps.localeText
                    }
                    disableColumnSelector
                    disableDensitySelector
                    disableColumnFilter
                    disableColumnMenu
                    loading={loading}
                    initialState={{
                        pagination: {
                            paginationModel: { pageSize: limit },
                        },
                    }}
                    rows={campanas ? campanas : []}
                    columns={columns}
                    paginationMode="server"
                    rowCount={count ? count : 0}
                    onPaginationModelChange={handlePaginationChange}
                    slots={{
                        toolbar: GridToolbar,
                        footer: CustomFooter,
                    }}
                    slotProps={{
                        toolbar: {
                            showQuickFilter: false,
                            // Estos dos quitan el export
                            printOptions: { disableToolbarButton: true },
                            csvOptions: { disableToolbarButton: true },
                        },
                        footer: {
                            limit,
                            page,
                            count: count,
                            handlePaginationChange,
                            total,
                            handleRowsPerPage,
                        },
                    }}
                    sx={DataGridStyle}
                ></DataGrid>
            </Box>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <div>
                    {openNuevaCampana && (
                        <NuevaCampana
                            deprtamentos={departamentos}
                            ciudades={ciudades}
                            divisiones={divisiones}
                            canales={CANALES}
                            sectores={sectores}
                            pais={pais}
                            handleClose={handleClose}
                            handleAgregar={handleAgregar}
                        ></NuevaCampana>
                    )}
                    {openEditCampana && (
                        <EditarCampana
                            campanaRow={campanaRow}
                            divisiones={divisiones}
                            canales={CANALES}
                            sectores={sectores}
                            pais={pais}
                            handleClose={handleClose}
                            handleEditar={handleEditar}
                        ></EditarCampana>
                    )}
                </div>
            </Modal>
        </>
    );
};

export default ConsultaCampana;
