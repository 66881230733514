import {
    Autocomplete,
    Box,
    Button,
    Grid,
    TextField,
    Typography,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { sendRequest } from '../../../../../services/request';
import dayjs from 'dayjs';
import WeekPicker from '../../../../../components/utils-components/WeekPicker/WeekPicker';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '40%',
    bgcolor: 'background.paper',
    overflowY: 'auto',
    boxShadow: 24,
    px: 4,
};

const styleModalHeader = {
    top: 0,
    textAlign: 'center',
    position: 'sticky',
    zIndex: 15,
    py: 2,
    /* borderBottom: 'solid #0B3E83 1px', */
    backgroundColor: '#ffffff',
};

const styleModalFooter = {
    bottom: 0,
    top: 0,
    position: 'sticky',
    mt: 10,
    pb: 3,
    backgroundColor: '#ffffff',
    zIndex: 15,
};

const tiposReporte = [
    { id: 1, nombre: 'Campaña' },
    { id: 2, nombre: 'Promotor' },
];

const ModalColocacion = ({
    URL,
    pais,
    divisiones,
    handleClose,
    handleContinuar,
    fecha,
    setFecha,
    hoveredDay,
    setHoveredDay,
}) => {
    const semana = dayjs(new Date()).week();
    const [tipo, setTipo] = useState(null);
    const [division, setDivision] = useState(null);
    const [campana, setCampana] = useState(null);
    const [campanas, setCampanas] = useState(null);

    useEffect(() => {
        if (division) {
            const fetchCampanasDivision = async () => {
                try {
                    const res = await sendRequest(
                        'get',
                        `${URL}/${pais}/campana?searchDivision=${division.id}`,
                    );
                    setCampanas(res.data);
                } catch (error) {
                    console.log(error);
                }
            };
            fetchCampanasDivision();
        }
    }, [URL, pais, division]);

    const handleChangeTipoReporte = (event, newValue) => {
        setTipo(newValue);
    };

    const handleChangeDivisiones = (event, newValue) => {
        setDivision(newValue);
    };

    const handleChangeCampana = (event, newValue) => {
        setCampana(newValue);
    };

    return (
        <Box sx={style}>
            <form>
                <Box sx={styleModalHeader}>
                    <Typography
                        id="modal-modal-title"
                        variant="h5"
                        color="primary"
                    >
                        Pre-reporte Cierre Colocación Semanal
                    </Typography>
                    <Typography
                        id="modal-modal-title"
                        variant="p"
                        color="primary"
                    >
                        Semana Actual - {semana}
                    </Typography>
                </Box>
                <Grid container spacing={2} sx={{ mt: '2px' }}>
                    <Grid item xs={12} md={6}>
                        <Autocomplete
                            disablePortal
                            fullWidth
                            value={tipo}
                            options={tiposReporte}
                            getOptionLabel={(option) => option.nombre}
                            onChange={handleChangeTipoReporte}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="Tipo Reporte"
                                    placeholder=""
                                    variant="standard"
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <WeekPicker
                            value={fecha}
                            setValue={setFecha}
                            hoveredDay={hoveredDay}
                            setHoveredDay={setHoveredDay}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Autocomplete
                            disablePortal
                            fullWidth
                            value={division}
                            options={divisiones}
                            getOptionLabel={(option) => option.nombre}
                            onChange={handleChangeDivisiones}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="Divisiones"
                                    placeholder=""
                                    variant="standard"
                                />
                            )}
                        />
                    </Grid>
                    {campanas && campanas.length > 0 && tipo.id === 2 && (
                        <Grid item xs={12} md={6}>
                            <Autocomplete
                                disablePortal
                                fullWidth
                                value={campana}
                                options={campanas}
                                getOptionLabel={(option) => option.nombre}
                                onChange={handleChangeCampana}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="Campañas Vigentes"
                                        placeholder=""
                                        variant="standard"
                                    />
                                )}
                            ></Autocomplete>
                        </Grid>
                    )}
                </Grid>
                <Grid container spacing={5} sx={styleModalFooter}>
                    <Grid item xs={12} md={6}>
                        <Button
                            fullWidth
                            variant="outlined"
                            onClick={handleClose}
                        >
                            Cerrar
                        </Button>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Button
                            fullWidth
                            variant="contained"
                            disabled={campanas?.length <= 0 || false}
                            onClick={() =>
                                handleContinuar('colocacion_semanal', {
                                    tipo,
                                    fecha,
                                    division,
                                    campana,
                                })
                            }
                        >
                            Continuar
                        </Button>
                    </Grid>
                </Grid>
            </form>
        </Box>
    );
};

export default ModalColocacion;
