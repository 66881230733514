import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTheme } from '@emotion/react';
import ColombiaFlag from '../../images/colombia-flag.png';
import PanamaFlag from '../../images/panama-flag.png';
import {
    ChangeCircle,
    LogoutOutlined,
    Menu as MenuIcon,
    Settings,
} from '@mui/icons-material';
import { Menu, MenuItem } from '@mui/material';
import RolesGate from '../utils-components/permissions/rolesGate';
import { useDispatch, useSelector } from 'react-redux';
import { signOutSuccess } from '../../redux/actions/auth';
import navigationConfig from '../../configs/NavigationConfig';
import { ROLES } from '../utils-components/permissions/permissions-map';

function HeaderNav({ pais, setPais }) {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const theme = useTheme();
    const location = useLocation();
    const usuario = useSelector((state) => state.auth.usuario);
    const role = useSelector((state) => state.auth.role);
    const pages = role
        ? navigationConfig.filter((item) => item.roles.includes(role))
        : [];

    const [isMantenimientoView, setIsMantenimientoView] = React.useState(false);

    const [bgColors, setBgColors] = React.useState({
        dashboardDirector:
            location.pathname === '/app/dashboard'
                ? '#062248'
                : theme.palette.primary,
        clientes:
            location.pathname === '/app/clientes'
                ? '#062248'
                : theme.palette.primary,
        campañas:
            location.pathname === '/app/campanas'
                ? '#062248'
                : theme.palette.primary,
        equipos:
            location.pathname === '/app/equipos'
                ? '#062248'
                : theme.palette.primary,
        inventarios:
            location.pathname === '/app/inventario'
                ? '#062248'
                : theme.palette.primary,
        validarJotform:
            location.pathname === '/app/validar-clientes-jotform'
                ? '#062248'
                : theme.palette.primary,
        misEstadisticas:
            location.pathname === '/app/promotor/mis-estadisticas'
                ? '#062248'
                : theme.palette.primary,
        miBD:
            location.pathname === '/app/promotor/mi-bd'
                ? '#062248'
                : theme.palette.primary,
        agenda:
            location.pathname === '/app/promotor/agenda'
                ? '#062248'
                : theme.palette.primary,
    });

    const [anchorElNav, setAnchorElNav] = React.useState(null);

    const handleClickMenu = (event) => {
        setIsMantenimientoView(false);
        if (event.target.innerText === 'Dashboard') {
            setBgColors({
                campañas: theme.palette.primary,
                clientes: theme.palette.primary,
                dashboardDirector: '#062248',
                equipos: theme.palette.primary,
                inventarios: theme.palette.primary,
                validarJotform: theme.palette.primary,
                misEstadisticas: theme.palette.primary,
                miBD: theme.palette.primary,
                agenda: theme.palette.primary,
            });
            navigate('/app/dashboard');
        } else if (event.target.innerText === 'Clientes') {
            setBgColors({
                campañas: theme.palette.primary,
                clientes: '#062248',
                dashboardDirector: theme.palette.primary,
                equipos: theme.palette.primary,
                inventarios: theme.palette.primary,
                validarJotform: theme.palette.primary,
                misEstadisticas: theme.palette.primary,
                miBD: theme.palette.primary,
                agenda: theme.palette.primary,
            });
            navigate('/app/clientes');
        } else if (event.target.innerText === 'Campañas') {
            setBgColors({
                ...bgColors,
                campañas: '#062248',
                clientes: theme.palette.primary,
                dashboardDirector: theme.palette.primary,
                equipos: theme.palette.primary,
                inventarios: theme.palette.primary,
                validarJotform: theme.palette.primary,
                misEstadisticas: theme.palette.primary,
                miBD: theme.palette.primary,
                agenda: theme.palette.primary,
            });
            navigate('/app/campanas');
        } else if (event.target.innerText === 'Equipos') {
            setBgColors({
                ...bgColors,
                clientes: theme.palette.primary,
                campañas: theme.palette.primary,
                dashboardDirector: theme.palette.primary,
                equipos: '#062248',
                inventarios: theme.palette.primary,
                validarJotform: theme.palette.primary,
                misEstadisticas: theme.palette.primary,
                miBD: theme.palette.primary,
                agenda: theme.palette.primary,
            });
            navigate('/app/equipos');
        } else if (event.target.innerText === 'Inventario') {
            setBgColors({
                ...bgColors,
                clientes: theme.palette.primary,
                campañas: theme.palette.primary,
                dashboardDirector: theme.palette.primary,
                equipos: theme.palette.primary,
                inventarios: '#062248',
                validarJotform: theme.palette.primary,
                misEstadisticas: theme.palette.primary,
                miBD: theme.palette.primary,
                agenda: theme.palette.primary,
            });
            navigate('/app/inventario');
        } else if (event.target.innerText === 'Validar BD Jotform') {
            setBgColors({
                clientes: theme.palette.primary,
                campañas: theme.palette.primary,
                dashboardDirector: theme.palette.primary,
                equipos: theme.palette.primary,
                inventarios: theme.palette.primary,
                validarJotform: '#062248',
                misEstadisticas: theme.palette.primary,
                miBD: theme.palette.primary,
                agenda: theme.palette.primary,
            });
            navigate('/app/validar-clientes-jotform');
        } else if (event.target.innerText === 'Mis Estadísticas') {
            setBgColors({
                clientes: theme.palette.primary,
                campañas: theme.palette.primary,
                dashboardDirector: theme.palette.primary,
                equipos: theme.palette.primary,
                inventarios: theme.palette.primary,
                validarJotform: theme.palette.primary,
                misEstadisticas: '#062248',
                miBD: theme.palette.primary,
                agenda: theme.palette.primary,
            });
            navigate('/app/promotor/mis-estadisticas');
        } else if (event.target.innerText === 'Mi BD') {
            setBgColors({
                clientes: theme.palette.primary,
                campañas: theme.palette.primary,
                dashboardDirector: theme.palette.primary,
                equipos: theme.palette.primary,
                inventarios: theme.palette.primary,
                validarJotform: theme.palette.primary,
                misEstadisticas: theme.palette.primary,
                miBD: '#062248',
                agenda: theme.palette.primary,
            });
            navigate('/app/promotor/preview');
        } else if (event.target.innerText === 'Agenda') {
            setBgColors({
                clientes: theme.palette.primary,
                campañas: theme.palette.primary,
                dashboardDirector: theme.palette.primary,
                equipos: theme.palette.primary,
                inventarios: theme.palette.primary,
                validarJotform: theme.palette.primary,
                misEstadisticas: theme.palette.primary,
                miBD: theme.palette.primary,
                agenda: '#062248',
            });
            navigate('/app/promotor/agenda');
        } else {
            setBgColors({
                clientes: theme.palette.primary,
                campañas: theme.palette.primary,
                dashboardDirector: theme.palette.primary,
                equipos: theme.palette.primary,
                inventarios: theme.palette.primary,
                validarJotform: theme.palette.primary,
                misEstadisticas: theme.palette.primary,
                miBD: theme.palette.primary,
                agenda: theme.palette.primary,
            });
            navigate('/app/');
        }
        if (anchorElNav) {
            handleCloseNavMenu();
        }
    };

    const handleChangePais = () => {
        if (pais === 'colombia') {
            setPais('panama');
        } else {
            setPais('colombia');
        }
    };

    /* const handleChangeRol = () => {
        navigate('/app/promotor');
        setBgColors({
            agenda: theme.palette.primary,
            campañas: theme.palette.primary,
            clientes: theme.palette.primary,
            dashboardDirector: theme.palette.primary,
            equipos: theme.palette.primary,
            inventarios: theme.palette.primary,
            miBD: theme.palette.primary,
        });
    }; */

    const handleOpenProfile = () => {
        console.log('Ver Perfil');
    };

    const handleLogOut = () => {
        dispatch(signOutSuccess());
        navigate('/auth/login');
    };

    const handleMantenimiento = () => {
        setBgColors({
            agenda: theme.palette.primary,
            campañas: theme.palette.primary,
            clientes: theme.palette.primary,
            dashboardDirector: theme.palette.primary,
            equipos: theme.palette.primary,
            inventarios: theme.palette.primary,
            miBD: theme.palette.primary,
        });
        setIsMantenimientoView(true);
        navigate('/app/mantenimiento');
    };

    const handleOpenNavMenu = (event) => {
        setAnchorElNav(event.currentTarget);
    };

    const handleCloseNavMenu = () => {
        setAnchorElNav(null);
    };

    const stringToColor = (string) => {
        let hash = 0;
        let i;

        for (i = 0; i < string.length; i += 1) {
            hash = string.charCodeAt(i) + ((hash << 5) - hash);
        }

        let color = '#';

        for (i = 0; i < 3; i += 1) {
            const value = (hash >> (i * 8)) & 0xff;
            color += `00${value.toString(16)}`.slice(-2);
        }

        return color;
    };

    const stringAvatar = (name) => {
        return {
            sx: {
                bgcolor: stringToColor(name),
            },
            children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`,
        };
    };

    return (
        <AppBar position="static">
            <Container maxWidth="xl">
                <Toolbar disableGutters>
                    <Box
                        sx={{
                            flexGrow: 1,
                            display: { xs: 'none', md: 'flex' },
                        }}
                    >
                        {pages.map((page) => (
                            <Button
                                key={page.key}
                                onClick={handleClickMenu}
                                sx={{
                                    py: 4.8,
                                    color: 'white',
                                    display: 'block',
                                    backgroundColor:
                                        page.key === 'Dashboard'
                                            ? bgColors.dashboardDirector
                                            : page.key === 'Clientes'
                                            ? bgColors.clientes
                                            : page.key === 'Campañas'
                                            ? bgColors.campañas
                                            : page.key === 'Equipos'
                                            ? bgColors.equipos
                                            : page.key === 'Inventario'
                                            ? bgColors.inventarios
                                            : page.key === 'Validar BD Jotform'
                                            ? bgColors.validarJotform
                                            : page.key === 'Mis Estadísticas'
                                            ? bgColors.misEstadisticas
                                            : page.key === 'Mi BD'
                                            ? bgColors.miBD
                                            : bgColors.agenda,
                                    '&:hover': {
                                        backgroundColor: '#062248',
                                    },
                                    textTransform: 'none',
                                    borderRadius: 0,
                                }}
                            >
                                {page.key}
                            </Button>
                        ))}
                    </Box>
                    <Box
                        sx={{
                            flexGrow: 1,
                            display: { xs: 'flex', md: 'none' },
                        }}
                    >
                        <IconButton
                            size="large"
                            aria-label="account of current user"
                            aria-controls="menu-appbar"
                            aria-haspopup="true"
                            onClick={handleOpenNavMenu}
                            color="inherit"
                        >
                            <MenuIcon />
                        </IconButton>
                        <Menu
                            id="menu-appbar"
                            anchorEl={anchorElNav}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'left',
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'left',
                            }}
                            open={anchorElNav}
                            onClose={handleCloseNavMenu}
                            sx={{
                                display: { xs: 'block', md: 'none' },
                            }}
                        >
                            {pages.map((page) => (
                                <MenuItem
                                    key={page.key}
                                    onClick={handleClickMenu}
                                >
                                    <Typography textAlign="center">
                                        {page.key}
                                    </Typography>
                                </MenuItem>
                            ))}
                        </Menu>
                    </Box>
                    <Box
                        sx={{
                            flexGrow: 0,
                            display: 'flex',
                            flexDirection: 'row',
                        }}
                    >
                        {/* {!isMantenimientoView && (
                            <Tooltip title="Rol">
                                <IconButton
                                    onClick={handleChangeRol}
                                    sx={{
                                        p: '10px 20px 10px 0px',
                                        display: 'flex',
                                        flexDirection: 'column',
                                        color: 'white',
                                    }}>
                                    <SwapHorizOutlined
                                        fontSize="large"
                                        sx={{
                                            pt: 0.5,
                                        }}></SwapHorizOutlined>
                                    <Typography>{role}</Typography>
                                </IconButton>
                            </Tooltip>
                        )} */}
                        <RolesGate
                            roles={[
                                ROLES.GERENCIA_EFFECTIVE,
                                ROLES.GERENCIA_WINNER,
                                ROLES.DIRECTOR_ADMINISTRATIVO,
                                ROLES.DIRECTOR_OPERATIVO,
                            ]}
                        >
                            {!isMantenimientoView && (
                                <Tooltip title="Cambiar país">
                                    <IconButton
                                        onClick={handleChangePais}
                                        sx={{
                                            p: '10px 30px 10px 10px',
                                            display: 'flex',
                                            flexDirection: 'column',
                                            color: 'white',
                                            borderRadius: 0,
                                        }}
                                    >
                                        <img
                                            alt={
                                                pais === 'colombia'
                                                    ? 'Colombia'
                                                    : 'Panama'
                                            }
                                            src={
                                                pais === 'colombia'
                                                    ? ColombiaFlag
                                                    : PanamaFlag
                                            }
                                            width={40}
                                            height={40}
                                            style={{ borderRadius: '50%' }}
                                        />
                                        <Typography>
                                            {pais === 'colombia'
                                                ? 'Colombia'
                                                : 'Panamá'}
                                        </Typography>
                                        <ChangeCircle sx={{ fontSize: 20 }} />
                                    </IconButton>
                                </Tooltip>
                            )}
                        </RolesGate>
                        <RolesGate
                            roles={[
                                ROLES.GERENCIA_EFFECTIVE,
                                ROLES.GERENCIA_WINNER,
                            ]}
                        >
                            <Tooltip title="Mantenimiento">
                                <IconButton
                                    onClick={handleMantenimiento}
                                    sx={{
                                        p: '10px 10px 10px 0px',
                                        display: 'flex',
                                        flexDirection: 'column',
                                        color: 'white',
                                        borderRadius: 0,
                                    }}
                                >
                                    <Settings
                                        fontSize="large"
                                        sx={{ pt: 0.5 }}
                                    ></Settings>
                                    <Typography>Mantenimiento</Typography>
                                </IconButton>
                            </Tooltip>
                        </RolesGate>
                        <Tooltip title="Perfil">
                            <IconButton
                                onClick={handleOpenProfile}
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    color: 'white',
                                    borderRadius: 0,
                                }}
                            >
                                <Avatar
                                    alt="Demo"
                                    {...stringAvatar(
                                        `${usuario?.nombres} ${usuario?.apellidos}`,
                                    )}
                                />
                                <Typography
                                    variant="body2"
                                    sx={{
                                        pt: 0.5,
                                    }}
                                >{`${usuario?.nombres} ${usuario?.apellidos}`}</Typography>
                                <Typography variant="caption">
                                    {role}
                                </Typography>
                            </IconButton>
                        </Tooltip>
                        <Tooltip title="Cerrar sesión">
                            <IconButton
                                onClick={handleLogOut}
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    color: 'white',
                                    borderRadius: 0,
                                }}
                            >
                                <LogoutOutlined fontSize="large"></LogoutOutlined>
                                <Typography variant="body2">
                                    Cerrar sesión
                                </Typography>
                            </IconButton>
                        </Tooltip>
                        {/* <Menu
                            sx={{ mt: '45px' }}
                            id="menu-appbar"
                            anchorEl={anchorElUser}
                            anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            open={Boolean(anchorElUser)}
                            onClose={handleCLickSettings}>
                            {settings.map((setting) => (
                                <MenuItem
                                    key={setting}
                                    onClick={handleCLickSettings}>
                                    <Typography textAlign="center">
                                        {setting}
                                    </Typography>
                                </MenuItem>
                            ))}
                        </Menu> */}
                    </Box>
                </Toolbar>
            </Container>
        </AppBar>
    );
}
export default HeaderNav;
