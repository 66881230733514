import { Box, Grid, IconButton, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { sendRequest } from '../../../../../services/request';
import { DataGrid, GridToolbar, esES } from '@mui/x-data-grid';
import RolesGate from '../../../../../components/utils-components/permissions/rolesGate';
import { ROLES } from '../../../../../components/utils-components/permissions/permissions-map';
import EditIcon from '@mui/icons-material/Edit';
import { DataGridStyle } from '../../../../../styles/DataGridSytle';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '80%',
    height: '80%',
    bgcolor: 'background.paper',
    overflowY: 'auto',
    boxShadow: 24,
    px: 4,
};

const styleModalHeader = {
    top: 0,
    textAlign: 'center',
    position: 'sticky',
    zIndex: 15,
    py: 2,
    /* borderBottom: 'solid #0B3E83 1px', */
    backgroundColor: '#ffffff',
};

const AñadirUsuariosEquipo = ({ pais, equipoId }) => {
    const [usuarios, setUsuarios] = useState([]);
    const [, /* page */ setPage] = useState(0);
    const [limit, setLimit] = useState(20);
    const [rowCount /* setRowCount */] = useState(null);

    //Condicionales
    const [recargar, setRecargar] = useState(false);

    const URL = process.env.REACT_APP_API_URL;

    useEffect(() => {
        const fetchData = async () => {
            try {
                const res = await sendRequest(
                    'get',
                    `${URL}/usuario/promotor/equipo/${equipoId}`,
                );
                setUsuarios(res.data);
            } catch (error) {
                console.log(error);
            }
        };
        fetchData();
    }, [URL, equipoId, recargar]);

    const columns = [
        {
            field: 'codigo',
            headerName: 'Código',
            headerClassName: 'header',
            flex: 1,
            sortable: false,
            headerAlign: 'center',
            align: 'center',
        },
        {
            field: 'nombre_completo',
            headerName: 'Nombre Completo',
            headerClassName: 'header',
            sortable: false,
            flex: 1,
            headerAlign: 'center',
            align: 'center',
            renderCell: ({ row }) => {
                return `${row?.nombres} ${row?.apellidos}`;
            },
        },
        {
            field: 'acciones',
            type: 'actions',
            cellClassName: 'actions',
            headerName: 'Acciones',
            headerClassName: 'header',
            flex: 1,
            align: 'center',
            getActions: ({ row }) => {
                return [
                    <RolesGate
                        roles={[
                            ROLES.GERENCIA_EFFECTIVE,
                            ROLES.DIRECTOR_ADMINISTRATIVO,
                            ROLES.GERENCIA_WINNER,
                        ]}
                    >
                        <IconButton
                            color="primary"
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                borderRadius: 0,
                            }}
                            onClick={() => handleAgregarClick(row)}
                        >
                            <EditIcon />
                            <Typography>Agregar</Typography>
                        </IconButton>
                    </RolesGate>,
                ];
            },
        },
    ];

    const handlePaginationChange = (event) => {
        setPage(event.page);
        setLimit(event.pageSize);
    };

    const handleAgregarClick = async (row) => {
        try {
            await sendRequest('post', `${URL}/${pais}/equipos/usuario`, {
                equipo: equipoId,
                usuario: row.id,
            });
            setRecargar(!recargar);
        } catch (error) {
            console.log(error);
        }
    };

    return (
        <Box sx={style}>
            <form>
                <Box sx={styleModalHeader}>
                    <Typography
                        id="modal-modal-title"
                        variant="h5"
                        color="primary"
                    >
                        Añadir Usuarios
                    </Typography>
                </Box>
                <Grid container alignItems="center" justifyContent="center">
                    <Grid item lg={7} md={9}>
                        <DataGrid
                            localeText={
                                esES.components.MuiDataGrid.defaultProps
                                    .localeText
                            }
                            disableColumnSelector
                            disableDensitySelector
                            disableColumnFilter
                            disableColumnMenu
                            disableRowSelectionOnClick
                            initialState={{
                                pagination: {
                                    paginationModel: { pageSize: limit },
                                },
                            }}
                            rows={usuarios}
                            columns={columns}
                            slots={{ toolbar: GridToolbar }}
                            slotProps={{
                                toolbar: {
                                    showQuickFilter: false,
                                    // Estos dos quitan el export
                                    printOptions: {
                                        disableToolbarButton: true,
                                    },
                                    csvOptions: { disableToolbarButton: true },
                                },
                            }}
                            paginationMode="server"
                            rowCount={rowCount ? rowCount : 0}
                            onPaginationModelChange={handlePaginationChange}
                            sx={DataGridStyle}
                        />
                    </Grid>
                </Grid>
            </form>
        </Box>
    );
};

export default AñadirUsuariosEquipo;
