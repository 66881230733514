import { Document, Page, View, Text } from '@react-pdf/renderer';
import dayjs from 'dayjs';

const styles = {
    title: {
        flexDirection: 'row',
        fontSize: '20px',
        fontWeight: 'bold',
        marginBottom: '3vh',
    },
    tableHeader: {
        width: '100%',
        flexDirection: 'row',
        fontSize: '12px',
        borderBottom: '1px solid #000000',
        alignItems: 'space-between',
    },
    textBold: {
        fontSize: 14,
        textAlign: 'justify',
        color: '#000000',
        fontWeight: 'bold',
    },
    tableBody: {
        width: '98%',
        fontSize: '10px',
        flexDirection: 'row',
        margin: '5px 2vw',
        justifyContent: 'center',
        alignItems: 'space-between',
        paddingVertical: 10,
        paddingRight: 10,
    },
    tableTextHeader: {
        margin: '1vh 4vw',
        textAlign: 'center',
        fontWeight: 'bold',
    },
    tableFirstColumn: {
        margin: '0.5vh 2vw 0 0',
        textAlign: 'center',
    },
    tableText: { margin: '0.5vh 1vw 0 1vw', textAlign: 'center' },
    tableLastColumn: { margin: '0.5vh 0 0 1vw', textAlign: 'center' },
    page: {
        paddingTop: '5vh',
        paddingRight: '5vw',
        paddingLeft: '5vw',
    },
};

const RutaDocument = ({ data, canal }) => {
    return (
        <Document>
            <Page size="A4" style={styles.page}>
                <View style={styles.title}>
                    <Text>Ruta De Entrega - {canal}</Text>
                    <Text
                        style={{
                            fontSize: 13,
                            marginVertical: '5px',
                            width: '50vw',
                            textAlign: 'right',
                        }}
                    >
                        {dayjs(new Date()).format('DD-MM-YYYY')}
                    </Text>
                </View>
                <View style={[styles.tableHeader, styles.textBold]}>
                    <Text style={styles.tableTextHeader}>Item</Text>
                    <Text style={styles.tableTextHeader}>Producto</Text>
                    <Text style={styles.tableTextHeader}>Cantidad</Text>
                    <Text style={styles.tableTextHeader}>Entregar a</Text>
                    <Text style={styles.tableTextHeader}>Celular</Text>
                    <Text style={styles.tableTextHeader}>Destino</Text>
                    {canal.includes('Mensajero') && (
                        <Text style={styles.tableTextHeader}>Recibido</Text>
                    )}
                </View>
                {data &&
                    data.map((item, index) => (
                        <View
                            style={[
                                styles.tableBody,
                                {
                                    backgroundColor:
                                        index % 2 === 0 ? '#E6E6E6' : 'none',
                                },
                            ]}
                        >
                            <Text
                                style={[
                                    styles.tableFirstColumn,
                                    {
                                        width: canal.includes('Mensajero')
                                            ? 80
                                            : 90,
                                    },
                                ]}
                            >
                                {(index += 1)}
                            </Text>
                            <Text
                                style={[
                                    styles.tableText,
                                    {
                                        width: canal.includes('Mensajero')
                                            ? 120
                                            : 150,
                                    },
                                ]}
                            >
                                {item.campana.nombre}
                            </Text>
                            <Text
                                style={[
                                    styles.tableText,
                                    {
                                        width: canal.includes('Mensajero')
                                            ? 100
                                            : 150,
                                    },
                                ]}
                            >
                                {item.cantidad}
                            </Text>
                            <Text
                                style={[
                                    styles.tableText,
                                    {
                                        width: canal.includes('Mensajero')
                                            ? 110
                                            : 150,
                                    },
                                ]}
                            >
                                {item.cliente.nombres +
                                    ' ' +
                                    item.cliente.apellidos}
                            </Text>
                            <Text
                                style={[
                                    styles.tableText,
                                    {
                                        width: canal.includes('Mensajero')
                                            ? 100
                                            : 150,
                                    },
                                ]}
                            >
                                {item.cliente.celular}
                            </Text>
                            <Text
                                style={[
                                    canal.includes('Mensajero')
                                        ? styles.tableText
                                        : styles.tableLastColumn,
                                    { width: 120 },
                                ]}
                            >
                                {item.cliente.direccion_exacta}
                            </Text>
                            {canal.includes('Mensajero') && (
                                <Text
                                    style={[
                                        styles.tableLastColumn,
                                        { width: 70 },
                                    ]}
                                >
                                    _________
                                </Text>
                            )}
                        </View>
                    ))}
            </Page>
        </Document>
    );
};

export default RutaDocument;
