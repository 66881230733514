import {
    Box,
    Button,
    Grid,
    MenuItem,
    TextField,
    Typography,
} from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { sendRequest } from '../../../../services/request';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '80%',
    height: '80%',
    bgcolor: 'background.paper',
    overflowY: 'auto',
    boxShadow: 24,
    px: 4,
};

const styleModalHeader = {
    top: 0,
    textAlign: 'center',
    position: 'sticky',
    zIndex: 15,
    py: 2,
    /* borderBottom: 'solid #0B3E83 1px', */
    backgroundColor: '#ffffff',
};

const styleModalFooter = {
    bottom: 0,
    top: 0,
    position: 'sticky',
    mt: 12,
    pb: 3,
    backgroundColor: '#ffffff',
    zIndex: 15,
};

const EditarCampana = ({
    campanaRow,
    divisiones,
    canales,
    sectores,
    pais,
    handleClose,
    handleEditar,
}) => {
    const [nombre, setNombre] = useState(campanaRow.nombre);
    const [jotformId, setJotformId] = useState(campanaRow.jotform_id);
    const [departamento, setDepartamento] = useState(
        campanaRow.ciudad?.departamento?.id,
    );
    const [ciudad, setCiudad] = useState(campanaRow.ciudad?.id);
    const [division, setDivision] = useState(campanaRow.division?.id);
    const [vigencia, setVigencia] = useState(dayjs(campanaRow.vigencia));
    const [canal, setCanal] = useState(campanaRow.estrategia);
    const [sector, setSector] = useState(campanaRow.sector?.id);
    const [categoria, setCategoria] = useState(campanaRow.categoria?.id);
    const [categorias, setCategorias] = useState([]);
    const [metaCampana, setMetaCampana] = useState(campanaRow.total_tarjetones);
    const [ciudades, setCiudades] = useState([]);
    const [departamentos, setDepartamentos] = useState([]);

    const URL = process.env.REACT_APP_API_URL;

    useEffect(() => {
        sendRequest('get', `${URL}/${pais}/departamentos`).then((res) => {
            setDepartamentos(res.data);
        });
    }, [URL, pais]);

    useEffect(() => {
        if (sector) {
            sendRequest(
                'get',
                `${URL}/${pais}/categoria?sector=${sector}`,
            ).then((res) => setCategorias(res.data));
        }
    }, [URL, pais, sector]);

    useEffect(() => {
        if (departamento) {
            sendRequest(
                'get',
                `${URL}/${pais}/ciudades?departamento=${departamento}`,
            ).then((res) => {
                setCiudades(res.data);
            });
        }
    }, [URL, pais, departamento]);

    const handleChangeNombre = (event) => {
        setNombre(event.target.value);
    };

    const handleChangeJotformId = (event) => {
        setJotformId(event.target.value);
    };

    const handleSelectDepartamento = (event) => {
        setDepartamento(event.target.value);
    };

    const handleSelectCiudad = (event) => {
        setCiudad(event.target.value);
    };

    const handleSelectDivision = (event) => {
        setDivision(event.target.value);
    };

    const handleChangeVigencia = (value) => {
        setVigencia(dayjs(value).toISOString());
    };

    const handleSelectCanal = (event) => {
        setCanal(event.target.value);
    };

    const handleSelectSector = (event) => {
        setSector(event.target.value);
    };

    const handleSelectCategoria = (event) => {
        setCategoria(event.target.value);
    };

    const handleMetaCampana = (event) => {
        setMetaCampana(event.target.value);
    };

    return (
        <Box sx={style}>
            <form>
                <Box sx={styleModalHeader}>
                    <Typography
                        id="modal-modal-title"
                        variant="h5"
                        color="primary">
                        Editar Campaña
                    </Typography>
                </Box>
                <Grid container spacing={5} sx={{ mt: '2px' }}>
                    <Grid item xs={12} md={6}>
                        <TextField
                            required
                            fullWidth
                            id="nombres"
                            label="Nombre"
                            placeholder="Nombre de la campana"
                            variant="outlined"
                            value={nombre}
                            onChange={handleChangeNombre}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField
                            required
                            fullWidth
                            id="jotform_id"
                            label="ID Jotform"
                            placeholder="ID Jotform"
                            variant="outlined"
                            value={jotformId}
                            onChange={handleChangeJotformId}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField
                            select
                            fullWidth
                            id="division"
                            label="División"
                            value={division}
                            variant="outlined"
                            width="100%"
                            onChange={handleSelectDivision}>
                            {divisiones &&
                                divisiones.map((division) => {
                                    return (
                                        <MenuItem
                                            key={division.id}
                                            value={division.id}>
                                            {division.nombre}
                                        </MenuItem>
                                    );
                                })}
                        </TextField>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker
                                sx={{ width: '100%' }}
                                label="Vigencia"
                                value={vigencia}
                                onChange={handleChangeVigencia}
                            />
                        </LocalizationProvider>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField
                            select
                            fullWidth
                            id="estrategia"
                            label="Canal"
                            value={canal}
                            variant="outlined"
                            width="100%"
                            onChange={handleSelectCanal}>
                            {canales &&
                                canales.map((canal) => {
                                    return (
                                        <MenuItem
                                            key={canal.id}
                                            value={canal.canal}>
                                            {canal.canal}
                                        </MenuItem>
                                    );
                                })}
                        </TextField>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField
                            select
                            fullWidth
                            id="sector"
                            label="Sector"
                            value={sector}
                            variant="outlined"
                            width="100%"
                            onChange={handleSelectSector}>
                            {sectores &&
                                sectores.map((sector) => {
                                    return (
                                        <MenuItem
                                            key={sector.id}
                                            value={sector.id}>
                                            {sector.sector}
                                        </MenuItem>
                                    );
                                })}
                        </TextField>
                    </Grid>
                    {sector && categorias.length > 0 && (
                        <Grid item xs={12} md={6}>
                            <TextField
                                select
                                fullWidth
                                id="categoria"
                                label="Categoria"
                                value={categoria}
                                variant="outlined"
                                width="100%"
                                onChange={handleSelectCategoria}>
                                {categorias &&
                                    categorias.map((categoria) => {
                                        return (
                                            <MenuItem
                                                key={categoria.id}
                                                value={categoria.id}>
                                                {categoria.categoria}
                                            </MenuItem>
                                        );
                                    })}
                            </TextField>
                        </Grid>
                    )}
                    <Grid item xs={12} md={6}>
                        <TextField
                            select
                            fullWidth
                            id="departamento"
                            label={
                                pais === 'colombia'
                                    ? 'Departamento'
                                    : 'Provincia'
                            }
                            value={departamento}
                            variant="outlined"
                            width="100%"
                            onChange={handleSelectDepartamento}>
                            {departamentos &&
                                departamentos.map((departamento) => {
                                    return (
                                        <MenuItem
                                            key={departamento.id}
                                            value={departamento.id}>
                                            {departamento.nombre}
                                        </MenuItem>
                                    );
                                })}
                        </TextField>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField
                            select
                            fullWidth
                            id="ciudad"
                            label="Ciudad"
                            value={ciudad}
                            variant="outlined"
                            width="100%"
                            onChange={handleSelectCiudad}>
                            {ciudades &&
                                ciudades.map((ciudad) => {
                                    return (
                                        <MenuItem
                                            key={ciudad.id}
                                            value={ciudad.id}>
                                            {ciudad.nombre}
                                        </MenuItem>
                                    );
                                })}
                        </TextField>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField
                            fullWidth
                            type="number"
                            id="meta"
                            label="Meta campaña"
                            placeholder="Meta de la campaña"
                            variant="outlined"
                            value={metaCampana}
                            onChange={handleMetaCampana}
                        />
                    </Grid>
                </Grid>
                <Grid container spacing={5} sx={styleModalFooter}>
                    <Grid item xs={12} md={6}>
                        <Button
                            fullWidth
                            variant="outlined"
                            onClick={handleClose}>
                            Cerrar
                        </Button>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Button
                            fullWidth
                            variant="contained"
                            onClick={() =>
                                handleEditar(campanaRow.id, {
                                    nombre,
                                    ciudad,
                                    vigencia,
                                    division,
                                    estrategia: canal,
                                    sector,
                                    categoria,
                                    total_tarjetones: metaCampana,
                                    jotform_id: jotformId,
                                })
                            }>
                            Editar
                        </Button>
                    </Grid>
                </Grid>
            </form>
        </Box>
    );
};

export default EditarCampana;
